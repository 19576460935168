import StorageModule from '../StorageModule';

export type TUser = {
    firstName: string;
    lastName: string;
};

type TStore = {
    user: TUser[];
};

let store: TStore = {
    user: [],
};

class UserModule extends StorageModule<TStore> {
    constructor() {
        super({name: 'user', store});
    }
}

export default UserModule;
