import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import appReducer from './reducer/app';
import userReducer from './reducer/user';
import matrixReducer from './reducer/matrix';

export const store = configureStore({
    reducer: {
        app: appReducer,
        user: userReducer,
        matrix: matrixReducer,
    },
    devTools: process.env.NODE_ENV === 'development',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
