import StorageHandler from '../Storage/StorageHandler';
import { Config } from '../Config';
import HTTP from '../HTTP';

export enum Events {
    CONFIGURATION_HTPP_FAILED = 'CONFIGURATION_HTPP_FAILED',
}

class Configuration {
    http = new HTTP();
    store = StorageHandler;

    get = async () => {
        const url = new URL('/api/web/client/configuration', Config.host);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) {
            return await res.json();
        }

        const result = await res.json();
        this.store.Configuration.set(result);

        return result;
    };
}
export default Configuration;
