import * as React from 'react';
import mcli from "../../libs/matrix";
import { useAppDispatch, useAppSelector } from '../../hooks';
import { hideActiveModal, toggleUserListModal } from '../../reducer/app';
import { selectRoom, selectSelectedRoom, TMember, TRoom } from '../../reducer/matrix';
import Cross from '../Icons/Cross';
import ModalSkelleton from '../ModalSkelleton';

const RoomListModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedRoomId = useAppSelector(selectSelectedRoom);
    const room: TRoom | undefined = selectedRoomId ? useAppSelector((state) => selectRoom(state, selectedRoomId)) : undefined;

    if(!selectSelectedRoom || !room) return <></>;
    let avatar = room.imageUrl;
    if (!avatar && room.members.length === 2) {
        room.members.forEach((member: TMember) => {
            if (member.userId !== mcli.getUserId()) {
                avatar = member.imageUrl
            }
        });
    }
    return (
        <ModalSkelleton>
        <div className={`chat-side-menu`}>
            <>
                    <div
                        className="w-full flex justify-end cursor-pointer"
                        onClick={ () => dispatch(hideActiveModal())}
                    >
                        <Cross className="w-4 h-4 fill-gray-600" />
                    </div>
                <div className="flex flex-col items-center justify-center">
                    <div>
                        <img
                            className="rounded-full shadow"
                            src={avatar}
                        />
                    </div>
                    <div className="mt-3 font-semibold text-lg">
                        {room.name}
                    </div>
                </div>
                <div className="overflow-y-scroll max-h-96">
                    {room.members.map((member) => {
                        const mAvatar = member.imageUrl;
                        return (
                            <div
                                key={member.userId}
                                className="flex items-center p-2"
                            >
                                <div>
                                    {mAvatar ? (
                                        <img
                                            className="rounded-full shadow w-10 h-10 bg-primary-full text-white"
                                            src={mAvatar}
                                            alt={member.name.substring(1)}
                                        />
                                    ) : (
                                        <div className="rounded-full flex justify-center items-center w-10 h-10 bg-primary-full text-white uppercase">
                                            {member.name.substring(0, 1)}
                                        </div>
                                    )}
                                </div>
                                <div className="ml-2">{member.name}</div>
                            </div>
                        );
                    })}
                </div>
            </>
        </div>
    </ModalSkelleton>
    );
};

export default RoomListModal;
