import { Config } from '../Config';
import HTTP from '../HTTP';
import StorageHandler from '../Storage/StorageHandler';

class Images {
    store = StorageHandler;
    http = new HTTP();

    /**
     * Fetch and store latest categories
     * @returns JSON
     */
    get = async (imageUrl: string) => {
        const url = new URL(imageUrl, Config.host);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const blob = await res.blob();
        return URL.createObjectURL(blob);
    };

    avatar = async (id: string) => {
        const url = this.avatarUrl(id);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const blob = await res.blob();
        return URL.createObjectURL(blob);
    };
    avatarUrl = (id: string) => {
        return new URL(`/api/web/avatar/download/${id}`, Config.host);
    };

    userAvatar = () => {
        return new URL(`/api/web/avatar/download`, Config.host);
    };
}

export default Images;
