import * as React from 'react';
import sdk from '../../libs/mapp/sdk';
import { auth } from '../../libs/matrix';
import Login from '../Login';
import { selectState, selectSyncState, updateApiState } from '../../reducer/app';
import { useAppDispatch, useAppSelector } from '../../hooks';

const StafficeAuthProvider = ({ children }) => {
	const appState = useAppSelector(selectState);
	const syncState = useAppSelector(selectSyncState);
	const [isLoggedIn, setIsLoggedIn] = React.useState<boolean | null>(null);

	React.useEffect(() => {
		const checkAuthStatus = async () => {
			try {
				const all = await Promise.allSettled([checkApiValidAccess(), checkMatrixValidAccess()]);
				const someError = all.some((r) => {
					return r.status !== 'fulfilled' || r.value === false;
				});

				if (someError) {
					await sdk.Auth.logout();
					await auth.logout();
				}

				setIsLoggedIn(!someError)
			} catch {
				setIsLoggedIn(false);
			}
		};
		checkAuthStatus();
	}, [appState]);


	const checkApiValidAccess = async () => {
		const {
			status,
		} = await sdk.Auth.isLoggedIn();
		const apiIsError = status && status > 399;
		return !apiIsError;
	}

	const checkMatrixValidAccess = () => {
		const check = syncState.data?.error?.errcode !== 'M_UNKNOWN_TOKEN';
		const accessToken = auth.isLoggedIn()
		return check && accessToken;
	}


	if (isLoggedIn === null) return <></>;

	if (!isLoggedIn || appState === 'loggedOut')
		return (
			<div className="h-full w-full flex flex-col justify-center items-center">
				<Login />
			</div>
		);

	return <>{children}</>;
};

export default StafficeAuthProvider;
