import StorageModule from '../StorageModule';

export type TChatUsersItem = {
    id: string;
    firstName: string;
    lastName: string;
    country: string;
    chatRegistrationAt: string;
    language: string;
    matrixId: string;
    title: string;
};

type TStore = {
    items: TChatUsersItem[];
};

let store: TStore = {
    items: [],
};

class ChatUsersModule extends StorageModule<TStore> {
    constructor() {
        super({name: 'chatusers', store});
    }
}

export default ChatUsersModule;
