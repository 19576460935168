
const getOSInfo = (): {osName: string, osVersion: string} => {
    const userAgent = window.navigator.userAgent;
    let osName = 'Unknown';
    let osVersion = 'Unknown';

    if (userAgent.indexOf('Win') !== -1) {
      osName = 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
      osName = 'MacOS';
    } else if (userAgent.indexOf('Linux') !== -1) {
      osName = 'Linux';
    } else if (userAgent.indexOf('Android') !== -1) {
      osName = 'Android';
    } else if (userAgent.indexOf('iOS') !== -1) {
      osName = 'iOS';
    }

    if (/(Mac OS X|Windows NT|Android) ([\d._]+)/.test(userAgent)) {
      osVersion = RegExp.$2;
    } else if (/Linux/.test(userAgent)) {
      osVersion = 'Unknown'; // Linux does not have a specific version
    } else if (/OS (\d+)_(\d+)_?(\d+)?/.test(userAgent)) {
      osVersion = `${RegExp.$1}.${RegExp.$2}.${RegExp.$3 || 0}`;
    }

    return{
        osName,
        osVersion
    }
};

const getBrowserInfo = () => {
    const userAgent = window.navigator.userAgent;
    let browserName = 'Unknown';

    if (userAgent.indexOf('Firefox') !== -1) {
      browserName = 'Firefox';
    } else if (userAgent.indexOf('Chrome') !== -1) {
      browserName = 'Chrome';
    } else if (userAgent.indexOf('Safari') !== -1) {
      browserName = 'Safari';
    } else if (userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR') !== -1) {
      browserName = 'Opera';
    } else if (userAgent.indexOf('Edge') !== -1) {
      browserName = 'Edge';
    } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1) {
      browserName = 'Internet Explorer';
    }

    return browserName;
};

export const appHeaders = (): Record<string, string> => {
    return {
        "os": getOSInfo().osName,
        "osVersion": getOSInfo().osVersion,
        "buildVersion": "1.0.0", // TODO: build version handling to be implemented
        "browser": getBrowserInfo()
    }
}