import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { hideActiveModal, toggleJoinRoomModal } from '../../reducer/app';
import {
	getPublicRooms,
	getRoomAsync,
	getUsersAsync,
	selectPublicRooms,
	selectUsers,
	TMember,
	TPublicRoom,
	updateSelectedRoom,
} from '../../reducer/matrix';
import Check from '../Icons/check';
import Cross from '../Icons/Cross';
import ModalSkelleton from '../ModalSkelleton';
import mcli from '../../libs/matrix';
import { Preset } from 'matrix-js-sdk';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

const JoinRoomModal: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const users = useAppSelector(selectUsers);
	const publicRooms = useAppSelector(selectPublicRooms);

	const userListRef = React.useRef<HTMLDivElement>(null);

	const [selected, setSelected] = React.useState<TMember | TPublicRoom>();
	const [category, setCategory] = React.useState<string>('user');
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [searchPattern, setSearchPattern] = React.useState('');
	const [currentPage, setCurrentPage] = React.useState(0);

	const mappData = JSON.parse(localStorage.getItem('mapp-sdk') || '');

	const encryptionOption = mappData.configuration?.chatEncryptionOption || 0;

	React.useEffect(() => {
		dispatch(getUsersAsync());
		dispatch(getPublicRooms());
	}, []);

	let searchedRooms = publicRooms.filter((r: any) =>
		r.name.toLowerCase().includes(searchPattern?.toLowerCase() || '')
	);

	let userPagination = users.pagination;

	const join = async (roomId: string) => {
		await mcli.joinRoom(roomId);
		dispatch(getRoomAsync(roomId)).finally(() => {
			dispatch(updateSelectedRoom(roomId));
			dispatch(toggleJoinRoomModal({ open: false }));
		});
	};

	const doneCreateChat = () => {
		dispatch(hideActiveModal());
		setIsLoading(false);
	};

	const createChat = async (userId: string, encrypted: boolean) => {
		if (!userId) return;
		setIsLoading(true);
		//find Room
		const dmRoomEvent = mcli.getAccountData('m.direct');
		let dmRoomMap: any = {};

		if (dmRoomEvent !== undefined) {
			dmRoomMap = { ...dmRoomEvent?.getContent() };
		}

		const foundRoom = dmRoomMap[userId]?.find((roomId: string) => {
			const room = mcli.getRoom(roomId);
			if (room) {
				const members = room.getJoinedMembers();
				return members.length > 1;
			}
			return false;
		});

		// 0 = verschlüsselt , 1 = nicht verschlüsselt, 2 = auswählen

		if (foundRoom) {
			dispatch(updateSelectedRoom(foundRoom));
			doneCreateChat();
			return;
		}

		const res: { room_id: string } = await mcli.createRoom({
			invite: [userId],
			preset: Preset.TrustedPrivateChat,
			is_direct: true,
		});

		await setDmRoom(res.room_id, userId);

		if (encrypted) {
			await encryptRoom(res.room_id);
		}

		dispatch(updateSelectedRoom(res.room_id));
		doneCreateChat();
	};

	async function setDmRoom(roomId: string, userId: string) {
		if (mcli != null) {
			const mDirectEvent = mcli.getAccountData('m.direct');
			let dmRoomMap: any = {};

			if (mDirectEvent !== undefined)
				dmRoomMap = { ...mDirectEvent?.getContent() }; // copy as we will mutate

			// remove it from the lists of any others users
			// (it can only be a DM room for one person)
			Object.keys(dmRoomMap).forEach((id) => {
				const roomList = dmRoomMap[id];

				if (id != userId) {
					const indexOfRoom = roomList.indexOf(roomId);
					if (indexOfRoom > -1) {
						roomList.splice(indexOfRoom, 1);
					}
				}
			});

			// now add it, if it's not already there
			if (userId) {
				const roomList = dmRoomMap[userId] || [];
				if (roomList.indexOf(roomId) == -1) {
					roomList.push(roomId);
				}
				dmRoomMap[userId] = roomList;
			}

			await mcli.setAccountData('m.direct', dmRoomMap);
		}
	}

	const encryptRoom = async (roomId: string) => {
		const createdRoom = mcli.getRoom(roomId);
		if (!createdRoom) return;
		await mcli.sendStateEvent(roomId, 'm.room.encryption', {
			algorithm: 'm.megolm.v1.aes-sha2',
		});

		await mcli.setRoomEncryption(roomId, {
			algorithm: 'm.megolm.v1.aes-sha2',
		});

		const members = (await createdRoom.getEncryptionTargetMembers()).map(
			(x) => x['userId']
		);
		const memberkeys = await mcli.downloadKeys(members);
		for (const userId in memberkeys) {
			for (const deviceId in memberkeys[userId]) {
				await mcli.setDeviceVerified(userId, deviceId);
			}
		}
	};

	const searchFn = (searchTerm: string) => {
		if (category === 'user') {
			dispatch(getUsersAsync({ searchTerm }));
		}
	};

	const loadMoreUser = (e: any) => {
		dispatch(getUsersAsync({ page: e.selected + 1 }));
	};

	return (
		<ModalSkelleton>
			<div className="chat-side-menu flex flex-col justify-between">
				<div className="flex items-center justify-between mb-6">
					<h4>{t('new_chat_header')}</h4>

					<div
						className="flex justify-end cursor-pointer"
						onClick={() => dispatch(hideActiveModal())}>
						<Cross className="w-4 h-4 fill-gray-600" />
					</div>
				</div>

				{/* Tabelle Nuter */}
				<div className="w-full flex items-center">
					<div
						className={`${
							category === 'user'
								? ' border-b-primary-full text-primary-full'
								: ' border-b-transparent'
						} border-b-2 p-2 mr-1 cursor-pointer`}
						onClick={() => setCategory('user')}>
						{t('people')}
					</div>
					<div
						className={`${
							category === 'rooms'
								? 'border-b-primary-full text-primary-full'
								: 'border-b-transparent'
						}
                        border-b-2 p-2 mr-1 cursor-pointer`}
						onClick={() => setCategory('rooms')}>
						{t('groups')}
					</div>
				</div>
				<div className="my-2 w-1/3">
					<div className="input-search--wrapper border">
						<div id="search" className="input-search-button"></div>
						<input
							value={searchPattern}
							className="input-search--input"
							onChange={(evt) => {
								setSearchPattern(evt.target.value);
								searchFn(evt.target.value);
							}}
							type="text"
							placeholder={`${t('search')}`}
						/>
					</div>
				</div>
				<div
					className="max-h-96 min-h-[24rem] overflow-y-scroll my-4"
					ref={userListRef}>
					{category === 'user' &&
						users.items.map((user: TMember) => {
							return (
								<div
									key={user.userId}
									className="flex items-center justify-between min-h-[3rem] hover:bg-primary-light cursor-pointer p-2"
									onClick={() => setSelected(user)}>
									<div className="flex items-center">
										<div>
											{user.imageUrl ? (
												<img
													src={user.imageUrl}
													className="rounded-full w-8 h-8"
												/>
											) : (
												<div className="w-8 h-8 bg-primary-full text-white rounded-full flex items-center justify-center">
													{user.name.substring(0, 1)}
												</div>
											)}
										</div>
										<div className="ml-6">{user.name}</div>
									</div>
									{selected &&
										// @ts-ignore
										selected.userId === user.userId && (
											<div>
												<Check className="w-5 h-5 fill-primary-full" />
											</div>
										)}
								</div>
							);
						})}

					{category === 'rooms' && (
						<>
							{searchedRooms.map((room: TPublicRoom) => {
								return (
									<div
										key={room.room_id}
										className="flex items-center justify-between min-h-[3rem] hover:bg-primary-light cursor-pointer p-2"
										onClick={() => setSelected(room)}>
										<div className="flex items-center">
											<div>
												{room.avatar_url ? (
													<img
														src={
															mcli.mxcUrlToHttp(
																room.avatar_url
															) || ''
														}
														className="rounded-full w-8 h-8"
													/>
												) : (
													<div className="rounded-full bg-primary-full w-8 h-8 flex items-center justify-center text-white">
														{room.name?.substring(
															0,
															1
														)}
													</div>
												)}
											</div>
											<div className="ml-6">
												{room.name}
											</div>
										</div>
										{selected &&
											// @ts-ignore
											selected.room_id ===
												room.room_id && (
												<div>
													<Check className="w-5 h-5 fill-primary-full" />
												</div>
											)}
									</div>
								);
							})}
						</>
					)}
				</div>
				<div className="h-5">
					{category === 'user' && userPagination.total_pages > 1 && (
						<ReactPaginate
							onPageChange={loadMoreUser}
							className="post-pagination"
							breakLabel="..."
							nextLabel=">"
							marginPagesDisplayed={3}
							pageRangeDisplayed={3}
							pageCount={userPagination.total_pages}
							previousLabel="<"
							forcePage={currentPage}
						/>
					)}
				</div>
				{encryptionOption === 2 && category === 'user' ? (
					<div className="mt-4 flex items-center justify-between w-full">
						<button
							className="mapp-button mapp-button-primary"
							disabled={!selected || isLoading}
							onClick={() => {
								if (category === 'user') {
									// @ts-ignore
									createChat(selected.userId, true);
								}
							}}>
							{t('start_chat_encrypted')}
						</button>
						<button
							className="mapp-button mapp-button-primary"
							disabled={!selected || isLoading}
							onClick={() => {
								if (category === 'user') {
									// @ts-ignore
									createChat(selected.userId, false);
								}
							}}>
							{t('start_chat')}
						</button>
					</div>
				) : (
					<div className="mt-4 flex justify-end w-full">
						<button
							className="mapp-button mapp-button-primary"
							disabled={!selected || isLoading}
							onClick={() => {
								if (category === 'user') {
									// @ts-ignore
									createChat(
										// @ts-ignore
										selected.userId,
										encryptionOption === 0
									);
								}
								if (category === 'rooms') {
									// @ts-ignore
									join(selected.room_id);
								}
							}}>
							{t('start_chat')}
						</button>
					</div>
				)}
			</div>
		</ModalSkelleton>
	);
};

export default JoinRoomModal;
