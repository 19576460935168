import StorageHandler from '../Storage/StorageHandler';
import HTTP from '../HTTP';
import { TCustom } from '../Storage/Modules/NewsModule';
import { TBoard } from '../Storage/Modules/BoardsModule';
import { Config } from '../Config';

type TBoardsResponse = {
	items: TBoard[];
};

type TParams = {
	sort?: string;
	order?: string;
	likes?: string;
	per_page: number;
	current_page: number;
	from?: string;
	to?: string;
};

interface IBoards {
	store: typeof StorageHandler;
	http: HTTP;
	url: string;
}

/** Boards
 *
 * MAPP Boards Module
 *
 */
class Boards implements IBoards {
	store: typeof StorageHandler = StorageHandler;
	http: HTTP;
	readonly url = '/api/web/boards';

	constructor() {
		this.http = new HTTP();
	}

	/**
	 * Fetch and store latest items.
	 * @returns JSON
	 */
	get = async ({
		params,
		custom,
	}: {
		params?: TParams;
		custom?: TCustom;
	}) => {
		const res = await this.http.json.httpGet(this.buildUrl(this.url));

		if (!res.ok) throw res.status;

		const jsonResult = await res.json();
		this.update({ items: jsonResult });
		return jsonResult;
	};

	getPost = async (id: string) => {
		const res = await this.http.json.httpGet(
			this.buildUrl(`/api/web/posts/${id}`)
		);

		if (!res.ok) throw res.status;

		const jsonResult = await res.json();
		return jsonResult;
	};

	getBoard = async (id: string) => {
		const res = await this.http.json.httpGet(
			this.buildUrl(`/api/web/boards/${id}`)
		);

		if (!res.ok) throw res.status;

		const jsonResult = await res.json();
		return jsonResult;
	};

	getImage = async (id: string) => {
		const url = this.buildUrl(`/api/web/posts/attachment/${id}`);
		const res = await this.http.json.httpGet(url);
		if (!res.ok) throw res.status;

		return URL.createObjectURL(await res.blob());
	};

	getImageUrl = (id: string) => {
		return this.buildUrl(`/api/web/posts/attachment/${id}`);
	};

	private buildUrl = (
		apiPath: string,
		params?: TParams,
		custom?: TCustom
	): URL => {
		const url = new URL(apiPath, Config.host);

		if (params) {
			// @ts-ignore
			url.search = new URLSearchParams(params).toString();
		}

		const filter = custom || this.store.News.getCustom();
		if (filter) {
			if (
				filter.name !== '' &&
				filter.type === 'array' &&
				filter.content
			) {
				filter.content.forEach((item) => {
					url.searchParams.append('channels[]', item);
				});
			}
		}
		return url;
	};

	private update = (res: TBoardsResponse) => {
		this.store.Boards.add(res.items);
	};
}

export default Boards;
