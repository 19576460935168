import StorageModule from '../StorageModule';

export interface TPostSickNote {
	start: string
	end: string
	file?: File
	fileKey?: string
	fileIv?: string
	mail?: string
	comment?: string
}

export interface SickNoteState {
    start: Date
    end: Date
    comment: string
    image: File|null
}

type TStore = {
	sickNote: []
};

let store: TStore = {
	sickNote: [],
};

class SickNoteModule extends StorageModule<TStore> {
	constructor() {
		super({ name: 'sicknote', store });
	}
}

export default SickNoteModule;
