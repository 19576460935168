import * as React from 'react';
import pkg from '../../package.json';
import mcli from "../libs/matrix"
type TProps = {
    children: React.ReactElement | React.ReactElement[];
};
const AppLayout: React.FC<TProps> = ({ children }) => {
    return (
        <div>
            <div className="container">{children}

            </div>
        </div>
    );
};

export default AppLayout;
