export const saveLocal = (name: string, data: any) => {
    if (typeof window !== 'undefined' && localStorage) {
        localStorage.setItem(
            name,
            JSON.stringify({
                ...data,
            }),
        );
    }
};

export const loadLocal = (name: string) => {
    if (typeof window !== 'undefined' && localStorage) {
        const storeFromLocal = localStorage.getItem(name);
        if (storeFromLocal) return JSON.parse(storeFromLocal);
    }
};

export const clearAll = () => {
    if (typeof window !== 'undefined' && localStorage) {
        localStorage.removeItem("mapp-sdk");
    }
};
