import {Config} from '../Config';
import HTTP from '../HTTP';
import {TPagination} from '../Storage/Modules/NewsModule';
import {TWikiArticle} from '../Storage/Modules/WikiModule';
import StorageHandler from '../Storage/StorageHandler';

type TWikiArticleResponse = {
    items: TWikiArticle[];
    pagination: TPagination;
};
class Wiki {
    store = StorageHandler;
    http = new HTTP();

    /**
     * Fetch and store latest categories
     * @returns JSON
     */
    getArticles = async (params: {
        category?: string;
        searchTerm?: string;
        sort?: string;
        perPage?: number;
        page?: number;
    }) => {
        const url = new URL('/api/web/wiki/articles', Config.host);

        if (params) {
            // @ts-ignore
            url.search = new URLSearchParams(params).toString();
        }

        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();
        this.update(jsonResult);

        return jsonResult;
    };

    getOneById = async (id: string) => {
        const url = new URL(`/api/web/wiki/article/${id}`, Config.host);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };

    getVideo = async (id: string) => {
        const url = new URL(
            `/api/web/wiki/article/${id}/video/download`,
            Config.host,
        );
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        return url;
    };

    getImage = async (id: string) => {
        const url = new URL(
            `/api/web/wiki/article/${id}/image/download`,
            Config.host,
        );
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };

    setFavourite = async (id: string, value: boolean) => {
        const url = new URL(
            `/api/web/wiki/article/${id}/favourite`,
            Config.host,
        );

        const res = await this.http.json.httpPost(
            url,
            JSON.stringify({
                favourite: value,
            }),
        );

        if (!res.ok) throw res.status;

        return res;
    };

    getCategories = async () => {
        const url = new URL(`/api/web/wiki/categories`, Config.host);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };

    getArticleDocuments = async (id: string) => {
        const url = new URL(`/api/web/wiki/categories`, Config.host);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };

    downloadDocument = async (id: string) => {
        const url = new URL(
            `/api/web/wiki/article/document/${id}/download`,
            Config.host,
        );
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        if (res.ok && res.status === 200) {
            return {
                response: res,
                blob: await res.blob(),
            };
        }
    };

    next = async (categoryId: string) => {
        const pagination = this.store.Wiki.getPagination();
        if (!pagination || !pagination.has_next_page) return undefined;

        const nextParams = {
            perPage: pagination.per_page,
            page: pagination.current_page + 1,
        };

        if (categoryId !== '') {
            // @ts-ignore
            nextParams['categoryId'] = categoryId;
        }

        if (pagination?.from) {
            // @ts-ignore
            nextParams['from'] = pagination.from;
        }

        if (pagination?.to) {
            // @ts-ignore
            nextParams['to'] = pagination.to;
        }
        const url = new URL('/api/web/wiki/articles', Config.host);

        if (nextParams) {
            // @ts-ignore
            url.search = new URLSearchParams(params).toString();
        }

        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult: TWikiArticleResponse = await res.json();

        this.update(jsonResult);
        return jsonResult.items;
    };

    previous = async (categoryId: string) => {
        const pagination = this.store.Wiki.getPagination();
        if (!pagination || !pagination.has_next_page) return undefined;

        const nextParams = {
            perPage: pagination.per_page,
            page: pagination.current_page + 1,
        };

        if (categoryId !== '') {
            // @ts-ignore
            nextParams['categoryId'] = categoryId;
        }

        if (pagination?.from) {
            // @ts-ignore
            nextParams['from'] = pagination.from;
        }

        if (pagination?.to) {
            // @ts-ignore
            nextParams['to'] = pagination.to;
        }
        const url = new URL('/api/web/wiki/articles', Config.host);

        if (nextParams) {
            // @ts-ignore
            url.search = new URLSearchParams(params).toString();
        }

        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult: TWikiArticleResponse = await res.json();

        this.update(jsonResult);
        return jsonResult.items;
    };

    totalPages = (): number =>
        this.store.Posts.getPagination()?.total_pages || 0;

    /**
     * get current page
     * @returns {number} page number
     */
    currentPage = (): number =>
        this.store.Posts.getPagination()?.current_page || 0;

    private update = (res: TWikiArticleResponse) => {
        this.store.Wiki.add(res.items);
        this.store.Wiki.setPagination(res.pagination);
    };
}

export default Wiki;
