import StorageModule from '../StorageModule';
import { TCategoryItem } from './CategoryModule';
import { TComment } from './NewsModule';

export type TAppUser = {
	id: string;
	firstName: string;
	lastName: string;
};

export type TPostAttachment = {
	id: string;
	fileMimeType: string;
	fileName: string;
};

export type TPost = {
	id: string;
	appUser: TAppUser;
	contact: string;
	content: string;
	headline: string;
	declineComment: string | null;
	declineReason: string | null;
	postAttachments: TPostAttachment[];
	firstPostAttachment: TPostAttachment;
	board: string;
	createdAt: string;
	state: string;
	title: string;
	liked: boolean;
	likes: number;
	postComments: TComment[];
};

export type TPagination = {
	current_page: number;
	has_next_page: boolean;
	has_previous_page: boolean;
	per_page: number;
	total_items: number;
	total_pages: number;
	from?: string;
	to?: string;
	boardId: string;
};

export type TCustom = {
	name?: string;
	type?: string;
	content?: string[];
	likes?: string;
	important?: string;
};

type TStore = {
	items: TPost[];
	pagination: TPagination | null;
	custom: TCustom | null;
};

let store: TStore = {
	items: [],
	pagination: null,
	custom: null,
};

class PostsModule extends StorageModule<TStore> {
	constructor() {
		super({ name: 'posts', store });
	}
	/**
	 * get all items
	 * @returns {TPost[]} items
	 */
	getAll = (): TPost[] => this.getStore()?.items || [];

	/**
	 * get one item by id
	 * @param {string} id
	 * @returns {TPost} single item
	 */
	getOneById = (id: string): TPost | undefined =>
		this.getStore().items.find((n: TPost) => n.id === id);

	/**
	 * get pagination info, null if not set
	 * @returns {TPagination | null}
	 */
	getPagination = (): TPagination | null =>
		this.getStore()?.pagination || null;

	/**
	 * set pagination
	 */
	setPagination = (pagination: any) => {
		this.updateStore({
			...this.getStore(),
			pagination: { ...pagination },
		});
	};

	/**
	 * get pagination info, null if not set
	 * @returns {TPagination | null}
	 */
	getCustom = (): TCustom | null => store.custom;

	/**
	 * set pagination
	 */
	setCustom = (custom: TCustom) => {
		this.updateStore({ ...this.getStore(), custom });
	};

	/**
	 * Remove all items
	 * @returns void
	 */
	clear = () => (store.items = []);

	/**
	 * Add item to the store
	 * @param {TPost[]} item
	 * @returns void
	 */
	add = (item: TPost[]) => {
		let toAdd: TPost[] = [];
		item.forEach((_item) => {
			const found = store.items.find((n) => n.id === _item.id);
			if (!found) toAdd.push(_item);
		});
		const stored = this.getStore();
		this.updateStore({ ...stored, items: [...toAdd] });
	};

	/**
	 * Remove single item
	 * @param boards
	 * @returns void
	 */
	remove = (item: TPost) => {
		this.updateStore({
			...this.getStore(),
			items: this.getStore().items.filter((n: TPost) => n.id !== item.id),
		});
	};
}

export default PostsModule;
