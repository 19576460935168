import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../hooks';
import sdk from '../../libs/mapp/sdk';
import {auth} from '../../libs/matrix';
import {updateState} from '../../reducer/app';
import CookieBanner from '../CookieBanner';

const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState<boolean>();
    const [accountError, setAccountError] = React.useState<boolean>(false);

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        sdk.Auth.login(email, password)
            .then((res) => {
                if (res.status === 401) {
                    setError(true);
                    return;
                }
                if (res.status === 404 || res.status === 500) {
                    setAccountError(true);
                    return;
                }
                sdk.Configuration.get().then((res) => {
                    auth.login(
                        email,
                        password,
                        sdk.Store.Configuration.get()?.matrixServer,
                    )
                        .then((loggedIn) => {
                            if (!loggedIn) {
                                setError(true);
                                return;
                            }
                            // @ts-ignore
                            location.reload();
                        })
                        .catch((e) => {
                            setError(true);
                        });
                });
            })
            .catch((e) => setError(true));
    };

    return (
        <>
            <div className="flex justify-evenly w-screen">
                <div className="login-container">
                    <div className="login-form">
                        <div className="login-staffice-icon">
                            <img src="/Staffice_Logo.svg" />
                        </div>
                        <div className="flex items-center justify-center mb-4 text-gray-250">
                            <h3>Chat</h3>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="login-item">
                                <label htmlFor="email">
                                    {t('login_form_placeholder_username')}
                                </label>
                                <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    id="email"
                                    placeholder="max.mustermann@mapp.de"
                                    className="login-input"
                                />
                            </div>
                            <br />
                            <div className="login-item">
                                <label htmlFor="password">
                                    {t('login_form_placeholder_password')}
                                </label>
                                <input
                                    id="password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    type="password"
                                    placeholder="****"
                                    className="login-input"
                                />
                            </div>
                            <br />
                            {error && (
                                <div className="login-item flex items-center justify-center text-red-400">
                                    {t('login_failed_userpassword')}
                                </div>
                            )}
                            {accountError && (
                                <div className="login-item flex items-center justify-center text-red-400">
                                    {t('login_failed_userpassword')}
                                </div>
                            )}
                            <br />
                            <div className="login-item mt-12">
                                <button
                                    type="submit"
                                    className="mapp-button mapp-button-primary w-full"
                                >
                                    {t('login')}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="login-bg h-screen w-full">
                        <img src={'/login-background-1.jpg'} />
                    </div>
                </div>
            </div>
            <CookieBanner
                labels={{
                    title: t('privacy_policy'),
                    description: t('privacy_text'),
                    acceptBtn: t('terms_accept'),
                    denyBtn: t('terms_deny'),
                    infoBtn: 'Information',
                    backBtn: t('cookie_back') || '',
                    policy: t('privacy_policy'),
                }}
                services={[
                    {
                        name: t('essential'),
                        isEssential: true,
                        descriptionHeader: t('description'),
                        vendorHeader: t('vendor'),
                        timeHeader: t('time'),
                        cookies: [
                            {
                                name: 'Authentication Cookie',
                                vendor: t('cookie_vendor'),
                                vendorLink:
                                    'https://www.staffice.app/datenschutzerklaerung/',
                                purpose: t('cookie_purpose'),
                                cookieName: t('cookie_name'),
                                cookieExpireText: t('cookie_time'),
                            },
                        ],
                    },
                ]}
            />
        </>
    );
};

export default Login;
