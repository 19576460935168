import * as React from 'react';

type TProps = {
    me: boolean;
    children: React.ReactElement | React.ReactElement[];
    isReply: boolean;
};

const BadEncryptText: React.FC<TProps> = ({
    me,
    children,
    isReply,
}) => {
   
    return (
        <span
            className={`px-4 py-2 rounded-lg inline-block rounded-bl-none ${
                !isReply
                    ? me
                        ? 'border-background-normal border border-solid text-background-normal'
                        : 'border-background-dark border border-solid text-background-dark'
                    : 'bg-transparent'
            }`}
        >
             <div className="flex flex-col">
                {children}
            </div>
        </span>
    );
};
export default BadEncryptText;
