import * as React from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {hideActiveModal, selectImageModal} from '../../reducer/app';
import Cross from '../Icons/Cross';
import ModalSkelleton from '../ModalSkelleton';
import ModalSkelletonImages from '../ModalSkelleton/Images';

const ImageModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const appImageModal = useAppSelector(selectImageModal);
    if (!appImageModal?.url) return <></>;

    return (
        <ModalSkelletonImages>
            <div className="flex flex-col justify-between">
                <div className="flex items-center justify-end mb-6">
                    <div
                        className="flex justify-end cursor-pointer"
                        onClick={() => dispatch(hideActiveModal())}
                    >
                        <Cross className="w-4 h-4 fill-white" />
                    </div>
                </div>
                <img
                    className="max-h-[60rem] object-contain"
                    src={appImageModal?.url}
                />
            </div>
        </ModalSkelletonImages>
    );
};

export default ImageModal;
