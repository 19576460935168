import * as React from 'react';
import {useAppDispatch} from '../../hooks';
import {updateRestored, updateState} from '../../reducer/app';
import Restore from './Restore';
import {useTranslation} from 'react-i18next';
const BackupRestore: React.FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const [passphrase, setPassphrase] = React.useState('');
    const [error, setError] = React.useState('');
    const [clicked, setClicked] = React.useState(false);
    const [restoring, setRestoring] = React.useState(false);

    const callback = (error: Error | undefined, success: boolean) => {
        if (!error && success) {
            dispatch(updateState('ready'));
            dispatch(updateRestored(true));
            return;
        }
        setRestoring(false);
        if (
            error?.message.includes(
                'Error decrypting secret m.megolm_backup.v1: bad MAC',
            )
        ) {
            setError(`${t('restore_error')}`);
            return;
        }
        setError(error?.message || 'Unknown Error.');
    };

    if (restoring)
        return <Restore passphrase={passphrase} callback={callback} />;
    return (
        <div className="h-screen w-screen flex flex-col justify-center items-center">
            <div className="h-1/2 flex flex-col justify-evenly">
                <h3>{t('restore_header')}</h3>
                <form onSubmit={() => setRestoring(true)}>
                    <div className="login-item">
                        <label
                            htmlFor="password"
                            className="text-sm text-gray-600"
                        >
                            {t('restore_text')}
                        </label>
                        <input
                            value={passphrase}
                            name="password"
                            onChange={(e) => setPassphrase(e.target.value)}
                            type="password"
                            placeholder="*********"
                            className={`login-input ${
                                error && 'border-red-500'
                            }`}
                            disabled={clicked}
                        />
                    </div>
                    <br />
                    {error.length > 0 && (
                        <div className="mb-4 text-red-500">{error}</div>
                    )}
                    <br />
                    <div className="flex justify-end">
                        <input
                            className="mapp-button mapp-button-primary"
                            type="submit"
                            value={`${t('restore')}`}
                            disabled={clicked}
                        />
                    </div>
                    <div className="mt-8">
                        <span className="text-xs">{t('restore_skip')}</span>
                        <a
                            className="text-xs ml-2"
                            onClick={() => {
                                dispatch(updateState('ready'));
                            }}
                        >
                            {t('skip')}
                        </a>
                    </div>
                </form>
                <br />
                <br />
            </div>
        </div>
    );
};

export default BackupRestore;
