import * as React from 'react';

const ChatGifIcon: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="19"
            viewBox="0 0 23 19"
        >
            <g id="GIF" transform="translate(-768.509 -10)">
                <g
                    id="Rechteck_1696"
                    data-name="Rechteck 1696"
                    transform="translate(768.509 10)"
                    fill="none"
                    stroke="#8E9BB8"
                    strokeWidth="1.5"
                >
                    <rect width="23" height="19" rx="4" stroke="none" />
                    <rect
                        x="0.75"
                        y="0.75"
                        width="21.5"
                        height="17.5"
                        rx="3.25"
                        fill="none"
                    />
                </g>
                <path
                    id="Pfad_4194"
                    data-name="Pfad 4194"
                    d="M.927-2.655A2.759,2.759,0,0,0,3.785-.726,2.78,2.78,0,0,0,6.608-2.69,6.586,6.586,0,0,0,6.8-4.443v-.694a.111.111,0,0,0-.118-.118H4.032a.111.111,0,0,0-.118.118V-4.2a.111.111,0,0,0,.118.118H5.349a.062.062,0,0,1,.071.071A3.311,3.311,0,0,1,5.29-2.973a1.507,1.507,0,0,1-1.517,1A1.453,1.453,0,0,1,2.268-3.02a6.379,6.379,0,0,1-.141-1.835,6.32,6.32,0,0,1,.141-1.835A1.453,1.453,0,0,1,3.773-7.736a1.7,1.7,0,0,1,1.611.988.123.123,0,0,0,.153.059l.988-.435a.13.13,0,0,0,.047-.165A2.816,2.816,0,0,0,3.785-8.983,2.759,2.759,0,0,0,.927-7.054a6.474,6.474,0,0,0-.212,2.2A6.474,6.474,0,0,0,.927-2.655Zm7.4,1.682a.111.111,0,0,0,.118.118H9.6a.111.111,0,0,0,.118-.118V-8.736A.111.111,0,0,0,9.6-8.854H8.443a.111.111,0,0,0-.118.118Zm3.152,0a.111.111,0,0,0,.118.118h1.153a.111.111,0,0,0,.118-.118v-3.14a.062.062,0,0,1,.071-.071h3.093a.111.111,0,0,0,.118-.118V-5.313a.111.111,0,0,0-.118-.118H12.936a.062.062,0,0,1-.071-.071V-7.536a.062.062,0,0,1,.071-.071h3.67a.111.111,0,0,0,.118-.118V-8.736a.111.111,0,0,0-.118-.118H11.595a.111.111,0,0,0-.118.118Z"
                    transform="translate(771.289 24.355)"
                    fill="#6c7a98"
                    strokeWidth="0.5"
                />
            </g>
        </svg>
    );
};

export default ChatGifIcon;
