import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectSyncState } from '../../reducer/app';
import { useAppSelector } from '../../hooks';
import { SyncState } from 'matrix-js-sdk/lib/sync';
import { useTranslation } from 'react-i18next';

const isSyncFailed = (state: SyncState | null) => {
	return !(
		state === SyncState.Prepared ||
		state === SyncState.Syncing ||
		state === SyncState.Catchup
	);
};

const AlertSyncState = () => {
	const [failedId, setFailedId] = React.useState<string | number | null>(
		null
	);
	const [lastState, setLastState] = React.useState<SyncState | null>(null);
	const { t } = useTranslation();

	const syncState = useAppSelector(selectSyncState);

	React.useEffect(() => {
		if (failedId) {
			toast.dismiss(failedId);
		}
		if (syncState.state === lastState) return;

		let newFailedId: number | string | null = null;
		if (syncState.state === SyncState.Error) {
			newFailedId = toast.warn(t('error_network'));
		}
		setFailedId(newFailedId);

		if (
			!isSyncFailed(syncState.state) &&
			syncState.lastState !== null && syncState.lastState !== SyncState.Reconnecting &&
			isSyncFailed(syncState.lastState)
		) {
			toast.success(t('network_recovered'));
		}
		setLastState(syncState.state);
		return;
	}, [syncState]);

	return <ToastContainer />;
};

export default AlertSyncState;
