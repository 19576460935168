import StorageModule from '../StorageModule';

type TModules = string[];

type TStore = {
	agreement: string;
	agreementAccepted: boolean;
	appName: string;
	availabilityListEnabled: boolean;
	chatEncryptionOption: number;
	chatGroupCreationAllowed: boolean;
	id: string;
	imprint: string;
	logo: string;
	matrixPushServer: string;
	matrixServer: string;
	modules: TModules;
	privacyPolicy: string;
	singleApp: boolean;
	termsAccepted: boolean;
	termsOfUse: string;
	theme: number;
};

let store: TStore = {
	agreement: '',
	agreementAccepted: false,
	appName: '',
	availabilityListEnabled: false,
	chatEncryptionOption: 0,
	chatGroupCreationAllowed: false,
	id: '',
	imprint: '',
	logo: '',
	matrixPushServer: '',
	matrixServer: '',
	modules: [],
	privacyPolicy: '',
	singleApp: false,
	termsAccepted: false,
	termsOfUse: '',
	theme: 0,
};

class ConfigurationModule extends StorageModule<TStore> {
	constructor() {
		super({ name: 'configuration', store });
	}
}

export default ConfigurationModule;
