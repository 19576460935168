import * as olm from '@matrix-org/olm';
import React from 'react';
import ReactDOM from 'react-dom/client';

// @ts-ignore
global.Olm = olm;

import './index.scss';
import './libs/mapp/sdk/index'; // load sdk at the beginning.
import './libs/matrix/index'; // load sdk at the beginning.

import { Provider } from 'react-redux';
import App from './app';
import { store } from './store';
import ErrorBoundary from './components/ErrorPage';
import StafficeAuthProvider from './components/AuthProvider/StafficeAuthProvider';
import AlertSyncState from './components/Alert/AlertSyncState';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<Provider store={store}>
		<ErrorBoundary>
			<AlertSyncState />
			<StafficeAuthProvider>
				<App />
			</StafficeAuthProvider>
		</ErrorBoundary>
	</Provider>
);
