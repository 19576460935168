import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import mcli from '../../libs/matrix';
import {
	selectRestored,
	toggleChatLeaveRoom,
	toggleUserListModal,
	updateState,
} from '../../reducer/app';
import { selectRoom, updateSelectedRoom } from '../../reducer/matrix';
import ArrowLeft from '../Icons/ArrowLeft';
import NetworkErrorTooltip from '../Tooltip/NetworkErrorTooltip';

type TProps = {
	selectedRoomId: string;
};

const ChatRoomTopbar: React.FC<TProps> = ({ selectedRoomId }) => {
	const { t } = useTranslation();
	const isMobile = window.innerWidth < 621;
	const dispatch = useAppDispatch();
	const room = useAppSelector((state) => selectRoom(state, selectedRoomId));
	const restored = useAppSelector(selectRestored);
	if (!room) return <></>;
	return (
		<div className="flex justify-between items-center w-full">
			{isMobile && (
				<ArrowLeft
					className="w-4 h-4 cursor-pointer"
					onClick={() => dispatch(updateSelectedRoom(''))}
				/>
			)}
			<span>{selectedRoomId && mcli.getRoom(selectedRoomId)?.name}</span>
			{isMobile && <NetworkErrorTooltip />}
			{!restored && room.isEncrypted && (
				<div>
					<a
						onClick={() => dispatch(updateState('no_restore'))}
						className="cursor-pointer">
						{t('restore_info')}
					</a>
				</div>
			)}
			<div className="flex items-center justify-between w-14">
				<div
					onClick={() =>
						dispatch(
							toggleUserListModal({
								open: true,
								room: selectedRoomId,
							})
						)
					}>
					<svg
						data-type={'modal-btn'}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 640 512"
						className="w-5 h-5 fill-gray-400 hover:fill-gray-500 cursor-pointer">
						<path d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM319.9 160c30.85 0 55.96 25.12 55.96 56S350.7 272 319.9 272S263.9 246.9 263.9 216S289 160 319.9 160zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352zM178.1 464c10.47-36.76 47.36-64 91.14-64H369.9c43.77 0 80.66 27.24 91.14 64H178.1zM551.9 192h-61.84c-12.8 0-24.88 3.037-35.86 8.24C454.8 205.5 455.8 210.6 455.8 216c0 33.71-12.78 64.21-33.16 88h199.7C632.1 304 640 295.6 640 285.3C640 233.8 600.6 192 551.9 192zM183.9 216c0-5.449 .9824-10.63 1.609-15.91C174.6 194.1 162.6 192 149.9 192H88.08C39.44 192 0 233.8 0 285.3C0 295.6 7.887 304 17.62 304h199.5C196.7 280.2 183.9 249.7 183.9 216zM128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160z" />
					</svg>
				</div>
			</div>
		</div>
	);
};

export default ChatRoomTopbar;
