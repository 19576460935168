import StorageModule from '../StorageModule';

type TAccessToken = string;

type TStore = {
    token: TAccessToken;
};

let store: TStore = {
    token: '',
};

class AuthModule extends StorageModule<TStore> {
    constructor() {
        super({name: 'auth', store});
    }
    /**
     *
     * @returns {string} get accessToken
     */
    public getAccessToken = (): string => {
        return this.getStore()?.token;
    }

    /**
     * set Access Token
     * @returns void
     */
    public setAccessToken = (token: string) => {
        this.updateStore({token});
    };
}

export default AuthModule;
