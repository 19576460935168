import {
	Direction,
	MatrixEvent,
	Room,
	RoomMember,
	RoomMemberEvent,
} from 'matrix-js-sdk';
import moment from 'moment';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import mcli from '../../libs/matrix';
import {
	TEvent,
	paginateRoomAsync,
	selectMatrixEvent,
	selectRoom,
	selectSelectedRoom,
} from '../../reducer/matrix';
import ChatMessage, { TReplyFun } from '../ChatMessage';
import ChevronDown from '../Icons/ChevronDown';
import TypingIndicator from '../TypingIndicator';

const allowedEventTypes = [
	'm.room.member',
	'm.room.name',
	'm.room.topic',
	'm.room.message',
	'm.room.encrypted',
];

let lastLoadedEventId;

const ChatMessageWindow: React.FC = React.memo(({ reply }: any) => {
	const dispatch = useAppDispatch();
	const roomId = useAppSelector(selectSelectedRoom);
	const room = useAppSelector((state) => selectRoom(state, roomId || ''));
	const lastEventId = room?.events[room.events.length - 1]?.id || '';
	const matrixEvent = useAppSelector((state) =>
		selectMatrixEvent(state, lastEventId)
	);

	const scrollRef = React.useRef<HTMLDivElement>(null);
	const containerRef = React.useRef<HTMLDivElement>(null);
	const endDiv = React.useRef<HTMLDivElement>(null);
	const [isFinished, setIsFinished] = React.useState(false);
	const [loadingBackwards, setLoadingBackwards] = React.useState(false);
	const [userThatTyping, setUserThatTyping] = React.useState<RoomMember[]>(
		[]
	);
	const [scroller, setScroller] = React.useState(false);

	React.useEffect(() => {
		if (!room) return;
		if (loadingBackwards && containerRef?.current) {
			containerRef.current.scrollBy(0, 15);
			setLoadingBackwards(false);
		}
		if (room.lastEventId !== lastLoadedEventId) {
			lastLoadedEventId = room.lastEventId;
			scrollDown();
		}
		mcli.on(RoomMemberEvent.Typing, updateTyping);

		if (!matrixEvent?.getId() || lastEventId.includes(':')) return;
		mcli.sendReadReceipt(matrixEvent || null).catch(console.error);

		return () => {
			mcli.removeListener(RoomMemberEvent.Typing, updateTyping);
		};
	}, [room, room?.events]);

	const updateTyping = (_event: MatrixEvent, member: RoomMember) => {
		const isMe = mcli.getUserId() === member.userId;
		if (isMe) return;
		if (member.roomId !== roomId) return;
		typingCheck(member);
	};
	const typingCheck = (member: RoomMember) => {
		if (
			member.typing &&
			!userThatTyping.find((user) => user.userId === member.userId)
		) {
			setUserThatTyping([...userThatTyping, member]);
		}
		if (!member.typing) {
			setUserThatTyping(
				userThatTyping.filter(
					(_member: RoomMember) => _member.name !== member.name
				)
			);
			roomId && mcli.sendTyping(roomId, false, 0);
		}
	};

	const checkScrolled = () => {
		if (!roomId) return;
		if (!containerRef?.current || !scrollRef.current || !isFinished) return;
		const scrollElement = scrollRef.current.getBoundingClientRect();
		const chatWindow = containerRef.current.getBoundingClientRect();

		const verticallyVisible =
			scrollElement.top >= 0 && scrollElement.bottom <= chatWindow.bottom;

		// Check horizontal visibility
		const horizontallyVisible =
			scrollElement.left >= 0 && scrollElement.right <= window.innerWidth;

		if (verticallyVisible && !loadingBackwards) {
			setLoadingBackwards(true);

			dispatch(paginateRoomAsync({ roomId, page: 25 })).finally(() => {
				setLoadingBackwards(false);
			});
		}
		const chatWindowEl = containerRef.current;
		const scrolling = chatWindowEl.scrollHeight - chatWindowEl.scrollTop - 10 > chatWindowEl.clientHeight;

		setScroller(scrolling);
	};

	const scrollDown = () => {
		if (!endDiv.current) return;
		endDiv.current.scrollIntoView();
	};

	const isFinish = (max: number) => {
		let done = 0;
		return () => {
			done += 1;
			if (done >= max && !isFinished) {
				setIsFinished(true);
				scrollDown();
			}
		};
	};

	if (!roomId) return <></>;
	if (!room) return <></>;

	const triggerFinish = isFinish(room.events.length - 1);
	return (
		<>
			<div
				className={`chat-window`}
				ref={containerRef}
				onScroll={checkScrolled}>
				{room.events.map((m_event, index: number) => {
					const sender = mcli.getUser(m_event.sender);
					if (
						allowedEventTypes.includes(m_event.type) &&
						sender?.displayName !== 'matrixadmin'
					) {
						return (
							<>
								{index === 3 && <div ref={scrollRef} />}
								<ChatMessage
									key={m_event.id}
									id={m_event.id}
									reply={reply}
									callback={triggerFinish}
								/>
							</>
						);
					}
				})}

				{scroller && (
					<div className="chat-window-scroller" onClick={scrollDown}>
						<ChevronDown className="" />
					</div>
				)}
				{room.userTyping.length > 0 && (
					<TypingIndicator typingUsers={room.userTyping} />
				)}
				<div className="mt-4 h-6" ref={endDiv}>
					<br />
					<br />
					<br />
				</div>
			</div>
		</>
	);
});

export default ChatMessageWindow;
