import * as React from 'react';
import mcli from '../../libs/matrix';
import { getDecryptedVoiceMessage, TFile } from '../../libs/matrix/message';
import { IContent } from 'matrix-js-sdk';

type TProps = {
	content: IContent;
	callback: () => void;
};

const Audio: React.FC<TProps> = ({ content, callback }) => {
	const isDecrypted = 'file' in content;
	const fileUrl = isDecrypted ? content.file.url : content.url;

	const [audio, setAudio] = React.useState<any>();
	React.useEffect(() => {
		if (!isDecrypted) {
			callback();
			return;
		};
		getDecryptedVoiceMessage(content.file).then((res) => {
			setAudio(res);
		});
	}, []);

	// Loader Skeleton
	if (isDecrypted && !audio) return <></>;
	return (
		<audio controls>
			{isDecrypted ? (
				<source src={audio} />
			) : (
				<source src={mcli.mxcUrlToHttp(fileUrl) || ''} />
			)}
		</audio>
	);
};

export default Audio;
