import {Config} from '../Config';
import HTTP from '../HTTP';
import StorageHandler from '../Storage/StorageHandler';

type TBookingParam = {
    timestamp: number;
    in: boolean;
    break: boolean;
    breakInterval: number;
    breakComment: string;
    notice: string;
};

export type TDailyBooking = {
    id: string;
    timestamp: number;
    time: string;
    in: boolean;
    break: boolean;
    breakInterval: number;
    breakComment: string;
    notice: boolean | null;
    conflictMessages: string[];
    conflictTargets: string[];
};

export type TDailyBookingUser = {
    break: boolean;
    breakComment: string;
    breakInterval: number;
    breakSince: boolean | null;
    breakUntil: string | null;
    firstName: string;
    isAvailable: boolean;
    lastName: string;
    time: string;
    userId: string;
};

type TBookingEvaluationItem = {
    end: string | null;
    hours: number;
    minutes: number;
    start: string | null;
};

export type TBookingEvaluation = {
    day: TBookingEvaluationItem;
    month: TBookingEvaluationItem;
    week: TBookingEvaluationItem;
};

export type TBookingReport = {
    assignedDuration: number;
    assignedDurationValid: boolean;
    bookedBreakDuration: number;
    bookedBreakDurationValid: boolean;
    bookedWorkDuration: number;
    pushedCommits: number;
    pushedCommitsValid: boolean;
};

class Time {
    store = StorageHandler;
    http = new HTTP();

    /**
     * Fetch and store latest categories
     * @returns JSON
     */
    getBookings = async () => {
        const url = new URL('/api/web/time-tracker/bookings', Config.host);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };

    startBooking = async (params: TBookingParam[]) => {
        const url = new URL('/api/web/time-tracker/bookings', Config.host);
        const res = await this.http.json.httpPost(
            url,
            JSON.stringify({bookings: params}),
        );

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };

    getBookingById = async (id: string) => {
        const url = new URL(
            `/api/web/time-tracker/bookings/${id}`,
            Config.host,
        );
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };

    getUsers = async () => {
        const url = new URL('/api/web/time-tracker/users', Config.host);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };

    getReport = async () => {
        const url = new URL(
            '/api/web/time-tracker/bookings/report',
            Config.host,
        );
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };

    getEvaluation = async () => {
        const url = new URL('/api/web/time-tracker/evaluation', Config.host);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };

    deleteTime = async (id: string) => {
        const url = new URL(
            `/api/web/time-tracker/bookings/${id}`,
            Config.host,
        );
        const res = await this.http.json.httpDelete(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };

    updateTime = async (params: TBookingParam, id: string) => {
        const url = new URL(
            `/api/web/time-tracker/bookings/${id}`,
            Config.host,
        );
        const res = await this.http.json.httpPut(url, JSON.stringify(params));

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };
}

export default Time;
