import StorageModule from '../StorageModule';
import { TCategoryItem } from './CategoryModule';

export type TCreator = {
	id: string;
	firstName: string;
	lastName: string;
	avatarExtension: string;
	avatarMimeType: string;
	avatarName: string;
	avatarOriginalName: string;
	avatarSize: number;
};

export type TComment = {
	id: string;
	creator: TCreator;
	creatorName: string;
	replies: TComment[];
	text: string;
	updatedAt: Date;
	createdAt: Date;
	isCreator: boolean;
	prohibitedContent?: boolean;
};

export type TNewsDocument = {
	id: string;
	name: string;
	originalName: string;
	downloadLink: string;
	mimeType: string;
	createdAt: Date;
};

export type TNewsSurveyQuestion = {
	id: string;
	question: string;
	choiceDescription: string;
	maxChoiceNumber: number;
	position: number;
};

export type TNewsSurvey = {
	id: string;
	name: string;
	showResult: true;
	participated: true;
	surveyQuestions: TNewsSurveyQuestion[];
};

export type TNews = {
	id: string;
	comments: TComment[];
	commentsEnabled: boolean;
	category: TCategoryItem;
	content: string;
	headline: string;
	likes: number;
	newsDocuments: TNewsDocument[];
	newsImageUrl: string;
	publishedAt: Date;
	videoFile: boolean;
	visits: number;
	liked: boolean;
	visited: boolean;
	important: boolean;
	survey: TNewsSurvey;
	pinned: boolean;
	banner: boolean;
	hideImage: boolean;
};

export type TPagination = {
	current_page: number;
	has_next_page: boolean;
	has_previous_page: boolean;
	per_page: number;
	total_items: number;
	total_pages: number;
	from?: string;
	to?: string;
};

export type TCustom = {
	name?: string;
	type?: string;
	content?: string[];
	likes?: string;
	important?: string;
};

type TStore = {
	items: TNews[];
	pagination: TPagination | null;
	custom: TCustom | null;
};

let store: TStore = {
	items: [],
	pagination: null,
	custom: null,
};

class NewsModule extends StorageModule<TStore> {
	constructor() {
		super({ name: 'news', store });
	}
	/**
	 * get all news items
	 * @returns {TNews[]} news items
	 */
	getAll = (): TNews[] => this.getStore()?.items || [];

	/**
	 * get one news item by id
	 * @param {string} id
	 * @returns {TNews} single news item
	 */
	getOneById = (id: string): TNews | undefined =>
		this.getStore().items.find((n: TNews) => n.id === id);

	/**
	 * get pagination info, null if not set
	 * @returns {TPagination | null}
	 */
	getPagination = (): TPagination | null =>
		this.getStore()?.pagination || null;

	/**
	 * set pagination
	 */
	setPagination = (pagination: TPagination) => {
		this.updateStore({ ...this.getStore(), pagination });
	};

	/**
	 * get pagination info, null if not set
	 * @returns {TPagination | null}
	 */
	getCustom = (): TCustom | null => store.custom;

	/**
	 * set pagination
	 */
	setCustom = (custom: TCustom) => {
		this.updateStore({ ...this.getStore(), custom });
	};

	/**
	 * Remove all news items
	 * @returns void
	 */
	clearNews = () => (store.items = []);

	/**
	 * Add news to the store
	 * @param {TNews[]} news
	 * @returns void
	 */
	add = (news: TNews[]) => {
		news.forEach((_news) => {
			const index = store.items.findIndex((n) => n.id === _news.id);
			if (index !== -1) {
				store.items[index] = _news;
			} else {
				store.items.push(_news);
			}
		});
		const stored = this.getStore();
		this.updateStore({ ...stored, items: store.items });
	};

	/**
	 * Remove single News item
	 * @param news
	 * @returns void
	 */
	remove = (news: TNews) => {
		this.updateStore({
			...this.getStore(),
			items: this.getStore().items.filter((n: TNews) => n.id !== news.id),
		});
	};
}

export default NewsModule;
