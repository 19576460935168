import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import translationEN from './public/locales/en/common.json';
import translationDE from './public/locales/de/common.json';
import translationPT from './public/locales/pt/common.json';
import translationFR from './public/locales/fr/common.json';
import translationRO from './public/locales/ro/common.json';

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
    pt: {
        translation: translationPT,
    },
    fr: {
        translation: translationFR,
    },
    ro: {
        translation: translationRO,
    },
};

const options = {
    // order and from where user language should be detected
    order: [
        'querystring',
        'navigator',
        'cookie',
        'localStorage',
        'sessionStorage',
        'htmlTag',
        'path',
        'subdomain',
    ],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
};

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en',
        detection: options,
        supportedLngs: ['de', 'en', 'pt', 'fr', 'ro'],
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
