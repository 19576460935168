import _ from 'lodash';
import { Config } from '../Config';
import StorageHandler from '../Storage/StorageHandler';

export interface IJson {
    httpGet: (url: URL, cached?: boolean) => Promise<Response>;
}

type TDefaults = {
    credentials: any;
    headers: THeaders;
}

type THeaders = {
    'Content-Type'?: 'application/json';
    authorization?: string;
}

class Json implements IJson {
    store = StorageHandler;
    _httpRequest(url: URL, options: object, headers?: Record<string, string>, isFormData = false): Promise<any> {
        const accessToken = this.store.Auth.getAccessToken();
        const credentials: RequestCredentials = Config.useCookie ? 'include' : 'same-origin';
        const defaults: TDefaults = {
            credentials,
            headers: {
                ...headers
            },
        };

        if(!isFormData) {
            defaults.headers['Content-Type'] = 'application/json'
        }
        if (!Config.useCookie) {
            defaults.headers.authorization = `Bearer ${accessToken}`
        }

        const optionsForFetch = _.merge(defaults, options);

        return new Promise((resolve, reject) => {
            fetch(url.toString(), optionsForFetch).then(resolve).catch(reject);
        });
    }

    _httpUpload(url: URL, options: object): Promise<any> {
        const credentials: RequestCredentials = Config.useCookie ? 'include' : 'same-origin'; 
        const defaults = {
            credentials,
            headers: {
                accept: 'application/json',
            },
        };

        const optionsForFetch = _.merge(defaults, options);

        return new Promise((resolve, reject) => {
            fetch(url.toString(), optionsForFetch).then(resolve).catch(reject);
        });
    }

    httpGet = (url: URL, cached: boolean = false): Promise<Response> => {
        const options = {};
        if (cached) Object.assign(options, { cache: 'force-cache' });
        return this._httpRequest(url, options);
    };

    httpDelete(url: URL): Promise<Response> {
        const options = {
            method: 'DELETE',
        };
        return this._httpRequest(url, options);
    }

    httpPost(url: URL, body?: string | FormData, headers?: Record<string, string>, isFormData = false): Promise<Response> {
        const options = {
            method: 'POST',
            body: body,
        };
        return this._httpRequest(url, options, headers, isFormData);
    }

    httpUpload(url: URL, body: string | FormData): Promise<Response> {
        const options = {
            method: 'POST',
            body: body,
        };
        return this._httpUpload(url, options);
    }

    httpPut(url: URL, body?: string | FormData): Promise<Response> {
        const options = {
            method: 'PUT',
            body: body,
        };
        return this._httpRequest(url, options);
    }

    httpPatch(url: URL, body?: string | FormData): Promise<Response> {
        const options = {
            method: 'PATCH',
            body: body,
        };
        return this._httpRequest(url, options);
    }
}

export default Json;
