import StorageModule from '../StorageModule';

export type TBoard = {
    icon: string;
    id: string;
    name: string;
    postLifeTime: number;
    totalPosts: number;
};

type TStore = {
    items: TBoard[];
};

let store: TStore = {
    items: [],
};

class BoardsModule extends StorageModule<TStore> {
    constructor() {
        super({name: 'boards', store});
    }
    /**
     * get all items
     * @returns {TBoard[]} items
     */
    getAll = (): TBoard[] => this.getStore()?.items || [];

    /**
     * get one item by id
     * @param {string} id
     * @returns {TBoard} single item
     */
    getOneById = (id: string): TBoard | undefined =>
        this.getStore().items.find((n: TBoard) => n.id === id);

    /**
     * Remove all items
     * @returns void
     */
    clear = () => (store.items = []);

    /**
     * Add to the store
     * @param {TBoard[]} items
     * @returns void
     */
    add = (items: TBoard[]) => {
        let toAdd: TBoard[] = [];
        items.forEach((_item) => {
            const found = store.items.find((i) => i.id === _item.id);
            if (!found) toAdd.push(_item);
        });
        const stored = this.getStore();
        this.updateStore({...stored, items: [...toAdd]});
    };

    /**
     * Remove single item
     * @param item
     * @returns void
     */
    remove = (item: TBoard) => {
        this.updateStore({
            ...this.getStore(),
            items: this.getStore().items.filter(
                (i: TBoard) => i.id !== item.id,
            ),
        });
    };
}

export default BoardsModule;
