import * as React from 'react';

type TProps = {
    children: React.ReactElement;
};

const ModalSkelletonImages: React.FC<TProps> = ({children}) => {
    return (
        <div
            className="relative z-50"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <div
                        data-type={'modal'}
                        className="relative transform overflow-hidden text-left transition-all"
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSkelletonImages;
