import * as React from 'react';
import {useTranslation} from 'react-i18next';

type TCookie = {
    name: string;
    vendor: string;
    vendorLink: string;
    purpose: string;
    cookieName: string;
    cookieExpireText: string;
};

type TService = {
    description?: string;
    name: string;
    isEssential: boolean;
    cookies: TCookie[];
    descriptionHeader: string;
    vendorHeader: string;
    timeHeader: string;
};

type TLabels = {
    title: string;
    description: string;
    acceptBtn: string;
    denyBtn: string;
    infoBtn: string;
    backBtn?: string;
    policy: string;
};

type CookieBannerProps = {
    labels: TLabels;
    services: TService[];
};

declare enum UI_LAYER {
    FIRST_LAYER = 0,
    NONE = 1,
    PRIVACY_BUTTON = 2,
    SECOND_LAYER = 3,
}

type FIRST_LAYER = boolean;

const isOnlyEssential = (services: TService[]): boolean => {
    for (const svc of services) {
        if (!svc.isEssential) return false;
    }
    return true;
};

const COOKIE_LOCAL_STORAGE_NAME = 'cb_cookies';

const CookieBanner: React.FunctionComponent<CookieBannerProps> = ({
    labels,
    services,
}) => {
    const [cookiesEnabled, setCookiesEnabled] = React.useState(true);
    const [clickedInfo, setClickedInfo] = React.useState(false);
    const {t} = useTranslation();

    React.useEffect(() => {
        const cookie = localStorage.getItem(COOKIE_LOCAL_STORAGE_NAME);
        setCookiesEnabled(!!cookie);
    });

    if (cookiesEnabled) return <></>;
    return (
        <div className={'cookiebanner'}>
            <div className={'cookie-content'}>
                {!clickedInfo ? (
                    <>
                        <div className={'cookie-header'}>{labels.title}</div>
                        <div className={'cookie-body'}>
                            <div>{labels.description}</div>
                            <div className={'cookie-checkboxes'}>
                                {services.map((svc, index) => (
                                    <div
                                        key={'cb_' + index}
                                        className="mapp-checkbox"
                                    >
                                        <input
                                            type="checkbox"
                                            disabled={svc.isEssential}
                                            checked={svc.isEssential}
                                        />
                                        <label htmlFor={index.toString()}>
                                            {svc.name}
                                        </label>
                                    </div>
                                ))}
                            </div>

                            <div className={'cookie-buttons'}>
                                <button
                                    className="mapp-button mapp-button-primary w-full mb-2"
                                    onClick={() => {
                                        localStorage.setItem(
                                            COOKIE_LOCAL_STORAGE_NAME,
                                            '' + true,
                                        );
                                        setCookiesEnabled(true);
                                    }}
                                >
                                    {labels.acceptBtn}
                                </button>
                                {!isOnlyEssential(services) && (
                                    <button className="">
                                        {labels.denyBtn}
                                    </button>
                                )}
                                <button
                                    className="mapp-button mapp-button-secondary w-full"
                                    onClick={() => setClickedInfo(true)}
                                >
                                    {labels.infoBtn}
                                </button>
                            </div>
                            <div className={'cookie-footer'}>
                                <a href="https://web.staffice.de/privacy">
                                    {labels.policy}
                                </a>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={'cookie-header'}>{labels.infoBtn}</div>
                        {services.map((svc) => {
                            return (
                                <div key={svc.name}>
                                    <h5>{svc.name}</h5>
                                    <p>{svc.description}</p>
                                    <table className={'cookie-table'}>
                                        <tr>
                                            <th>Cookie</th>
                                            <th>{svc.descriptionHeader}</th>
                                            <th>{svc.vendorHeader}</th>
                                            <th>{svc.timeHeader}</th>
                                        </tr>
                                        {svc.cookies.map((cookie, index) => {
                                            return (
                                                <tr key={'cookie_' + index}>
                                                    <td>{cookie.cookieName}</td>
                                                    <td>{cookie.purpose}</td>
                                                    <td>{cookie.vendor}</td>
                                                    <td>
                                                        {
                                                            cookie.cookieExpireText
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </table>
                                </div>
                            );
                        })}

                        <button
                            type="button"
                            className="mapp-button mapp-button-secondary"
                            value="Back"
                            onClick={() => setClickedInfo(false)}
                        >
                            {labels.backBtn || 'Back'}
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default CookieBanner;
