import storageHandler from '../Storage/StorageHandler';
import { Config } from '../Config';
import HTTP from '../HTTP';
import { TPagination, TCustom } from '../Storage/Modules/NewsModule';
import { TPost } from '../Storage/Modules/PostsModule';

type TPostsResponse = {
	items: TPost[];
	pagination: TPagination;
};

type TParams = {
	sort?: string;
	order?: string;
	likes?: string;
	per_page?: number;
	page?: number;
	from?: string;
	to?: string;
};

/** Posts
 *
 * MAPP Posts Module
 *
 */
class Posts {
	store: typeof storageHandler = storageHandler;
	http: HTTP;
	readonly url = '/api/web/boards/posts';

	constructor() {
		this.http = new HTTP();
	}

	/**
	 * Fetch and store latest items. If nothing is set.
	 * Will reset pagination.
	 * @returns JSON
	 */
	get = async ({
		params,
		custom,
		boardId,
	}: {
		params?: TParams;
		custom?: TCustom;
		boardId?: string;
	}) => {
		const pagination = params;
		const id = boardId ? boardId : '';
		let nextParams: any = {};
		nextParams['boardId'] = id;

		if (pagination?.per_page && pagination?.page) {
			nextParams = Object.assign(nextParams, pagination);
		}
		if (params?.order) {
			// @ts-ignore
			nextParams['order'] = params.order;
		}

		// @ts-ignore
		const url = this.buildUrl(this.url, nextParams, custom);

		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		const jsonResult = await res.json();

		this.update(jsonResult);
		return jsonResult.items;
	};

	getPersonalPosts = async () => {
		const url = this.buildUrl('/api/web/boards/personalposts');

		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		const jsonResult = await res.json();

		this.update(jsonResult);
		return jsonResult.items;
	};

	/**
	 * Fetch by id
	 * @returns JSON
	 */
	getOneById = async (id: string) => {
		const url = this.buildUrl(`/api/web/posts/${id}`);
		const res = await this.http.json.httpGet(url);
		if (!res.ok) throw res.status;
		const jsonResult = await res.json();
		this.store.Posts.add([jsonResult]);
		return jsonResult;
	};

	createPost = async (payload: {
		category: string;
		text: string;
		headline: string;
		contact: string;
	}) => {
		const { category, text, headline, contact } = payload;

		const url = new URL(`/api/web/posts`, Config.host);

		const res = await this.http.json.httpPost(
			url,
			JSON.stringify({
				board: category,
				content: text,
				title: headline,
				contact: contact,
			})
		);

		if (!res.ok || res.status !== 201) {
			throw res.status;
		}

		return { ok: res.ok, status: res.status, response: res };
	};

	createDraft = async (payload: {
		category: string;
		text: string;
		headline: string;
		contact: string;
	}) => {
		const { category, text, headline, contact } = payload;

		const url = new URL(`/api/web/posts?draft=true`, Config.host);

		const res = await this.http.json.httpPost(
			url,
			JSON.stringify({
				board: category,
				content: text,
				title: headline,
				contact: contact,
			})
		);

		if (!res.ok || res.status !== 201) {
			throw res.status;
		}

		return { ok: res.ok, status: res.status, response: res };
	};

	updateDraft = async (
		id: string,
		payload: {
			category: string;
			text: string;
			headline: string;
			contact: string;
		}
	) => {
		const { category, text, headline, contact } = payload;

		const url = new URL(`/api/web/posts/${id}?draft=true`, Config.host);

		const res = await this.http.json.httpPost(
			url,
			JSON.stringify({
				board: category,
				content: text,
				title: headline,
				contact: contact,
			})
		);

		if (!res.ok || res.status !== 201) {
			throw res.status;
		}

		return { ok: res.ok, status: res.status, response: res };
	};

	updatePost = async (
		id: string,
		payload: {
			category: string;
			text: string;
			headline: string;
			contact: string;
		}
	) => {
		const { category, text, headline, contact } = payload;

		try {
			const res = await this.http.json.httpPost(
				new URL(`/api/web/posts/${id}`, Config.host),
				JSON.stringify({
					board: category,
					content: text,
					title: headline,
					contact: contact,
				})
			);

			if (!res.ok || res.status !== 201) {
				return res.status;
			}
			return { ok: res.ok, status: res.status, response: res };
		} catch (e) {
			throw e;
		}
	};

	addAttachment = async (
		id: string,
		files: Array<{ result: string | ArrayBuffer; name: string }>
	) => {
		const formData = new FormData();

		files.forEach((file) => {
			formData.append(
				`attachments[]`,
				// @ts-ignore
				this.dataURLtoFile(file.result, file.name),
				file.name
			);
		});
		try {
			const res = await this.http.json.httpUpload(
				new URL(`/api/web/posts/${id}/attachments`, Config.host),
				formData
			);
			if (!res.ok || res.status !== 201) {
				return res;
			}
			return { ok: res.ok, status: res.status, response: res };
		} catch (e) {
			throw e;
		}
	};

	like = async (id: string, likeBool: boolean) => {
		const url = this.buildUrl(`/api/web/boards/posts/${id}/likes`);
		const res = await this.http.json.httpPost(
			url,
			JSON.stringify({
				liked: likeBool,
			})
		);
		if (!res.ok) throw res.status;
		return res.ok;
	};

	comment = async ({
		id,
		text,
		reply = '',
	}: {
		id: string;
		text: string;
		reply: string | undefined;
	}) => {
		const url = this.buildUrl(`/api/web/boards/posts/comments`);
		let body = {
			post: id,
			text: text,
			reply: reply,
		};

		const res = await this.http.json.httpPost(url, JSON.stringify(body));

		if (!res.ok) throw res.status;

		return res.ok;
	};

	updateComment = async (payload: { id: string; text: string }) => {
		const { id, text } = payload;
		const url = this.buildUrl(`/api/web/boards/posts/comments/${id}`);

		let body = {
			post: id,
			text: text,
		};

		const res = await this.http.json.httpPatch(url, JSON.stringify(body));

		if (!res.ok) throw res.status;

		return res.ok;
	};

	deleteAttachments = async (picIds: string[]) => {
		try {
			const res = await this.http.json.httpPost(
				new URL(`/api/web/attachments/remove`, Config.host),
				JSON.stringify({ attachments: picIds })
			);
			if (!res.ok || res.status !== 202) {
				return res;
			}
			return { ok: res.ok, status: res.status, response: res };
		} catch (e) {
			throw e;
		}
	};

	deletePost = async (id: string) => {
		return await this.http.json.httpDelete(
			new URL(`/api/web/posts/${id}`, Config.host)
		);
	};

	downloadPostsDocumentById = async (id: string) => {
		const url = this.buildUrl(`${this.url}/document/${id}/download`);

		const res = await this.http.json.httpGet(url);

		if (res.ok || res.status == 200) {
			return res.blob();
		}
	};

	/**
	 * Get Posts by headline
	 * @returns JSON
	 */
	search = async (searchTerm: string, boardId?: string | null) => {
		const url = new URL(this.url, Config.host);
		const searchParams = {
			search: searchTerm,
		};
		if (boardId) {
			// @ts-ignore
			searchParams['boardId'] = boardId;
		}

		url.search = new URLSearchParams(searchParams).toString();

		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		const jsonResult: TPostsResponse = await res.json();
		this.update(jsonResult);
		return jsonResult.items;
	};

	/**
	 * Get next item from pagination and update pagination
	 * @returns JSON
	 */
	next = async (boardId: string) => {
		const pagination = this.store.Posts.getPagination();
		if (!pagination || !pagination.has_next_page) return undefined;

		const nextParams = {
			per_page: pagination.per_page,
			page: pagination.current_page + 1,
		};

		if (boardId !== 'all' && boardId !== 'own') {
			// @ts-ignore
			nextParams['boardId'] = boardId;
		}

		if (pagination?.from) {
			// @ts-ignore
			nextParams['from'] = pagination.from;
		}

		if (pagination?.to) {
			// @ts-ignore
			nextParams['to'] = pagination.to;
		}

		const url = this.buildUrl(this.url, nextParams);
		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		const jsonResult: TPostsResponse = await res.json();

		this.update(jsonResult);
		return jsonResult.items;
	};

	/**
	 * check if has next page
	 * @returns
	 */
	hasNext = () => this.store.Posts.getPagination()?.has_next_page || false;

	/**
	 * get current page
	 * @returns {number} page number
	 */
	currentPage = (): number =>
		this.store.Posts.getPagination()?.current_page || 0;

	/**
	 * get perPage
	 * @returns {number} item amount
	 */
	perPage = (): number => this.store.Posts.getPagination()?.per_page || 0;

	/**
	 * get total items
	 * @returns {number} total items
	 */
	totalItems = (): number =>
		this.store.Posts.getPagination()?.total_items || 0;

	/**
	 * get total pages
	 * @returns {number} total pages
	 */
	totalPages = (): number =>
		this.store.Posts.getPagination()?.total_pages || 0;

	/**
	 * Get previous item from pagination and update pagination
	 * @returns JSON
	 */
	previous = async (boardId: string) => {
		const pagination = this.store.Posts.getPagination();
		if (!pagination || !pagination.has_previous_page) return undefined;

		const nextParams = {
			per_page: pagination.per_page,
			page: pagination.current_page - 1,
		};

		if (boardId !== 'all' && boardId !== 'own') {
			// @ts-ignore
			nextParams['boardId'] = boardId;
		}

		const url = this.buildUrl(this.url, nextParams);
		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		const jsonResult: TPostsResponse = await res.json();

		this.update(jsonResult);
		return jsonResult.items;
	};

	/**
	 * check if has previous page
	 * @returns
	 */
	hasPrev = () =>
		this.store.Posts.getPagination()?.has_previous_page || false;

	/**
	 * Get Video Url for Posts
	 * @param id
	 * @returns Posts Video Url
	 */
	getPostsVideo = async (id: string) => {
		const url = this.buildUrl(`${this.url}/${id}/video`);

		return url;
	};

	private buildUrl = (
		apiPath: string,
		params?: TParams,
		custom?: TCustom
	): URL => {
		const url = new URL(apiPath, Config.host);

		if (params) {
			// @ts-ignore
			url.search = new URLSearchParams(params).toString();
		}

		const filter = custom || this.store.Posts.getCustom();
		if (filter) {
			if (
				filter.name !== '' &&
				filter.type === 'array' &&
				filter.content
			) {
				filter.content.forEach((item) => {
					url.searchParams.append('channels[]', item);
				});
			}
		}
		return url;
	};

	private update = (res: TPostsResponse, custom?: TCustom) => {
		this.store.Posts.add(res.items);
		this.store.Posts.setPagination(res.pagination);
		if (custom) {
			this.store.Posts.setCustom(custom);
		}
	};

	private dataURLtoFile = (dataurl: string, filename: string) => {
		var arr = dataurl.split(','),
			// @ts-ignore
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		let extension = '.jpg';

		switch (mime) {
			// @ts-ignore
			case mime.includes('png'):
				extension = '.png';
				break;
			// @ts-ignore
			case mime.includes('mp4'):
				extension = '.mp4';
				break;
		}

		return new File([u8arr], filename.replaceAll(' ', '_') + extension, {
			type: mime,
		});
	};
}

export default Posts;
