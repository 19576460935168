import {clearAll} from '@atino/libs/browser/storage';
import AuthModule from './Modules/AuthModule';
import CategoryModule from './Modules/CategoryModule';
import ConfigurationModule from './Modules/ConfigurationModule';
import ChatUsersModule from './Modules/ChatUsersModule';
import NewsModule from './Modules/NewsModule';
import ImagesModule from './Modules/ImagesModule';
import BoardsModule from './Modules/BoardsModule';
import PostsModule from './Modules/PostsModule';
import DocumentModule from './Modules/DocumentModule';
import ContactModule from './Modules/ContactModule';
import UserModule from './Modules/UserModule';
import CouponModule from './Modules/CouponModule';
import ToolsModule from './Modules/ToolsModule';
import WikiModule from './Modules/WikiModule';
import FormsModule from './Modules/FormsModule';
import SickNoteModule from './Modules/SickNoteModule';

class StorageHandler {
    Auth = new AuthModule();
    News = new NewsModule();
    Boards = new BoardsModule();
    Posts = new PostsModule();
    Category = new CategoryModule();
    Configuration = new ConfigurationModule();
    ChatUsers = new ChatUsersModule();
    Images = new ImagesModule();
    Documents = new DocumentModule();
    Contact = new ContactModule();
    User = new UserModule();
    Coupon = new CouponModule();
    Tools = new ToolsModule();
    Wiki = new WikiModule();
    Forms = new FormsModule();
    SickNoteModule = new SickNoteModule();

    clear = () => {
        clearAll();
    };
}
const storageHandler = new StorageHandler();
export default storageHandler;
