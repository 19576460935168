import matrixcs, {MatrixEvent} from 'matrix-js-sdk';
import {TAuthResponse} from './auth';
type TUserImage = {
    sender: string;
    url: string;
};
type TEvent = {
    eventId: string;
    event: MatrixEvent;
};

type TStore = {
    client: matrixcs.MatrixClient;
    phrase: string;
    auth?: TAuthResponse;
    userImages: TUserImage[];
    events: TEvent[];
};
const store: TStore = {
    client: matrixcs.createClient({baseUrl: ""}),
    phrase: '',
    userImages: [],
    events: [],
};

export default store;
