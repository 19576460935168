import StorageHandler from '../Storage/StorageHandler';
import { Config } from '../Config';
import HTTP from '../HTTP';
import { TPagination, TNews, TCustom } from '../Storage/Modules/NewsModule';

type TNewsResponse = {
	items: TNews[];
	pagination: TPagination;
};

export type TSurveyAnswers = {
	question: string;
	result: number;
};

export type TSurveyAnswer = {
	survey: string;
	surveyAnswers: TSurveyAnswers[];
};

export type TSurveyAnswerResults = {
	survey: {
		id: string;
		name: string;
		totalResults: number;
	};
	results: [
		{
			question: string;
			choiceDescription: string;
			totalAnswers: number;
			answers: [
				{
					answer: number;
					answerCount: number;
				}
			];
		}
	];
};

type TParams = {
	sort?: string;
	order?: string;
	likes?: string;
	important?: string;
	per_page?: number;
	perPage?: number;
	current_page?: number;
	page?: number;
	from?: string;
	to?: string;
	survey?: string;
	surveyAnswers?: TSurveyAnswers[];
};

interface INews {
	http: HTTP;
}

/** News
 *
 * MAPP News Module
 *
 */
class News implements INews {
	store = StorageHandler;
	http: HTTP;

	constructor() {
		this.http = new HTTP();
	}

	/**
	 * Fetch and store latest news items. If nothing is set.
	 * Will reset pagination.
	 * @returns JSON
	 */
	get = async ({
		params,
		custom,
	}: {
		params?: TParams;
		custom?: TCustom;
	}) => {
		const pagination = params || this.store.News.getPagination();
		let nextParams = {
			perPage: pagination?.per_page,
			page: params?.page || pagination?.current_page,
			order: 'desc',
		};

		if (params?.order) {
			// @ts-ignore
			nextParams['order'] = params.order;
		}
		if (params?.likes) {
			// @ts-ignore
			nextParams['likes'] = params.likes;
		}
		if (params?.important) {
			// @ts-ignore
			nextParams['important'] = params.important;
		}

		if (params?.from) {
			// @ts-ignore
			nextParams['from'] = params.from;
		}

		if (params?.to) {
			// @ts-ignore
			nextParams['to'] = params.to;
		}

		// @ts-ignore
		const url = this.buildUrl('/api/web/news', nextParams, custom);

		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		const jsonResult = await res.json();

		this.updateNews(jsonResult);
		return jsonResult.items;
	};

	/**
	 * Fetch by id
	 * @returns JSON
	 */
	getOneById = async (id: string) => {
		const url = this.buildUrl(`/api/web/news/${id}`);
		const res = await this.http.json.httpGet(url);
		if (!res.ok) throw res.status;
		const jsonResult = await res.json();
		this.store.News.add([jsonResult.data]);
		return jsonResult.data;
	};

	downloadNewsDocumentById = async (id: string) => {
		const url = this.buildUrl(`/api/web/news/document/${id}/download`);

		const res = await this.http.json.httpGet(url);

		if (res.ok && res.status === 200) {
			return {
				response: res,
				blob: await res.blob(),
			};
		}
	};

	getBanner = async () => {
		const url = this.buildUrl(`/api/web/banners`);
		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		if (res.ok && res.status === 200) {
			return await res.json();
		}
	};

	getPinned = async () => {
		const url = this.buildUrl(`/api/web/pinned`);
		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		if (res.ok && res.status === 200) {
			return await res.json();
		}
	};

	createComment = async (payload: {
		id: string;
		text: string;
		reply?: string;
	}) => {
		const { id: news, text, reply } = payload;
		const url = this.buildUrl('/api/web/news/comments');

		const res = await this.http.json.httpPost(
			url,
			JSON.stringify({
				news,
				text,
				reply,
			})
		);

		if (res.ok) {
			return res;
		}
	};

	like = async (id: string) => {
		const url = this.buildUrl(`/api/web/news/${id}/likes`);
		const res = await this.http.json.httpGet(url);

		if (res.ok || res.status == 200) {
			return res;
		}
	};

	unlike = async (id: string) => {
		const url = this.buildUrl(`/api/web/news/${id}/unlike`);
		const res = await this.http.json.httpDelete(url);

		if (res.ok || res.status == 200) {
			return res;
		}
	};

	visit = async (id: string) => {
		const url = this.buildUrl(`/api/web/news/${id}/visited`);
		const res = await this.http.json.httpGet(url);

		if (res.ok || res.status == 200) {
			return res;
		}
	};

	/**
	 * Get News by headline
	 * @returns JSON
	 */
	search = async (searchTerm: string) => {
		const url = new URL('/api/web/news', Config.host);
		url.search = new URLSearchParams({
			headline: searchTerm,
		}).toString();

		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		const jsonResult: TNewsResponse = await res.json();
		this.updateNews(jsonResult);
		return jsonResult.items;
	};

	/**
	 * Get next news from pagination and update pagination
	 * @returns JSON
	 */
	next = async (custom?: TCustom) => {
		const pagination = this.store.News.getPagination();
		if (!pagination || !pagination.has_next_page) return undefined;

		const nextParams = {
			perPage: pagination.per_page,
			page: pagination.current_page + 1,
		};

		if (pagination?.from) {
			// @ts-ignore
			nextParams['from'] = pagination.from;
		}

		if (pagination?.to) {
			// @ts-ignore
			nextParams['to'] = pagination.to;
		}

		const url = this.buildUrl('/api/web/news', nextParams, custom);
		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		const jsonResult: TNewsResponse = await res.json();

		this.updateNews(jsonResult);
		return jsonResult.items;
	};

	/**
	 * check if has next page
	 * @returns
	 */
	hasNext = () => this.store.News.getPagination()?.has_next_page || false;

	/**
	 * get current page
	 * @returns {number} page number
	 */
	currentPage = (): number =>
		this.store.News.getPagination()?.current_page || 1;

	/**
	 * get perPage
	 * @returns {number} item amount
	 */
	perPage = (): number => this.store.News.getPagination()?.per_page || 0;

	/**
	 * get total items
	 * @returns {number} total items
	 */
	totalItems = (): number =>
		this.store.News.getPagination()?.total_items || 0;

	/**
	 * get total pages
	 * @returns {number} total pages
	 */
	totalPages = (): number =>
		this.store.News.getPagination()?.total_pages || 0;

	/**
	 * Get previous news from pagination and update pagination
	 * @returns JSON
	 */
	previous = async (custom?: TCustom) => {
		const pagination = this.store.News.getPagination();
		if (!pagination || !pagination.has_previous_page) return undefined;

		const nextParams = {
			per_page: pagination.per_page,
			page: pagination.current_page - 1,
		};

		const url = this.buildUrl('/api/web/news', nextParams, custom);
		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		const jsonResult: TNewsResponse = await res.json();

		this.updateNews(jsonResult);
		return jsonResult.items;
	};

	/**
	 * check if has previous page
	 * @returns
	 */
	hasPrev = () => this.store.News.getPagination()?.has_previous_page || false;

	/**
	 * Get Video Url for News
	 * @param id
	 * @returns News Video Url
	 */
	getNewsVideo = async (id: string) => {
		const url = this.buildUrl(`/api/web/news/${id}/video`);

		return url;
	};

	saveSurvey = async (surveyAnswer: TSurveyAnswer) => {
		const url = this.buildUrl('/api/web/surveys');

		const res = await this.http.json.httpPost(
			url,
			JSON.stringify({
				survey: surveyAnswer?.survey,
				surveyAnswers: surveyAnswer?.surveyAnswers,
			})
		);
		if (!res.ok) throw res;

		return res.json();
	};

	getSurveyResults = async (surveyId: string) => {
		const url = this.buildUrl(`/api/web/surveys/${surveyId}/results`);

		const res = await this.http.json.httpGet(url);

		if (!res.ok) return;
		return await res.json();
	};

	private buildUrl = (
		apiPath: string,
		params?: TParams,
		custom?: TCustom
	): URL => {
		const url = new URL(apiPath, Config.host);

		if (params) {
			// @ts-ignore
			url.search = new URLSearchParams(params).toString();
		}

		const filter = custom || this.store.News.getCustom();
		if (filter) {
			if (
				filter.name !== '' &&
				filter.type === 'array' &&
				filter.content
			) {
				filter.content.forEach((item) => {
					url.searchParams.append('channels[]', item);
				});
			}
		}
		return url;
	};

	private updateNews = (res: TNewsResponse, custom?: TCustom) => {
		this.store.News.add(res.items);
		this.store.News.setPagination(res.pagination);
		if (custom) {
			this.store.News.setCustom(custom);
		}
	};
}

export default News;
