import StorageModule from '../StorageModule';

export type TForms = {
	name: string;
	id: string;
};

type Submittable = "single" | "multiple"

export type TForm = {
	id: string;
	name: string;
	signatureRequired: boolean;
	externalSignatureRequired: boolean;
	encryptionRequired: boolean;
	copyTo: boolean;
	submittable: Submittable;
	publicKeyContent: string;
	formFields: TFormField[];
	formAttachments: TFormAttachment[];
};

export interface FileCategory {
	name: string;
	fileNamePart: string;
	required: boolean;
}

export type TFormField = {
	id: string;
	label: string;
	name: string;
	value: string;
	type: string;
	placeholder: string;
	required: boolean;
	help: string;
	allowScanner: boolean;
	additionalAttachments: boolean;
	fileCategories: FileCategory[]
	constraints: [
		{
			constraint: string;
			rules: [string];
		}
	];
	list: [
		{
			id: string;
			text: string;
			required: boolean;
		}
	];
	position: number;
};

export type TFormAttachment = {
	id: string;
	fileName: string;
	fileSize: number;
	mimeType: string;
	originalName: string;
	downloadLink: string;
};

type TStore = {
	forms: TForms[];
};

let store: TStore = {
	forms: [],
};

class FormsModule extends StorageModule<TStore> {
	constructor() {
		super({ name: 'forms', store });
	}
}

export default FormsModule;
