import { Config } from '../Config';
import HTTP from '../HTTP';
import {TCategoryItem} from '../Storage/Modules/CategoryModule';
import StorageHandler from '../Storage/StorageHandler';

class Contacts {
    store = StorageHandler;
    http = new HTTP();

    /**
     * Fetch and store latest categories
     * @returns JSON
     */
    get = async () => {
        const url = new URL('/api/web/contact-list', Config.host);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();
        this.update(jsonResult.data);
        return jsonResult;
    };

    private update = (data: any[]) => {
        this.store.Contact.set({items: data});
    };
}

export default Contacts;
