import * as React from 'react';
import Lottie from 'lottie-react';
import ErrorAnimation from '../assets/images/127413-robot-error-alert.json';
import {withTranslation} from 'react-i18next';
import sdk from '../libs/mapp/sdk';
import { logout }from '../libs/matrix/auth';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: props.hasError || false,
            networkError: props.networkError || false,
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
    }

    render() {
        // @ts-ignore
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
				<div>
					<div className="flex flex-col justify-center items-center h-[50vh]">
						<div className="mobile:w-3/4 tablet:w-1/2">
							<Lottie
								animationData={ErrorAnimation}
								loop={true}
							/>{' '}
						</div>
						{/* @ts-ignore */}
						{!this.state.networkError ? (
							// @ts-ignore
							<h3>{this.props.t('error_info')}</h3>
						) : (
							// @ts-ignore
							<h3>{this.props.t('error_network')}</h3>
						)}
						<button
							className="mapp-button mapp-button-primary mt-4"
							onClick={() => {
								// @ts-ignore
								location.reload();
							}}>
							{/* @ts-ignore */}
							{this.props.t('back_start')}
						</button>
						<button
							className="mapp-button mapp-button-primary mt-4"
							onClick={() => {
								sdk.Auth.logout().finally(() => {
									logout().finally(() => {
										location.href = '/login';
									});
								});
							}}>
							Logout
						</button>
					</div>
				</div>
			);
        }
        // @ts-ignore
        return this.props.children;
    }
}

export default withTranslation()(ErrorBoundary);
