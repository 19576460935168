import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { SyncState } from 'matrix-js-sdk/lib/sync';
import AppState from '../../app';

export interface AppState {
	activeModal: string;
	userListRoom: string;
	chatMessageDelete?: {
		roomId: string;
		eventId: string;
	};
	uploadModal?: {
		files?: any;
		fileName?: string;
	};
	uploadFileModal?: {
		files: any;
		fileName: string;
		fileType: string;
		fileSize: number;
	};
	imageModal?: {
		url: string;
	};
	chatLeaveRoomModal?: {
		roomId: string;
	};
	restored: boolean;
	syncState: { state: SyncState; lastState: SyncState | null; data: any };
	apiState: { state: 'ok' | 'error'; message: string };
	state:
		| 'new'
		| 'loggedOut'
		| 'loading'
		| 'no_restore'
		| 'restoring'
		| 'prepared'
		| 'ready'
		| 'error';
}

const initialState: AppState = {
	activeModal: '',
	userListRoom: '',
	chatMessageDelete: undefined,
	uploadModal: undefined,
	syncState: { state: SyncState.Prepared, lastState: null, data: null },
	apiState: { state: 'ok', message: '' },
	restored: false,
	state: 'new',
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		toggleUserListModal: (state, { payload }) => {
			const { open, room } = payload;

			state.activeModal = open ? 'userRoomList' : '';
			state.userListRoom = room;
		},
		toggleJoinRoomModal: (state, { payload }) => {
			const { open } = payload;
			state.activeModal = open ? 'joinRoomModal' : '';
		},
		toggleChatLeaveRoom: (state, { payload }) => {
			state.chatLeaveRoomModal = { roomId: payload.roomId };
			state.activeModal = 'chatLeaveRoom';
		},
		openUploadModal: (state, { payload }) => {
			state.uploadModal = payload;
			state.activeModal = 'uploadModal';
		},
		openUploadFileModal: (state, { payload }) => {
			state.uploadFileModal = payload;
			state.activeModal = 'uploadFileModal';
		},
		openImageModal: (state, { payload }) => {
			state.imageModal = { url: payload.url };
			state.activeModal = 'imageModal';
		},
		openChatMessageDeleteModal: (state, { payload }) => {
			state.chatMessageDelete = payload;
			state.activeModal = 'chatMessageDeleteModal';
		},
		updateState: (state, { payload }: { payload: AppState['state'] }) => {
			state.state = payload;
		},
		updateRestored: (state, { payload }: { payload: boolean }) => {
			state.restored = payload;
		},
		updateSyncState: (
			state,
			{
				payload,
			}: {
				payload: AppState['syncState'];
			}
		) => {
			state.syncState = payload;
		},
		updateApiState: (
			state,
			{ payload }: { payload: AppState['apiState'] }
		) => {
			state.apiState = payload;
		},
		hideActiveModal: (state) => {
			state.activeModal = '';
		},
	},
});

export const {
	toggleUserListModal,
	toggleJoinRoomModal,
	toggleChatLeaveRoom,
	openUploadModal,
	openUploadFileModal,
	openImageModal,
	openChatMessageDeleteModal,
	updateState,
	updateRestored,
	updateSyncState,
	hideActiveModal,
	updateApiState,
} = appSlice.actions;

export const selectUserListModal = (state: RootState) => {
	return {
		activeModal: state.app.activeModal,
		userListRoom: state.app.userListRoom,
	};
};

export const selectChatMessageDeleteModal = (state: RootState) => {
	return state.app.chatMessageDelete;
};
export const selectChatLeaveRoomModal = (state: RootState) => {
	return state.app.chatLeaveRoomModal;
};

export const selectActiveModal = (state: RootState) => {
	return state.app.activeModal;
};

export const selectUploadModal = (state: RootState) => {
	return state.app.uploadModal;
};

export const selectUploadFileModal = (state: RootState) => {
	return state.app.uploadFileModal;
};

export const selectImageModal = (state: RootState) => {
	return state.app.imageModal;
};

export const selectState = (state: RootState) => {
	return state.app.state;
};
export const selectRestored = (state: RootState) => {
	return state.app.restored;
};
export const selectSyncState = (state: RootState) => {
	return state.app.syncState;
};
export const selectApiState = (state: RootState) => {
	return state.app.apiState;
};

export default appSlice.reducer;
