import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { hideActiveModal, selectActiveModal } from '../../reducer/app';
import ChatLeaveRoomModal from './ChatLeaveRoomModal';
import ImageModal from './ImageModal';
import JoinRoomModal from './JoinRoomModal';
import RoomListModal from './RoomListModal';
import UploadModal from './UploadModal';
import ChatMessageDeleteModal from './ChatMessageDeleteModal';
import UploadFileModal from './UploadFileModal';

const ModalIndex: React.FC = () => {
    const dispatch = useAppDispatch();
    const activeModal = useAppSelector(selectActiveModal);

    React.useEffect(() => {
        const hideModal = (evt: any) => {
            const selected = document.querySelector("[data-type=modal]");
            if (!selected) return evt;
            if (!selected?.contains(evt.target)) {
                dispatch(hideActiveModal());
            }
        }
        if(activeModal !== "") {
            window.addEventListener("mousedown", hideModal);
        }

        return () => {
            window.removeEventListener("mousedown", hideModal);
        }
    }, [activeModal])

    switch (activeModal) {
        case 'userRoomList':
            return <RoomListModal />;
        case 'joinRoomModal':
            return <JoinRoomModal />;
        case 'chatLeaveRoom':
            return <ChatLeaveRoomModal />
        case 'chatMessageDeleteModal':
            return <ChatMessageDeleteModal />
        case 'uploadModal':
            return <UploadModal />
        case 'uploadFileModal':
                return <UploadFileModal />
        case 'imageModal':
            return <ImageModal />
        default:
            return <div hidden={true}></div>;
    }
};

export default ModalIndex;
