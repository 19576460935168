import { Config } from '../Config';
import HTTP from '../HTTP';
import StorageHandler from '../Storage/StorageHandler';

class ChatUsers {
    store = StorageHandler;
    http = new HTTP();

    /**
     * Fetch and store latest categories
     * @returns JSON
     */
    get = async (params?: {
        searchTerm?: string;
        page?: string;
        perPage?: string;
        limit?: string;
    }) => {
        const url = new URL('/api/web/chat-users', Config.host);

        if (params) {
            url.search = new URLSearchParams(params).toString();
        }

        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();
        this.store.ChatUsers.set(jsonResult);
        return jsonResult;
    };
}

export default ChatUsers;
