import StorageModule from '../StorageModule';

export type TDocumentFolder = {
	availableChildren: boolean;
	documentCount: number;
	id: string;
	name: string;
	kind: 'folder';
	parents?: {};
};

export type TDocument = {
	createdAt: string;
	fileExtension: string;
	id: string;
	name: string;
	size: number;
	kind: 'document';
};

type TStore = {
	folder: TDocumentFolder[];
	documents: TDocument[];
};

let store: TStore = {
	folder: [],
	documents: [],
};

class DocumentModule extends StorageModule<TStore> {
	constructor() {
		super({ name: 'documents', store });
	}
}

export default DocumentModule;
