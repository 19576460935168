import * as React from 'react';
import mcli from "../../libs/matrix";
import {downloadFile} from '@atino/libs/common/download';

import {TFile, getDecryptedImageUrl} from '../../libs/matrix/message';

type TProps = {
    file?: TFile;
    name: string;
    url?: string;
};

const File: React.FC<TProps> = React.memo(({file, name, url}) => {
    const download = () => {
        if (!file) return;

        getDecryptedImageUrl(file)
            .then((resUrl) => {
                if (resUrl) {
                    downloadFile(resUrl, name);
                }
            })
            .catch(console.error);
    };

    return (
        <>
            <div className="h-fill w-fill">
                <div className="bg-slate-200 p-2 text-gray-800 rounded">
                    {name}
                </div>
                {url ? (
                    <a
                        className="text-xs text-gray-500 cursor-pointer"
                        onClick={() => downloadFile(mcli.mxcUrlToHttp(url) || url, name)}
                    >
                        download
                    </a>
                ) : (
                    <a
                        className="text-xs text-gray-500 cursor-pointer"
                        onClick={download}
                    >
                        download
                    </a>
                )}
            </div>
        </>
    );
});

export default File;
