import {loadLocal} from '@atino/libs/browser/storage';
import {Config} from './Config';
import {setStore} from './Storage/Local';

import HTTP from './HTTP';
import Auth from './Auth';
import News from './News';
import Boards from './Boards';
import StorageHandler from './Storage/StorageHandler';
import Configuration from './Configuration';
import Category from './Category';

import ChatUsers from './ChatUsers';
import Images from './Images';
import Posts from './Posts';
import Documents from './Documents';
import Contacts from './Contacts';
import User from './User';
import Coupon from './Coupon';
import Tools from './Tools';
import Wiki from './Wiki';
import Time from './Time';
import Forms from './Forms';
import SickNote from './SickNote';

/** MappSDK
 *
 * provides functionality to access the MAPP (staffice) API.
 *
 */
class MappSDK extends HTTP {
    http = new HTTP();
    config = Config;
    Store = StorageHandler;
    Auth = new Auth();
    News = new News();
    Boards = new Boards();
    Posts = new Posts();
    Configuration = new Configuration();
    Category = new Category();
    ChatUsers = new ChatUsers();
    Images = new Images();
    Documents = new Documents();
    Contacts = new Contacts();
    User = new User();
    Coupon = new Coupon();
    Tools = new Tools();
    Wiki = new Wiki();
    Time = new Time();
    Forms = new Forms();
    SickNote = new SickNote()

    local = false;

    constructor({host = '', local = false, useCookie = true}) {
        super();
        if (typeof window !== 'undefined' && localStorage && local) {
            this.config.local = local;
            setStore(loadLocal('mapp-sdk'));
        }
        this.config.useCookie = useCookie;
        this.config.host = host;
    }
}
export default MappSDK;
