import {loadLocal} from '@atino/libs/browser/storage';
import * as matrixcs from 'matrix-js-sdk';
import {logger} from 'matrix-js-sdk/lib/logger';
import auth from './auth';
import store from './store';
logger.disableAll();
const BASE_URL = process.env.MATRIX_URL || '';
store.auth = loadLocal('chat');
store.phrase = '';

store.client = matrixcs.createClient({baseUrl: BASE_URL});
if (store.auth) {
    auth.loginWithToken(store.auth);
}

export {auth};
export default store.client;
