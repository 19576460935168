import { useLottie } from 'lottie-react';
import { ClientEvent } from 'matrix-js-sdk/lib/client';
import { SyncState } from 'matrix-js-sdk/lib/sync';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import loadingAnimation from './assets/animations/loading.json';
import App from './components/App';
import BackupRestore from './components/BackupRestore';
import PreparingChat from './components/PreparingChat';
import { useAppDispatch, useAppSelector } from './hooks';
import './i18n';
import AppLayout from './layouts/AppLayout';
import mcli from './libs/matrix';
import store from './libs/matrix/store';
import { selectState, updateState, updateSyncState } from './reducer/app';
import { getRoomsAsync } from './reducer/matrix';
import { updateMatrixProfile } from './reducer/user';
mcli.setMaxListeners(500);

const AppState: React.FC = () => {
	const appState = useAppSelector(selectState);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	moment.locale(navigator.language);
	const animationOptions = {
		animationData: loadingAnimation,
		loop: true,
	};

	const { View: LottieView } = useLottie(animationOptions);

	React.useEffect(() => {
		if (appState === 'new') {
			// @ts-ignore
			mcli.on(
				ClientEvent.Sync, statePreparedCallback
			);
		}
		return () => {
			mcli.removeListener(
				ClientEvent.Sync, statePreparedCallback
			);
		};
	}, [appState]);

        const statePreparedCallback =
                (state: SyncState, lastState: SyncState | null, data: any) => {
                        dispatch(updateSyncState({ state, lastState, data }));
                        if (state === 'PREPARED') {
                                dispatch(updateMatrixProfile());
                                dispatch(getRoomsAsync()).finally(() => {
                                        dispatch(updateState('prepared'));
                                });
                        }
                }

	if (appState === 'prepared') {
		return <PreparingChat />;
	}

	if (appState === 'no_restore' && store.client.getCrypto()) {
		return <BackupRestore />;
	}

	if (appState === 'ready') {
		return (
			<AppLayout>
				<App />
			</AppLayout>
		);
	}

	return (
		<div className="h-screen w-screen flex flex-col justify-center items-center">
			<div className="w-1/3 flex flex-col justify-center items-center">
				{LottieView}
				<div>{t('loading_chat')}</div>
			</div>
		</div>
	);
};

export default AppState;
