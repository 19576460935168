import StorageHandler from '../Storage/StorageHandler';
import { Config } from '../Config';
import HTTP from '../HTTP';
import { TDocumentFolder } from '../Storage/Modules/DocumentModule';

type TParams = {
	category?: string;
	name?: string;
};

type TChildFolder = TDocumentFolder;

class Documents {
	http = new HTTP();
	store = StorageHandler;

	getRoot = async () => {
		try {
			const url = new URL(
				'/api/web/root-document-categories',
				Config.host
			);
			const res = await this.http.json.httpGet(url);

			if (!res.ok) {
				throw res.status;
			}

			const result = await res.json();
			this.store.Configuration.set(result);

			return result;
		} catch (e) {
			console.error(e);
		}
	};

	getDocuments = async (id = '') => {
		const params = {
			category: id,
		};
		const url = this.buildUrl(`/api/web/documents`, params);

		const res = await this.http.json.httpGet(url);

		if (!res.ok) {
			throw res.status;
		}

		const result = await res.json();

		return result;
	};

	getChildFolders = async (id: string) => {
		const url = this.buildUrl(
			`/api/web/document-category/${id}/child-list`
		);

		const res = await this.http.json.httpGet(url);

		if (!res.ok) {
			throw res.status;
		}

		const result: { data: TChildFolder[]; breadCrumbs: any } =
			await res.json();

		return result;
	};

	download = async (id: string) => {
		const url = this.buildUrl(`/api/web/document/${id}/download`);

		const res = await this.http.json.httpGet(url);
		if (!res.ok) {
			throw res.status;
		}

		if (res.ok && res.status === 200) {
			return {
				response: res,
				blob: await res.blob(),
			};
		}
	};

	search = async (payload: { searchParam: string; id: string }) => {
		const params = {
			name: payload.searchParam,
			category: payload.id,
		};
		const url = this.buildUrl(`/api/web/documents`, params);
		const res = await this.http.json.httpGet(url);
		const resJson = await res.json();

		if (!res.ok) {
			throw res.status;
		}

		if (res.ok || res.status === 200) {
			return await resJson;
		}
	};

	private buildUrl = (apiPath: string, params?: TParams): URL => {
		const url = new URL(apiPath, Config.host);

		if (params) {
			// @ts-ignore
			url.search = new URLSearchParams(params).toString();
		}

		return url;
	};
}
export default Documents;
