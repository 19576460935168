import { Config } from '../Config';
import HTTP from '../HTTP';
import { appHeaders } from '../Headers';
import StorageHandler from '../Storage/StorageHandler';
import {
	AESEncryptionWithIv,
	generateKeyAndIv,
	getDataHash,
	getIv,
	rsaEncryption,
	encryptFile,
} from '@atino/libs/cryptography/crypto';
import { saveAs } from 'file-saver';
import { SickNoteState, TPostSickNote } from '../Storage/Modules/SickNoteModule';

type TParams = {
	order?: string;
	per_page?: number;
	page?: number;
};
class SickNote {
	store = StorageHandler;
	http = new HTTP();

	submitSickNote = async (
		dataParams: SickNoteState,
	) => {
		const url = new URL(`/api/web/sick-leave`, Config.host);

		// console.log("sickNoteData: ", dataParams.data)
		try {

			const formData = new FormData();

            formData.append('start', dataParams.start?.toISOString().split('T')[0] ?? '');
            formData.append('end', dataParams.end?.toISOString().split('T')[0] ?? '');
            dataParams.comment !== '' && formData.append('comment', dataParams.comment);
            if(dataParams.image){
                const fileKeyAndIv = await generateKeyAndIv();
                const fileKey = fileKeyAndIv.fileKey
                const fileIv =  fileKeyAndIv.fileIV
                const encryptedFile =
                    await encryptFile(
                        dataParams.image,
                        fileKey,
                        fileIv
                    );
                formData.append('file', encryptedFile);
                formData.append('fileKey', fileKey);
                formData.append('fileIv', fileIv);
            }

			// console.log("FormData: ", formData)
            const res = await this.http.json.httpPost(
                url,
                formData,
                undefined,
                true
            );
            if (!res.ok){
                const errorResponse = await res.json();
                throw errorResponse
            }
            return res;
		} catch (err) {
			throw err;
		}
	};

	
}

export default SickNote;
