import { Config }  from '../Config';
import HTTP from '../HTTP';
import {TCategoryItem} from '../Storage/Modules/CategoryModule';
import StorageHandler from '../Storage/StorageHandler';

class Category {
    store = StorageHandler;
    http = new HTTP();

    /**
     * Fetch and store latest categories
     * @returns JSON
     */
    get = async () => {
        const url = new URL('/api/web/news/category', Config.host);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();
        this.update(jsonResult.data);
        return jsonResult;
    };

    subscribe = async (id: string) => {
        const url = new URL(
            `/api/web/news/category/${id}/subscribe`,
            Config.host,
        );
        const res = await this.http.json.httpPost(url);

        if (!res.ok) throw res.status;
        this.get();
        return await this.get();
    };
    unsubscribe = async (id: string) => {
        const url = new URL(
            `/api/web/news/category/${id}/unsubscribe`,
            Config.host,
        );
        const res = await this.http.json.httpPost(url);

        if (!res.ok) throw res.status;
        return await this.get();
    };

    private update = (data: TCategoryItem[]) => {
        this.store.Category.set({items: data});
    };
}

export default Category;
