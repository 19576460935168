import * as React from 'react';
import mcli from '../../libs/matrix';

import {Room} from 'matrix-js-sdk';
import {getBackup, restoreBackupWithPassphrase} from '../../libs/matrix/backup';
import {getDecryptedImageUrl, TFile} from '../../libs/matrix/message';
import {useTranslation} from 'react-i18next';
import loadingAnimation from '../../assets/animations/loading.json';
import {useLottie} from 'lottie-react';
type TProps = {
    passphrase: string;
    callback: (error: Error | undefined, success: boolean) => void;
};
const Restore: React.FC<TProps> = ({passphrase, callback}) => {
    const animationOptions = {
        animationData: loadingAnimation,
        loop: true,
    };

    const {View: LottieView} = useLottie(animationOptions);
    const {t} = useTranslation();
    React.useEffect(() => {
        restoreBackupWithPassphrase(passphrase)
            .then(() => {
                getBackup().then((bk) => {
                    if (bk?.backupInfo) mcli.enableKeyBackup(bk.backupInfo);
                });
                recurseRoomDecrypt()
                    .catch(console.warn)
                    .finally(() => {
                        callback(undefined, true);
                    });
            })
            .catch((error) => {
                callback(error, false);
                console.error('Error restoring backup. ', error);
            });
    }, []);

    const decryptRoom = (room: Room) => {
        if (room.getMyMembership() !== 'join') return Promise.reject();

        return new Promise((resolve) => {
            const events = room.getLiveTimeline().getEvents();

            let count = 0;
            events.forEach((evt) => {
                if (evt.isEncrypted()) {
                    mcli.decryptEventIfNeeded(evt).finally(() => {
                        const file: TFile = evt.getContent()?.file;
                        if (
                            evt.getContent()?.msgtype === 'm.image' &&
                            file &&
                            file.url
                        ) {
                            getDecryptedImageUrl(file).finally(() => {
                                count += 1;
                                if (count >= events.length) {
                                    return resolve('');
                                }
                            });
                        } else {
                            count += 1;
                            if (count >= events.length) {
                                return resolve('');
                            }
                        }
                    });
                } else {
                    count += 1;
                    if (count >= events.length) {
                        return resolve('');
                    }
                }
            });
        });
    };

    const recurseRoomDecrypt = (count = 0) => {
        const rooms = mcli.getRooms();
        return new Promise((resolve) => {
            rooms.forEach((room) => {
                decryptRoom(room).finally(() => {
                    count += 1;
                    if (count >= rooms.length) {
                        return resolve('');
                    }
                });
            });
        });
    };
    return (
        <div className="h-screen w-screen flex justify-center items-center">
            <div className="w-1/3 flex flex-col justify-center items-center">
                {LottieView}
                <div>{t('restoring')}</div>
            </div>
        </div>
    );
};

export default Restore;
