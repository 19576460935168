import StorageModule from '../StorageModule';

export type TCategoryItem = {
    id: string;
    imageUrl: string;
    name: string;
    subscribed: boolean;
};

type TStore = {
    items: TCategoryItem[];
};

let store: TStore = {
    items: [],
};

class CategoryModule extends StorageModule<TStore> {
    constructor() {
        super({name: 'categories', store});
    }
}

export default CategoryModule;
