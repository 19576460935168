import StorageHandler from '../Storage/StorageHandler';
import { Config } from '../Config';
import HTTP from '../HTTP';
import DelegatedEventTarget from '../DelegateEventTarget';

export enum AuthEvents {
	MAPP_LOGIN_SUCCESSFULL = 'Authentication successfull',
	MAPP_LOGIN_FAILED = 'Authentication failed.',
	MAPP_LOGOUT = 'Logout.',
}

type TPayload = {
	username: string;
	code?: string | null;
	title: string;
	firstName: string;
	lastName: string;
};

class Auth extends DelegatedEventTarget {
	http = new HTTP();
	store: typeof StorageHandler = StorageHandler;
	/**
	 * Check if logged in, by request user profile
	 * @returns boolean
	 */
	isLoggedIn = async () => {
		try {
			const url = new URL('/api/web/user/profile', Config.host);
			const res = await this.http.json.httpGet(url);
			return { status: res.status, isLoggedIn: res.status !== 401 };
		} catch (e: any) {
			return { error: e };
		}
	};

	login = async (email: string, password: string) => {
		this.store.Auth.setAccessToken('');
		this.store.clear();

		const url = new URL('/api/web/login', Config.host);
		const res = await this.http.json.httpPost(
			url,
			JSON.stringify({
				email,
				password,
			})
		);
		if (!res.ok) {
			this.dispatchEvent(
				new CustomEvent(AuthEvents.MAPP_LOGIN_FAILED, { detail: res })
			);
			return res;
		}

		const result = await res.json();
		if (!Config.useCookie) {
			this.store.Auth.set(result);
		}
		this.dispatchEvent(new Event(AuthEvents.MAPP_LOGIN_SUCCESSFULL));

		return result;
	};

	logout = async () => {
		this.store.Auth.setAccessToken('');
		this.store.clear();

		const url = new URL('/api/web/logout', Config.host);
		const res = await this.http.json.httpGet(url);
		this.dispatchEvent(new Event(AuthEvents.MAPP_LOGOUT));

		if (res.ok) {
			return Config;
		}
	};

	update = async (email: string, password: string, newPassword: string) => {
		const url = new URL('/api/web/password-update', Config.host);

		const res = await this.http.json.httpPost(
			url,
			JSON.stringify({
				email: email,
				password: password,
				newPassword: newPassword,
			})
		);

		if (!res.ok) {
			throw res.status;
		}

		return await res.json();
	};

	register = async (payload: TPayload) => {
		const url = new URL('/api/web/register', Config.host);

		const res = await this.http.json.httpPost(url, JSON.stringify(payload));

		if (!res.ok) {
			return await res.json();
		}

		return await res.json();
	};

	reset = async (email: string) => {
		const url = new URL('/api/web/password-reset', Config.host);
		if (email) {
			const res = await this.http.json.httpPut(
				url,
				JSON.stringify({ email: email })
			);

			if (!res.ok) {
				throw res.status;
			}

			return await res.json();
		}
	};

	acceptTermsOfUse = async () => {
		const url = new URL(`/api/web/terms/accept`, Config.host);
		const res = await this.http.json.httpPatch(url);

		if (!res.ok || res.status !== 204) {
			return res;
		}

		return res.ok;
	};
	acceptAgreement = async () => {
		const url = new URL(`/api/web/agreement/accept`, Config.host);
		const res = await this.http.json.httpPatch(url);

		if (!res.ok || res.status !== 204) {
			return res;
		}

		return res.ok;
	};
}
export default Auth;
