import { Config } from '../Config';
import HTTP from '../HTTP';
import { appHeaders } from '../Headers';
import StorageHandler from '../Storage/StorageHandler';
import {
	Attachment,
	PostFormDataParams,
} from '../../../mapp-web-app/src/features/forms/formsSlice';
import {
	AESEncryptionWithIv,
	generateKeyAndIv,
	getDataHash,
	getIv,
	rsaEncryption,
	encryptFile,
} from '@atino/libs/cryptography/crypto';
import { saveAs } from 'file-saver';

type TParams = {
	order?: string;
	per_page?: number;
	page?: number;
};
class Forms {
	store = StorageHandler;
	http = new HTTP();

	/**
	 * Fetch and store latest categories
	 * @returns JSON
	 */
	get = async (params?: TParams) => {
		const url = new URL('/api/web/forms', Config.host);
		let formParams = {
			perPage: params?.per_page || 10,
			page: params?.page || 1,
			// sort: params?.order || 'desc',
		};
		if (formParams) {
			// @ts-ignore
			url.search = new URLSearchParams(formParams).toString();
		}

		const res = await this.http.json.httpGet(url);

		if (params?.order) {
			// @ts-ignore
			formParams['order'] = params.order;
		}
		if (!res.ok) throw res.status;

		const jsonResult = await res.json();

		return jsonResult;
	};

	getOneById = async (id: string) => {
		const url = new URL(`/api/web/forms/${id}`, Config.host);
		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		const jsonResult = await res.json();

		return jsonResult;
	};

	search = async (term: string) => {
		const url = new URL('/api/web/forms', Config.host);

		if (term) {
			// @ts-ignore
			url.search = new URLSearchParams({ searchTerm: term });
		}
		const res = await this.http.json.httpGet(url);

		if (!res.ok) throw res.status;

		const jsonResult = await res.json();

		return jsonResult.items;
	};

	submitForm = async (
		dataParams: PostFormDataParams,
		attachments: Attachment[] | undefined,
		headers?: Record<string, string>
	) => {
		const url = new URL(`/api/web/forms`, Config.host);

		const _headers = {
			...appHeaders(),
			...headers,
		};
		// console.log("formData: ", dataParams.data)
		try {
			const data = JSON.stringify(dataParams.data);

			const formData = new FormData();

			formData.append('form', dataParams.form);
			formData.append('data', data);
			dataParams.fields?.forEach((field, i) => {
				formData.append(`fields[${i}]`, field);
			});
			attachments?.forEach((attachment, i) => {
				formData.append(`attachments[${i}]`, attachment.attachment, attachment.attachmentName);
				formData.append(
					`attachmentTypes[${i}]`,
					attachment.attachmentType
				);
				formData.append(
					`attachmentHashes[${i}]`,
					attachment.attachmentHash
				);
				formData.append(
					`attachmentNames[${i}]`,
					attachment.attachmentName
				);
			});
			if(dataParams.email) {
				formData.append('copyTo', dataParams.email);
			}
			if(dataParams.externalName) {
				formData.append('externalPersonName', dataParams.externalName);
			}

			// console.log("POST FormData: ", formData)
			if (data.length > 2 || formData.get('attachments[0]') !== null) {
				const res = await this.http.json.httpPost(
					url,
					formData,
					_headers,
					true
				);

				if (!res.ok) throw res.status;
				return res;
			}
			throw new Error('Error processing the request');
		} catch (err) {
			throw err;
		}
	};

	submitEncryptedForm = async (
		dataParams: PostFormDataParams,
		attachments: Attachment[] | undefined,
		publicKey: string,
		headers?: Record<string, string>
	) => {
		const url = new URL(`/api/web/forms/encrypted`, Config.host);

		const _headers = {
			...appHeaders(),
			...headers,
		};

		try {
			const data = JSON.stringify(dataParams.data);
			let rsaEncryptedIv = '';
			let rsaEncryptedKey = '';
			let encryptedData: string | undefined = undefined;

			const fileKeyAndIv = await generateKeyAndIv();

			if (fileKeyAndIv) {
				rsaEncryptedIv = rsaEncryption(
					fileKeyAndIv.fileIV,
					publicKey
				) as string;
				rsaEncryptedKey = rsaEncryption(
					fileKeyAndIv.fileKey,
					publicKey
				) as string;

				encryptedData = await AESEncryptionWithIv(data, fileKeyAndIv);
				const dataHash = getDataHash(data);

				const formData = new FormData();

				formData.append('form', dataParams.form);
				formData.append('fileIv', rsaEncryptedIv);
				formData.append('fileKey', rsaEncryptedKey);
				formData.append('encryptedData', encryptedData ?? '');
				formData.append('dataHash', dataHash);
				dataParams.fields?.forEach((field, i) => {
					formData.append(`fields[${i}]`, field);
				});

				if (attachments) {
					for (const [i, attachment] of attachments.entries()) {
						// console.log("attachmentHash_" + attachment.attachmentName + ": ", attachment.attachmentHash)
						const encryptedAttachment =
							await encryptFile(
								attachment.attachment,
								fileKeyAndIv.fileKey,
								fileKeyAndIv.fileIV
							);
							// saveAs(decryption, "decrypted.png")
						// const decryption = await AESDecryption(encryptedData ?? '', rsaEncryptedKey, rsaEncryptedIv, encryptedAttachment)
						// saveAs(decryption, "decrypted.png")
						formData.append(
							`attachments[${i}]`,
							encryptedAttachment,
							attachment.attachmentName
						);
						formData.append(
							`attachmentTypes[${i}]`,
							attachment.attachmentType
						);
						formData.append(
							`attachmentHashes[${i}]`,
							attachment.attachmentHash
						);
						formData.append(
							`attachmentNames[${i}]`,
							attachment.attachmentName
						);
					}
				}
				if(dataParams.externalName) {
					const externalName = JSON.stringify(dataParams.externalName)
					const encryptedExternalName = await AESEncryptionWithIv(externalName, fileKeyAndIv);
					formData.append('externalPersonName', encryptedExternalName ?? '');
				}
				if(dataParams.email) {
					formData.append('copyTo', dataParams.email);
				}
				

				// console.log("Encrypted POST FormData: ", formData)
				if (encryptedData || formData.get('attachments[0]') !== null) {
					const res = await this.http.json.httpPost(
						url,
						formData,
						_headers,
						true
					);

					if (!res.ok) throw res.status;
					return res;
				}
				throw new Error('Error processing the request');
			}
		} catch (err) {
			throw err;
		}
	};
}

export default Forms;
