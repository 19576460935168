import encrypt from 'matrix-encrypt-attachment';
import { IContent, ISendEventResponse } from 'matrix-js-sdk';
import { readFileAsArrayBuffer, uploadFile, uploadFileEncrypted } from './file';
import store from './store';
import { TEvent } from '../../reducer/matrix';

const MAX_WIDTH = 800;
const MAX_HEIGHT = 600;

type TImage = {
	url: string;
	objUrl: string;
};
let imageStore: TImage[] = [];

type ThumbnailableElement = HTMLImageElement | HTMLVideoElement;

export type TFile = {
	key: encrypt.IEncryptedFileJWK;
	iv: string;
	v?: string | undefined;
	hashes?:
		| {
				sha256?: string | undefined;
		  }
		| undefined;
	url: string;
	info: TFileInfo;
};

export type TFileInfo = {
	h?: number;
	w?: number;
	mimetype: string;
	size: number;
	thumbnail_file?: IThumbnail;
};

export const BLURHASH_FIELD = 'xyz.amorgan.blurhash'; // MSC2448
export interface IThumbnail {
	// eslint-disable-next-line camelcase
	info: {
		// eslint-disable-next-line camelcase
		thumbnail_info: {
			w: number;
			h: number;
			mimetype: string;
			size: number;
		};
		w: number;
		h: number;
		// [BLURHASH_FIELD]: string;
	};
	thumbnail: Blob;
}

export const sendMessage = async (
	roomId: string,
	msg: string | IContent,
	type: string
): Promise<void> => {
	let content = msg;
	if (typeof msg === 'string') content = { body: msg, msgtype: type };

	// @ts-ignore
	await store.client.sendMessage(roomId, content);
};

/**
 * Sends media encrypted or unencrypted for given room
 * @param roomId
 * @param type
 * @param file
 * @returns {Promise<MatrixSDK.ISendEventResponse>}
 */
export const sendMedia = async (
	roomId: string,
	type: string,
	file: File | Blob,
	fileName?: string,
	videoInfo?: any
): Promise<ISendEventResponse> => {
	const client = store.client;
	let fileContent;
	let url;
	// @ts-ignore
	const { h, w }: { h: number; w: number } =
		type === 'm.image' &&
		(await new Promise((resolve) => {
			const fr = new FileReader();
			fr.onload = () => {
				const img = new Image();
				img.onload = () => {
					resolve({ h: img.height, w: img.width });
				};
				// @ts-ignore
				img.src = fr.result;
			};
			fr.readAsDataURL(file);
		}));
	if (client.isRoomEncrypted(roomId)) {
		const buffer = await readFileAsArrayBuffer(file);
		const encryptResult = await encrypt.encryptAttachment(buffer);
		const blob = new Blob([encryptResult.data]);
		const uploadRes = videoInfo
			? { content_uri: videoInfo.thumbnail_url }
			: await client.uploadContent(blob, {
					includeFilename: false,
					type: 'aplication/octet-stream',
			  });

		url = uploadRes.content_uri;

		fileContent = encryptResult.info;
	} else {
		const uploadRes = videoInfo
			? { content_uri: videoInfo.thumbnail_url }
			: await client.uploadContent(file, { type: file.type });
		url = uploadRes.content_uri;
	}
	let body =
		fileName !== undefined
			? fileName
			: // @ts-ignore
			file?.name
			? // @ts-ignore
			  file.name.trim()
			: 'blob';
	body = body.trim().replace(/ /g, '');
	const content = {
		msgtype: type,
		body,
		info: {
			mimetype: file.type,
			size: file.size,
			h,
			w,
		},
		url,
	};
	if (videoInfo?.thumbnail_info && videoInfo?.thumbnail_file) {
		Object.assign(content.info, { ...videoInfo });
	}
	if (fileContent) Object.assign(content, { file: { ...fileContent, url } });

	// @ts-ignore
	return client.sendEvent(roomId, 'm.room.message', content, '', (err) => {
		throw err;
	});
};

export const sendVideo = async (
	roomId: string,
	file: File,
	autoplay = false,
	encrypted = false
) => {
	const client = store.client;
	const thumbnail = await loadVideoElement(file).then((video) => {
		return createThumbnail(
			video,
			video.videoWidth,
			video.videoHeight,
			'image/jpeg'
		);
	});
	const thumbnailFile = encrypted
		? await uploadFileEncrypted(thumbnail.thumbnail)
		: await uploadFile(thumbnail.thumbnail);

	const info = {
		mimetype: file.type,
		size: file.size,
		thumbnail_file: thumbnailFile.file,
		...thumbnail.info,
		thumbnail_url: thumbnailFile.file.url,
	};
	// @ts-ignore
	if (autoplay) info.autoplay = true;

	const remoteFile = encrypted
		? await uploadFileEncrypted(file)
		: await uploadFile(file);

	if (remoteFile) {
		const content = {
			msgtype: 'm.video',
			body: file.name,
			info,
			url: remoteFile.file.url,
		};
		if (encrypted) {
			// @ts-ignore
			content.file = remoteFile.file;
		}

		return client.sendEvent(
			roomId,
			'm.room.message',
			// @ts-ignore
			content,
			'',
			(err) => {
				throw err;
			}
		);
	}
};

const loadVideoElement = (videoFile: File): Promise<HTMLVideoElement> => {
	return new Promise((resolve, reject) => {
		// Load the file into an html element
		const video = document.createElement('video');
		video.preload = 'metadata';
		video.playsInline = true;
		video.muted = true;

		const reader = new FileReader();

		reader.onload = (ev) => {
			// Wait until we have enough data to thumbnail the first frame.
			video.onloadeddata = async () => {
				resolve(video);
				video.pause();
			};
			video.onerror = reject;

			let dataUrl = ev.target?.result as string;
			// Chrome chokes on quicktime but likes mp4, and `file.type` is
			// read only, so do this horrible hack to unbreak quicktime
			if (dataUrl.startsWith('data:video/quicktime;')) {
				dataUrl = dataUrl.replace(
					'data:video/quicktime;',
					'data:video/mp4;'
				);
			}

			video.src = dataUrl;
			video.load();
			video.play();
		};
		reader.onerror = reject
		reader.readAsDataURL(videoFile);
	});
};

const createThumbnail = async (
	element: ThumbnailableElement,
	inputWidth: number,
	inputHeight: number,
	mimeType: string,
	calculateBlurhash = true
): Promise<IThumbnail> => {
	let targetWidth = inputWidth;
	let targetHeight = inputHeight;
	if (targetHeight > MAX_HEIGHT) {
		targetWidth = Math.floor(targetWidth * (MAX_HEIGHT / targetHeight));
		targetHeight = MAX_HEIGHT;
	}
	if (targetWidth > MAX_WIDTH) {
		targetHeight = Math.floor(targetHeight * (MAX_WIDTH / targetWidth));
		targetWidth = MAX_WIDTH;
	}

	// @ts-ignore
	let canvas: HTMLCanvasElement | OffscreenCanvas;
	let context:
		| CanvasRenderingContext2D
		// @ts-ignore
		| OffscreenCanvasRenderingContext2D
		| null;
	try {
		// @ts-ignore
		canvas = new window.OffscreenCanvas(targetWidth, targetHeight);
		// @ts-ignore
		context = canvas.getContext('2d');
	} catch (e) {
		// Fallback support for other browsers (Safari and Firefox for now)
		canvas = document.createElement('canvas');
		// @ts-ignore
		canvas.width = targetWidth;
		// @ts-ignore
		canvas.height = targetHeight;
		// @ts-ignore
		context = canvas.getContext('2d');
	}

	// @ts-ignore
	context?.drawImage(element, 0, 0, targetWidth, targetHeight);

	let thumbnailPromise: Promise<Blob>;

	// @ts-ignore
	if (window.OffscreenCanvas) {
		// @ts-ignore
		thumbnailPromise = (canvas as OffscreenCanvas).convertToBlob({
			type: mimeType,
		});
	} else {
		// @ts-ignore
		thumbnailPromise = new Promise<Blob>((resolve: any) =>
			(canvas as HTMLCanvasElement).toBlob(resolve, mimeType)
		);
	}

	const imageData = context?.getImageData(0, 0, targetWidth, targetHeight);
	// thumbnailPromise and blurhash promise are being awaited concurrently
	// const blurhash = calculateBlurhash ? await BlurhashEncoder.instance.getBlurhash(imageData) : undefined;
	const thumbnail = await thumbnailPromise;

	return {
		info: {
			thumbnail_info: {
				w: targetWidth,
				h: targetHeight,
				mimetype: thumbnail.type,
				size: thumbnail.size,
			},
			w: inputWidth,
			h: inputHeight,
			// [BLURHASH_FIELD]: blurhash,
		},
		thumbnail,
	};
};

export const getDecryptedImageUrl = async (file: TFile) => {
	const client = store.client;
	const accessToken = client.getAccessToken();
	const found = imageStore.find((f) => f.url === file.url);
	if (found) {
		return found.objUrl;
	}
	const res = await fetch(
		store.client.mxcUrlToHttp(file.url) + '?access_token' + accessToken
	);
	if (res.ok) {
		const ab = await res.arrayBuffer();
		const buffer = await encrypt.decryptAttachment(ab, file);
		const objUrl = URL.createObjectURL(new Blob([buffer]));
		imageStore = [...imageStore, { url: file.url, objUrl }];
		return objUrl;
	}
};

export const normalizeText = (message: string, hasReply: boolean) => {
	let quote = '';
	let sender = '';
	if (message.substring(0, 3).includes('> <') && hasReply) {
		sender = message.substring(3, 73);
		quote = message.substring(74, message.length - 1).split('\n')[0];
		message = message.split('\n')[2];
	}
	return { normalized: message, quote, sender };
};

export const getDecryptedVoiceMessage = async (file: TFile) => {
	const client = store.client;
	const accessToken = client.getAccessToken();

	const res = await fetch(
		store.client.mxcUrlToHttp(file.url) + '?access_token' + accessToken
	);

	if (res.ok) {
		const ab = await res.arrayBuffer();
		const buffer = await encrypt.decryptAttachment(ab, file);
		const objUrl = URL.createObjectURL(new Blob([buffer]));
		return objUrl;
	}
};

const urlify = (text: string) => {
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	return text.replace(urlRegex, (url) => {
		return (
			'<a href="' +
			url +
			'" rel="noopener" target="_blank">' +
			url +
			'</a>'
		);
	});
};

export const parseMessage = (normalized: string, hasReply = false) => {
	const html = normalized;
	let body = normalized;
	const bodyUrl = normalized?.includes('https://');
	if (
		hasReply &&
		typeof normalized === 'string' &&
		normalized.substring(0, 4).includes('> <@')
	) {
		body = normalized.substring(74);
		const found = body.indexOf('\n\n');
		body = body.substring(found + 2);
	}

	return { html: bodyUrl && urlify(html), body };
};

export const getLastMessage = (evts: TEvent[]) => {
	if (!Array.isArray(evts)) return;

	for (let i = evts.length - 1; i >= 0; i--) {
		if (evts[i].type === 'm.room.message') {
			return evts[i];
		}
	}
};

// @ts-nocheck
import { MatrixClient, Room, MatrixEvent } from 'matrix-js-sdk';
import sanitizeHtml from 'sanitize-html';
import escapeHtml from '../../../../libs/escapeHtml';

const ALLOWED_TAGS = [
	'font',
	'del',
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'p',
	'ul',
	'ol',
	'sup',
	'sub',
	'blockquote',
	'li',
	'b',
	'i',
	'u',
	'strong',
	'em',
	'strike',
	'code',
	'hr',
	'br',
	'div',
	'table',
	'thead',
	'tbody',
	'tr',
	'th',
	'td',
	'caption',
	'pre',
	'span',
	'img',
	'details',
	'summary',
];

const PERMITTED_URL_SCHEMES = [
	'ftp',
	'geo',
	'http',
	'https',
	'im',
	'irc',
	'ircs',
	'mailto',
	'news',
	'nntp',
	'openpgp4fpr',
	'sip',
	'sftp',
	'ssh',
	'tel',
	'urn',
	'webcal',
];

function cleanHtml(body: string) {
	return sanitizeHtml(body, {
		allowedTags: ALLOWED_TAGS, // false means allow everything
		allowedAttributes: false,
		// we somehow can't allow all schemes, so we allow all that we
		// know of and mxc (for img tags)
		allowedSchemes: [...PERMITTED_URL_SCHEMES, 'mxc'],
		exclusiveFilter: (frame: any) => frame.tag === 'mx-reply',
	});
}

export const textMessage = async (
	client: MatrixClient,
	content: any,
	roomId: string
) => {
	const room: Room | null = client.getRoom(roomId);
	if (!room)
		return {
			message: 'ERROR_ROOM_NOT_FOUND',
		};
	if (!content?.body) return { message: '' };

	const sanatizedHtml = cleanHtml(content?.formatted_body || content?.body);
	const msg = sanatizedHtml.replace(
		/<blockquote(.*?)<\/blockquote>|((.|\n)*)<\/blockquote>/g,
		''
	);

	if (content.formatted_body && content.replyTo) {
		const replyEvent = await client.fetchRoomEvent(roomId, content.replyTo);

		const mvent = new MatrixEvent(replyEvent);
		await client.decryptEventIfNeeded(mvent);
		const replyContent = mvent?.getContent();
		const userString = content.body.substring(3, 73);
		const sender = client.getUser(userString);

		if (!sender)
			return {
				message: 'ERROR_SENDER_NOT_FOUND',
			};
		const reply = content.formatted_body
			.match(/<br(.*?)<\/blockquote>|\n(.*?)<\/blockquote>/g)
			?.map((val: any) => {
				const newString = val.replace(/<\/?br>/g, '');
				const replaceStartTag = newString.replace(/<\/?br \/>/g, '');
				const newLineReplace = replaceStartTag.replace(/\n/g, '');
				return newLineReplace.replace(/<\/?blockquote>/g, '');
			});
		if (
			replyContent?.msgtype === 'm.video' ||
			replyContent?.msgtype === 'm.image'
		) {
			return {
				name: sender.displayName,
				message: msg,
				replyMedia: replyContent,
				roomId,
				isVideo: replyContent.msgtype === 'm.video',
			};
		}

		if (
			replyContent?.msgtype === 'm.text' ||
			replyContent?.msgtype === 'm.file'
		) {
			return {
				name: sender.displayName,
				message: msg,
				replyMessage: reply,
			};
		}
	} else {
		let _sendBody = escapeHtml(content.body);
		const bodyUrl = _sendBody?.includes('https://');

		if (!_sendBody?.length || !content?.body?.length || !bodyUrl) {
			const found = _sendBody.indexOf('\n\n');
			if (found) {
				_sendBody = _sendBody.substring(found);
			}
			return { messagePlain: _sendBody };
		}

		return {
			messageHtml: bodyUrl ? urlify(_sendBody) : _sendBody,
		};
	}
};
