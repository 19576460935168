import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import mcli from '../../libs/matrix';
import { getLastMessage } from '../../libs/matrix/message';
import {
	TRoom,
	getRoomAsync,
	selectRooms,
	updateSelectedRoom,
} from '../../reducer/matrix';
import MRoom from '../Matrix/MRoom';
import ListItem from './ListItem';

type TProps = {
	selectedRoomId: string;
	searchPattern?: string;
};

const orderLatest = (a: TRoom, b: TRoom) => {
	const ea = a.events;
	const eb = b.events;
	if (ea.length === 0) return -1;
	if (eb.length === 0) return 0;

	let lastA = getLastMessage(ea);
	let lastB = getLastMessage(eb);

	if (!lastA) return 1;
	if (!lastB) return -1;

	if (lastA.timestamp > lastB.timestamp) return -1;
	return 1;
};

const ChatRoomsSidebar: React.FC<TProps> = ({
	selectedRoomId,
	searchPattern,
}) => {
	const dispatch = useAppDispatch();
	const rooms = useAppSelector(selectRooms);

	const isFavourite = (roomId: string) => {
		const tags = mcli.getRoom(roomId)?.getAccountData('m.tag');
		try {
			// mcli.getRoomTags <- would also be possible, but is async and we have already account data...
			return tags?.event?.content?.tags['m.favourite']['order'];
		} catch {
			return false;
		}
	};

	const handlePin = (roomId: string) => {
		const room = mcli.getRoom(roomId);
		if (!room) return;

		if (isFavourite(roomId)) {
			mcli.deleteRoomTag(roomId, 'm.favourite');
			return;
		}
		mcli.setRoomTag(roomId, 'm.favourite', { order: 1 });
	};

	const searchedRooms = rooms.filter((r) =>
		r.name.toLowerCase().includes(searchPattern?.toLowerCase() || '')
	);

	const favourites = searchedRooms
		.filter((r) => isFavourite(r.id))
		.sort(orderLatest);
	const lowprio = searchedRooms
		.filter((r) => !isFavourite(r.id))
		.sort(orderLatest);
	return (
		<div className={'Sidebar scrollbar'}>
			<ul className="sidebar-entries border-b-2">
				{favourites.map((room: TRoom) => (
					<ListItem
						key={room.id}
						roomId={room.id}
						onSelect={() => {
							dispatch(updateSelectedRoom(room.id));
							dispatch(getRoomAsync(room.id));
						}}
						isSelected={selectedRoomId === room.id}
						onPin={handlePin}
						isPinned={true}
					/>
				))}
			</ul>
			<ul className="sidebar-entries">
				{lowprio.map((room: TRoom) => (
					<ListItem
						key={room.id}
						roomId={room.id}
						onSelect={() => {
							dispatch(updateSelectedRoom(room.id));
							dispatch(getRoomAsync(room.id));
						}}
						isSelected={selectedRoomId === room.id}
						onPin={handlePin}
						isPinned={false}
					/>
				))}
			</ul>
		</div>
	);
};

export default ChatRoomsSidebar;
