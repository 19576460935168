import {IKeyBackupCheck} from 'matrix-js-sdk/lib/crypto/backup';
import store from './store';

const restoreBackupWithPassphrase = async (passphrase: string) => {
    const client = store.client;
    try {
        const backup: IKeyBackupCheck | null = await client.checkKeyBackup();
        if (!backup || !backup.backupInfo) throw new Error('Backup not there.');
        if (backup.trustInfo.usable) return; // when backup is already restored.
        if (
            backup.backupInfo.auth_data?.private_key_salt &&
            backup.backupInfo.auth_data?.private_key_iterations
        ) {
            await client.restoreKeyBackupWithPassword(
                passphrase,
                undefined,
                undefined,
                backup.backupInfo,
                {},
            );
        } else {
            store.phrase = passphrase;
            await restoreBackup(backup);
        }
    } catch (e) {
        throw e;
    }
};

const restoreBackup = async (backup: IKeyBackupCheck): Promise<void> => {
    const client = store.client;
    try {
        if(backup.backupInfo) {
            if (!backup.trustInfo.usable) {
                // this will also set trust usable nad local trust true.
                const recoverInfo = await client.restoreKeyBackupWithSecretStorage(
                    backup.backupInfo,
                    undefined,
                    undefined,
                );
                // eslint-disable-next-line no-console
                console.warn('Recovery Info', recoverInfo);
            }
            await client.enableKeyBackup(backup.backupInfo);
        }

    } catch (e) {
        throw e;
    }
};

const getBackup = async () => store.client.checkKeyBackup();

export {restoreBackupWithPassphrase, restoreBackup, getBackup};
