import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks';
import mcli from '../../libs/matrix';
import {getLastMessage, parseMessage} from '../../libs/matrix/message';
import {toggleChatLeaveRoom} from '../../reducer/app';
import {TMember, selectRoom} from '../../reducer/matrix';
import ChatPresence from '../ChatPresence';
import Bell from '../Icons/Bell';
import BellSlash from '../Icons/BellSlash';
import EllipsisVertical from '../Icons/EllipsisVertical';
import Pin from '../Icons/Pin';
import ShieldCheck from '../Icons/ShieldCheck';
import Trash from '../Icons/Trash';

type TProps = {
    roomId: string;
    onSelect: (roomId: string) => void;
    isSelected: boolean;
    onPin: (roomId: string) => void;
    isPinned: boolean;
};

const ListItem: React.FC<TProps> = ({
    roomId,
    onSelect,
    isSelected,
    isPinned,
    onPin,
}) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const room = useAppSelector((state) => selectRoom(state, roomId));
    const [isOpen, setIsOpen] = React.useState(false);
    const [isRoomMuted, setIsRoomMuted] = React.useState(false);
    const menuRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!room) return;

        const checkRules = setTimeout(() => {
            const pushRule = mcli.getRoomPushRule('global', roomId);
            const dontNotify = pushRule?.actions[0] === 'dont_notify';
            setIsRoomMuted(dontNotify);
        }, 100);

        document.addEventListener('mousedown', closeMenu);

        return () => {
            document.removeEventListener('mousedown', closeMenu);
            clearTimeout(checkRules);
        };
    }, [room, room?.lastEventId]);

    const closeMenu = (e: any) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    const muteRoom = (roomId: string) => {
        if (!isRoomMuted) {
            setIsRoomMuted(true);
            mcli?.setRoomMutePushRule('global', roomId, true);
        } else {
            setIsRoomMuted(false);
            mcli?.setRoomMutePushRule('global', roomId, false);
        }
    };

    const openMenu = (e: any) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    if (!room) return <li></li>;

    let avatar = room.imageUrl;
    if (!avatar && room.members.length === 2) {
        room.members.forEach((member: TMember) => {
            if (member.userId !== mcli.getUserId()) {
                avatar = member.imageUrl;
            }
        });
    }

    const lastEvent = getLastMessage(room.events);
    const mimetype = lastEvent?.content?.info?.mimetype;
    const splittedMimeType = mimetype?.split('/') || [];

    let mediaType = '';

    if (splittedMimeType.length === 2) {
        if (splittedMimeType[0] === 'image') mediaType = t('image');
        if (splittedMimeType[0] === 'video') mediaType = t('video');
        if (splittedMimeType[0] === 'audio') mediaType = t('audio');
        if (splittedMimeType[1] === 'gif') mediaType = t('gif');
    }

    const otherMember = room.members.find(
        (member: TMember) => member.userId !== mcli.getUserId(),
    );
    return (
        <>
            <li onClick={() => onSelect(roomId)}>
                <div
                    className={`${
                        isSelected && 'listitem-selected'
                    } ListItem group`}
                >
                    <div className="flex items-center">
                        <div className="mr-3">
                            {avatar ? (
                                <div className="rounded-full bg-slate-400 w-8">
                                    <img
                                        src={avatar}
                                        alt="room avatar"
                                        className="w-8 h-8"
                                    />
                                </div>
                            ) : (
                                <div className="rounded-full bg-primary-full w-8 h-8 flex items-center justify-center text-white">
                                    {room.name.substring(0, 1)}
                                </div>
                            )}
                            {room.isDirect && (
                                <ChatPresence
                                    userId={otherMember?.userId || ''}
                                    presence={otherMember?.presence || ''}
                                    isSelected={isSelected}
                                />
                            )}
                        </div>
                        <div className="flex flex-col justify-start w-full">
                            <div className="flex items-center justify-between text-[#001A34]">
                                <div className="flex">
                                    <div>{room.name || ''}</div>

                                    <div className="flex items-center">
                                        {room.isEncrypted && (
                                            <ShieldCheck className="w-4 h-4 ml-2 fill-gray-400" />
                                        )}

                                        {isRoomMuted && (
                                            <BellSlash className="w-4 h-4 ml-2 fill-gray-400" />
                                        )}
                                        {isPinned && (
                                            <Pin className="w-4 h-4 ml-2 fill-gray-400" />
                                        )}
                                    </div>
                                </div>

                                <div className="chat-dropdown relative hidden group-hover:block">
                                    <div
                                        className="chat-toggle"
                                        onClick={(e) => openMenu(e)}
                                    >
                                        <EllipsisVertical className="w-5 h-5 fill-gray-500" />
                                    </div>

                                    {isOpen && (
                                        <div
                                            ref={menuRef}
                                            className="listitem-dropdown"
                                        >
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    muteRoom(roomId);
                                                    setIsOpen(false);
                                                }}
                                            >
                                                <div className="mr-2">
                                                    {isRoomMuted ? (
                                                        <BellSlash className="w-4 h-4" />
                                                    ) : (
                                                        <Bell className="w-4 h-4" />
                                                    )}
                                                </div>
                                                {isRoomMuted
                                                    ? t('unmute')
                                                    : t('mute')}
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onPin(roomId);
                                                    setIsOpen(false);
                                                }}
                                            >
                                                <div className="mr-2">
                                                    <Pin className="w-4 h-4" />
                                                </div>
                                                {isPinned
                                                    ? t('unpin')
                                                    : t('pin')}
                                            </div>
                                            <div
                                                onClick={() => {
                                                    dispatch(
                                                        toggleChatLeaveRoom({
                                                            roomId: room.id,
                                                        }),
                                                    );
                                                    setIsOpen(false);
                                                }}
                                                data-type={'modal-btn'}
                                            >
                                                <div className="mr-2">
                                                    <Trash className="w-4 h-4" />
                                                </div>
                                                {t('leave')}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`text-sm truncate w-80 ${
                                    lastEvent && lastEvent?.readState < 1
                                        ? 'font-semibold italic'
                                        : 'font-light text-slate-500'
                                }`}
                            >
                                {room.userTyping && room.userTyping.length > 0
                                    ? `${room.userTyping[0].name} schreibt...`
                                    : mediaType
                                    ? mediaType
                                    : parseMessage(
                                          lastEvent?.content?.body,
                                          !!lastEvent?.replyTo,
                                      ).body}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </>
    );
};

export default ListItem;
