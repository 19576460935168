export const downloadFile = (url: string, name: string) => {
    if(url.search("blob:") === -1) {
        fetch(url).then(res => {
            res.blob().then((blob) => download(URL.createObjectURL(blob), name));
        });

    } else {
        download(url, name);

    }
};

const download = (blobUrl: string, name: string) => {
    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.download = name;
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
}
