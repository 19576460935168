import * as React from 'react';
import mcli from '../../libs/matrix';
import {IContent} from 'matrix-js-sdk';
import {TFile, getDecryptedImageUrl} from '../../libs/matrix/message';
import {downloadFile} from '@atino/libs/common/download';
type TImage = {
    url: string;
    objUrl: string;
};
const imageStore: TImage[] = [];

type TInfo = {
    thumbnail_file?: TFile;
    thumbnail_url?: string;
};

type TProps = {
    content: IContent;
    callback: () => void;
};

const Video: React.FC<TProps> = React.memo(({content, callback}) => {
    const isDecrypted = 'file' in content;
    const fileUrl = isDecrypted ? content.file.url : content.url;
    const thumbUrl = isDecrypted
        ? content.info.thumbnail_file?.url
        : content.info.thumbnail_url;

    const foundThumbnail = imageStore.find((i) => i.url === thumbUrl);
    const foundVideo = imageStore.find((i) => i.url === fileUrl);

    const [image, setImage] = React.useState<string | undefined | null>(
        !isDecrypted ? mcli.mxcUrlToHttp(thumbUrl) : foundThumbnail?.objUrl,
    );
    const [video, setVideo] = React.useState<string | undefined | null>(
        !isDecrypted ? mcli.mxcUrlToHttp(fileUrl) : foundVideo?.objUrl,
    );

    const [clicked, setClicked] = React.useState(false);

    const [ready, setReady] = React.useState(false);
    const [readyVideo, setReadyVideo] = React.useState(false);

    React.useEffect(() => {
        if (content.info?.autoplay) {
            downloadVideo();
        } else if (isDecrypted && content.info?.thumbnail_file) {
            getDecryptedImageUrl(content.info.thumbnail_file).then((resUrl) => {
                if (resUrl) {
                    setImage(resUrl);
                    if (thumbUrl)
                        imageStore.push({
                            url: thumbUrl,
                            objUrl: resUrl || '',
                        });
                }
            });
        }
    }, []);

    const downloadVideo = () => {
        if (!foundVideo && !video && isDecrypted) {
            getDecryptedImageUrl(content.file)
                .then((resUrl) => {
                    if (resUrl) {
                        setVideo(resUrl);
                        imageStore.push({
                            url: fileUrl,
                            objUrl: resUrl || '',
                        });
                    }
                })
                .catch(console.error);
        }
    };

    const calcHeight = (
        originWidth: number,
        width: number,
        originHeight: number,
    ) => {
        let factor = 1;

        if (originWidth < width) {
            factor = originWidth / width;
        }
        if (originWidth > width) {
            factor = width / originWidth;
        }
        return originHeight * factor;
    };

    const rawH = content.info.thumbnail_info?.h;
    const rawW = content.info.thumbnail_info?.w;

    const width = rawW ? (rawW <= 320 ? rawW : 320) : 320;
    const height = calcHeight(rawW || 250, width, rawH || 300);

    return (
        <>
            <div className="h-fill w-fill">
                {image && !clicked && !video && (
                    <>
                        {!ready && (
                            <div
                                className={`max-w-full image-skeleton`}
                                style={{width, height}}
                            ></div>
                        )}
                        {!video && (
                            <img
                                onLoad={() => {
                                    setReady(true);
                                }}
                                src={image}
                                onClick={() => {
                                    downloadVideo();
                                    setClicked(true);
                                }}
                                className={`rounded ${!ready && 'hidden'}`}
                                data-type="modal-btn"
                            />
                        )}
                    </>
                )}
                {video && (
                    <video
                        style={{width, height}}
                        loop={content.info?.autoplay}
                        preload="metadata"
                        controls
                        poster={image || ''}
                    >
                        <source src={video} />
                    </video>
                )}
                <a
                    className="text-xs text-gray-500 cursor-pointer"
                    onClick={() =>
                        downloadFile(image || '', content?.body || 'file.mp4')
                    }
                >
                    download
                </a>
            </div>
        </>
    );
});

export default Video;
