import {IEventRelation, MatrixEvent, MatrixEventEvent} from 'matrix-js-sdk';
import moment from 'moment';
import * as React from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import mcli from '../../libs/matrix';
import {createTEvent} from '../../libs/matrix/event';
import {openChatMessageDeleteModal} from '../../reducer/app';
import {
    selectEvent,
    selectMatrixEvent,
    updateEvent,
} from '../../reducer/matrix';
import Switch from './Switch';
import CircleInfo from '../Icons/CircleInfo';
import {useTranslation} from 'react-i18next';
import {Tooltip} from 'react-tooltip';

const EVENTS = [
    MatrixEventEvent.LocalEventIdReplaced,
    MatrixEventEvent.RelationsCreated,
    MatrixEventEvent.Replaced,
    MatrixEventEvent.Status,
    MatrixEventEvent.VisibilityChange,
];

export type TReplyFun = (
    content: {},
    sender: string,
    roomId: string,
    eventType: string,
    eventId: string,
    threadRootId: string | undefined,
    relation: IEventRelation | null,
    replyEvent: any,
) => unknown;

type TProps = {
    id: string;
    callback: () => void;
    reply?: TReplyFun;
};

const ChatMessage: React.FC<TProps> = ({id, callback, reply}) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const storeEvent = useAppSelector((state) => selectEvent(state, id));
    const matrixEvent: MatrixEvent | undefined = useAppSelector((state) =>
        selectMatrixEvent(state, id),
    );
    const user = mcli.getUser(storeEvent?.sender || '');
    let message = storeEvent?.content?.body;
    const me = storeEvent?.sender === mcli.getUserId();
    const content = storeEvent?.content;
    const sender = storeEvent?.sender || '';
    const eventType = storeEvent?.type || '';
    const roomId = storeEvent?.roomId || '';
    const eventId = storeEvent?.id || '';

    React.useEffect(() => {
        if (!matrixEvent || !storeEvent) return;
        matrixEvent?.setMaxListeners(255);

        EVENTS.forEach((evt) => {
            matrixEvent.on(evt, (mevent: MatrixEvent) => {
                dispatch(updateEvent({event: createTEvent(mevent)}));
            });
        });
        matrixEvent.on(
            MatrixEventEvent.BeforeRedaction,
            (mevent: MatrixEvent) => {
                dispatch(updateEvent({event: createTEvent(mevent, true)}));
            },
        );

        matrixEvent.on(MatrixEventEvent.Decrypted, (mevent: MatrixEvent) => {
            dispatch(updateEvent({event: createTEvent(mevent)}));
        });

        if(matrixEvent.isDecryptionFailure() && mcli.crypto) {
            matrixEvent.cancelAndResendKeyRequest(mcli.crypto, matrixEvent.getSender() || '')
        }

        return () => {
            EVENTS.forEach((evt) => {
                matrixEvent.removeListener(evt, (mevent: MatrixEvent) => {
                    dispatch(updateEvent({event: createTEvent(mevent)}));
                });
            });

            matrixEvent.removeListener(
                MatrixEventEvent.BeforeRedaction,
                (mevent: MatrixEvent) => {
                    dispatch(updateEvent({event: createTEvent(mevent, true)}));
                },
            );
            matrixEvent.removeListener(
                MatrixEventEvent.Decrypted,
                (mevent: MatrixEvent) => {
                    dispatch(updateEvent({event: createTEvent(mevent)}));
                },
            );
        };
    }, []);

    if (!storeEvent) return <></>;
    if (
        (content?.avatar_url && content?.membership === 'join') ||
        content?.name
    )
        return <></>;

    if (!message) {
        if (content?.membership === 'join') {
            message = `${
                content.displayname ? content.displayname : t('deleted_user')
            }${t('chat_join')}`;
        }
        if (content?.membership === 'leave') {
            message = `${
                content.displayname ? content.displayname : t('deleted_user')
            }${t('chat_leave')}`;
        }
        if (content?.membership === 'ban') {
            message = `${
                content.displayname ? content.displayname : t('deleted_user')
            }${t('chat_ban')}`;
        }
        if (content?.membership === 'invite') {
            message = `${
                content.displayname ? content.displayname : t('deleted_user')
            }${t('chat_invite')}`;
        }
    }

    if (storeEvent?.isStatusEvent) {
        return (
            <div className="flex justify-center items-center w-full my-2">
                <div className="border h-[1px] w-[28%]"></div>
                <div
                    className={`flex justify-center text-sm text-gray-800 bg-gray-200 rounded-md p-3 min-w-fit max-w-fit mx-2`}
                >
                    {message}
                </div>
                <div className="border h-[1px] w-[28%]"></div>
            </div>
        );
    }
    if (storeEvent?.isRedacted) {
        return (
            <div>
                <div
                    className={`flex ${
                        me ? 'justify-end' : 'justify-start'
                    } text-sm text-gray-400`}
                >
                    {t('deleted_message')}
                </div>
            </div>
        );
    }

    const storeEventTime = moment(new Date(storeEvent?.timestamp || 0));

    return (
        <div id={storeEvent.id}>
            <div className={`group flex items-end ${me && 'justify-end'} `}>
                <div
                    className={`flex flex-col space-y-2 text-xs max-w-xs mx-2 ${
                        me ? 'order-1 items-end' : 'order-2 items-start'
                    }`}
                >
                    <div className={`flex flex-col flex-nowrap`}>
                        <div className="flex justify-between items-center text-[#6C7A98]">
                            <span className="py-1 flex items-center">
                                {user?.displayName?.includes('@') ? (
                                    <span>{t('deleted_user')}</span>
                                ) : (
                                    !me && <span>{user?.displayName}</span>
                                )}

                                <span className={`${!me && 'mx-1'} text-right`}>
                                    {storeEventTime.isBefore(new Date(), 'day')
                                        ? storeEventTime.format('DD.MMM')
                                        : storeEventTime.format('HH:mm')}
                                </span>
                            </span>
                            <div className="flex items-center justify-between opacity-0 group-hover:opacity-100 ml-2">
                                {me && (
                                    <div
                                        onClick={() => {
                                            dispatch(
                                                openChatMessageDeleteModal({
                                                    roomId,
                                                    eventId: storeEvent.id,
                                                }),
                                            );
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            className="w-3 h-3 fill-gray-300 hover:fill-red-500 cursor-pointer"
                                            data-type={'modal-btn'}
                                        >
                                            <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                        </svg>
                                    </div>
                                )}

                                {reply &&
                                    content?.msgtype !== 'm.bad.encrypted' && (
                                        <div
                                            className="ml-2"
                                            onClick={() =>
                                                reply(
                                                    {...content, user},
                                                    sender,
                                                    roomId,
                                                    eventType,
                                                    eventId,
                                                    storeEvent.threadRootId,
                                                    storeEvent.relation,
                                                    storeEvent?.replyEvent,
                                                )
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                className="w-3 h-3 fill-gray-300 hover:fill-gray-400 cursor-pointer"
                                            >
                                                <path d="M205 34.8c11.5 5.1 19 16.6 19 29.2v64H336c97.2 0 176 78.8 176 176c0 113.3-81.5 163.9-100.2 174.1c-2.5 1.4-5.3 1.9-8.1 1.9c-10.9 0-19.7-8.9-19.7-19.7c0-7.5 4.3-14.4 9.8-19.5c9.4-8.8 22.2-26.4 22.2-56.7c0-53-43-96-96-96H224v64c0 12.6-7.4 24.1-19 29.2s-25 3-34.4-5.4l-160-144C3.9 225.7 0 217.1 0 208s3.9-17.7 10.6-23.8l160-144c9.4-8.5 22.9-10.6 34.4-5.4z" />
                                            </svg>
                                        </div>
                                    )}
                            </div>
                            {content?.msgtype === 'm.bad.encrypted' && (
                                <div className="h-4 w-8">
                                    <CircleInfo className="w-4 h-4 cursor-pointer fill-gray-400 message-encrypt" />
                                    <Tooltip
                                        anchorSelect=".message-encrypt"
                                        place="top"
                                        className="message-encrypt-tooltip"
                                    >
                                        {t('missing_key')}
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                        {content && (
                            <Switch
                                content={content}
                                message={message}
                                me={me}
                                roomId={roomId}
                                replyTo={storeEvent.replyTo}
                                callback={callback}
                            />
                        )}
                        <div className="flex flex-row flex-nowrap">
                            {me ? (
                                storeEvent.isSending ? (
                                    <div className="loaderdots mr-4" />
                                ) : (
                                    <span className="mr-4">
                                        &#10003;{' '}
                                        {storeEvent.readState > 1 && (
                                            <span>&#10003;</span>
                                        )}
                                    </span>
                                )
                            ) : (
                                <></>
                            )}

                            {process.env.NODE_ENV === 'development' && (
                                <span
                                    className="text-slate-400 text-right"
                                    onClick={() =>
                                        console.debug('DEBUG', {
                                            eventId,
                                            msgType: eventType,
                                            content,
                                        })
                                    }
                                >
                                    details
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatMessage;
