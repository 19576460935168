import {  createSlice } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store';
import mcli from '../../libs/matrix';

export interface UserState {
  authorization: any;
  name: string;
  imageUrl: string;
}

const initialState: UserState = {
  authorization: {},
  name: '',
  imageUrl: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateMatrixProfile: (state) => {
      const me = mcli.getUser(mcli.getUserId() || "");
      if(me) {
        return {
          name: me.displayName || "",
          imageUrl: mcli.mxcUrlToHttp(me.avatarUrl || "")|| "",
          authorization: {
            token: mcli.getAccessToken()
          }
        }
      }
    },
    clear: () => {
      return {
        authorization: {},
        name: '',
        imageUrl: ''
      }
    },
  },
});

export const { updateMatrixProfile, clear } = userSlice.actions;

export const selectAuthorization = (state: RootState) => state.user.authorization;
export const selectName = (state: RootState) => state.user.name;
export const selectImageUrl = (state: RootState) => state.user.imageUrl;

export default userSlice.reducer;