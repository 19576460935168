import * as React from "react";
import mcli from "../../libs/matrix";
import { useAppDispatch } from "../../hooks";
import { getBackup } from "../../libs/matrix/backup";
import { updateRestored, updateState } from "../../reducer/app";

const PreparingChat: React.FC = () => {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        getBackup().then(bk => {
            if(bk && bk.backupInfo && bk.trustInfo.trusted_locally) {
                    mcli.restoreKeyBackupWithSecretStorage(bk.backupInfo).finally(() => {
                        dispatch(updateState("ready"));
                        dispatch(updateRestored(true));
                        if(bk?.backupInfo)
                            mcli.enableKeyBackup(bk.backupInfo)
                    });
                    
            } else {
                dispatch(updateState("no_restore"));
            }

        })
    },[])
    
    return <></>
}

export default PreparingChat;