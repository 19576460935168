import * as React from 'react';
import TenorFetch, { Gif } from 'tenor-fetch';

type TProps = {
	onClick: (url: string) => void;
};
const GifPicker: React.FC<TProps> = ({ onClick }) => {
	const apiKey = process.env.TENOR_API_KEY || '';

	const tf = new TenorFetch(apiKey);

	const [previewId, setPreviewId] = React.useState<string | null>(null);
	const [list, setList] = React.useState<any[]>([]);

	React.useEffect(() => {
		tf.trending().then((data: any) => {
			setList(data.results);
		});
	}, []);

	const search = (term: string) => {
		tf.search(term).then((data) => {
			setList(data.results);
		});
	};

	if (!list) return <></>;
	return (
		<div className="flex flex-col flex-nowrap overflow-y-auto scrollbar h-full p-1">
			<div className="flex flex-row w-full border-t bg-gray-150 dark:bg-neutral-800 rounded-sm p-3">
				<input
					type="text"
					className="w-full p-2"
					onChange={(e) => search(e.target.value)}
					placeholder="powered by tenor..."
				/>
			</div>
			<div className="scrollbar overflow-y-auto grid grid-cols-6 p-1">
				{list.length > 0 &&
					list.map((gif) => {
						return (
							<div
								key={gif.id}
								onMouseEnter={() => {
									setPreviewId(gif.id);
								}}
								onMouseLeave={() => setPreviewId(null)}
								className="flex items-center justify-center border border-transparent hover:border-primary-full rounded p-1">
								<img
									className="cursor-pointer h-[120px]"
									onClick={() =>
										onClick(gif.media[0].tinygif.url)
									}
									src={
										previewId === gif.id
											? gif.media[0].gif.url
											: gif.media[0].gif.preview
									}
								/>
							</div>
						);
					})}
			</div>
		</div>
	);
};
export default GifPicker;
