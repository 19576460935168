import * as React from 'react';
import * as unicodeEmoji from 'unicode-emoji';

type TProps = {
    onClick: (emoji: string) => void;
};
const EmojiPicker: React.FC<TProps> = ({onClick}) => {
    const groupBy = 'category';
    const omitWhere: any = {version: ['12.1', '13.0', '13.1', '14.0', '15.0']};
    const emojis: Record<unicodeEmoji.GroupedBy, unicodeEmoji.BaseEmoji[]> =
        unicodeEmoji.getEmojisGroupedBy(groupBy, omitWhere);

    const allEmoj = unicodeEmoji.getEmojis(omitWhere);
    const groups = Object.keys(emojis) as unicodeEmoji.GroupedBy[];
    const searchIcon = allEmoj.find(
        (emoji) => emoji.description === 'magnifying glass tilted left',
    );

    const [selectedGroup, setSelectedGroup] =
        React.useState<unicodeEmoji.GroupedBy | null>(groups[0]);
    const [allEmojis, setAllEmojis] = React.useState<
        unicodeEmoji.BaseEmoji[] | null
    >(allEmoj);

    const [openSearch, setOpenSearch] = React.useState<boolean>(false);

    const filterEmojis = (search) => {
        if (allEmojis) {
            setAllEmojis(
                allEmoj.filter((emo) => emo.description.includes(search)),
            );
        }
    };
    return (
        <div className="flex flex-col flex-nowrap overflow-y-auto scrollbar h-full border-t p-1">
            <div className="flex justify-between bg-gray-100 rounded-sm p-2">
                <span
                    className="text-2xl cursor-pointer"
                    onClick={() => {
                        setSelectedGroup(null);
                        setOpenSearch(true);
                    }}
                >
                    {searchIcon?.emoji}
                </span>
                {groups.map((g) => {
                    return (
                        <span
                            className="text-2xl cursor-pointer"
                            onClick={() => {
                                setOpenSearch(false);
                                setSelectedGroup(g);
                            }}
                            key={g}
                        >
                            {emojis[g][0].emoji}
                        </span>
                    );
                })}
            </div>
            {openSearch && (
                <div>
                    <input
                        type="text"
                        onChange={(e) => filterEmojis(e.target.value)}
                        className="input-search--input border border-solid h-10 p-2"
                        placeholder="suche"
                    />
                </div>
            )}
            <div className="scrollbar overflow-y-auto flex flex-wrap cursor-pointer">
                {openSearch &&
                    allEmojis &&
                    allEmojis.map((emo) => {
                        return (
                            <span
                                onClick={() => onClick(emo.emoji)}
                                className="text-xl p-1"
                                key={emo.emoji}
                            >
                                {emo.emoji}
                            </span>
                        );
                    })}
                {selectedGroup &&
                    emojis[selectedGroup].map((emo) => {
                        return (
                            <span
                                onClick={() => onClick(emo.emoji)}
                                className="text-xl p-1"
                                key={emo.emoji}
                            >
                                {emo.emoji}
                            </span>
                        );
                    })}
            </div>
        </div>
    );
};

export default EmojiPicker;
