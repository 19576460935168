import * as React from 'react';
import { parseMessage } from '../../libs/matrix/message';
import Quote from './Quote';

type TProps = {
    me: boolean;
    message: string;
    replyTo: string | null;
    isReply: boolean;
    roomId: string;
    callback: () => void;
};

const Message = ({ message, isReply }: { message: string; isReply: boolean }) => {
    let isEmoji = false;
    if (message?.length === 2) {
        const regex_emoji =
            /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
        isEmoji = !!message.match(regex_emoji);
    }
    const { html, body } = parseMessage(message);
    return (
        <div
            className={`break-words whitespace-pre-wrap ${isReply && message && message?.length > 500
                ? 'lineclamp'
                : 'p-2'
                }  ${isEmoji ? 'text-4xl' : 'text-sm'}`}
        >
            {html ? html : body}
        </div>
    );
};

const HtmlMessage = ({ htmlMessage }: { htmlMessage: string }) => {
    let isEmoji = false;
    return (
        <div
            className={`break-words whitespace-pre-wrap p-2 ${isEmoji ? 'text-4xl' : 'text-sm'
                }`}
            dangerouslySetInnerHTML={{ __html: htmlMessage }}
        ></div>
    );
};

const Text: React.FC<TProps> = ({
    me,
    message,
    replyTo,
    roomId,
    isReply,
    callback,
}) => {
    const { html, body } = parseMessage(message, !!replyTo || !!isReply);

    React.useEffect(() => {
        if (!replyTo) callback();

    }, [])


    return (
        <span
            className={`px-4 py-2 max-w-md rounded-lg inline-block rounded-bl-none ${!isReply
                ? me
                    ? 'bg-background-normal text-white'
                    : 'bg-background-dark text-white'
                : 'bg-transparent'
                }
            `}
        >
            <div className="flex flex-col">
                {replyTo && (
                    <Quote
                        replyTo={replyTo}
                        roomId={roomId}
                        me={me}
                        callback={callback}
                    />
                )}
                {html ? (
                    <HtmlMessage htmlMessage={html} />

                ) :
                    <Message message={body} isReply={isReply} />
                }
            </div>
        </span>
    );
};
export default Text;
