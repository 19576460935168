import CryptoJs from 'crypto-js';
import crypto from 'crypto';
import { JSEncrypt } from 'jsencrypt';

interface AESFileObject {
    fileKey: string
    fileIV: string
}

export const encryptFile = (file: File, key: string, iv:string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      try {
        const dataBuffer = Buffer.from(fileReader.result as ArrayBuffer);

        const cipher = crypto.createCipheriv('aes-128-cbc', key, iv);
        // const ivBuffer = Buffer.from(iv);
        const result = Buffer.concat([cipher.update(dataBuffer), cipher.final()]);
        const blob = new Blob([result], {
          type: file.type
        });
        resolve(blob);
      } catch (error) {
        reject('Error while encrypting file: ' + error);
      }
    };

    fileReader.onerror = () => {
      reject('Error reading the input file.');
    };

    fileReader.readAsArrayBuffer(file);
  });
}

export const AESEncryptionWithIv = (message: string, fileObject: AESFileObject) => {
  if(fileObject){
    const cipher = crypto.createCipheriv('aes-128-cbc', fileObject.fileKey, fileObject.fileIV);
    let crypted = cipher.update(message,'utf8','base64');
    crypted += cipher.final('base64');
    return crypted;
  }
};

//Decryption

// export const AESDecryptionWithIv = (encryptedMessage: string, fileObject: AESFileObject) => {
//   let decipher = crypto.createDecipheriv('aes-128-cbc',fileObject.fileKey,fileObject.fileIV)
//   // decipher.setAutoPadding(false)
//   let decoded  = decipher.update(encryptedMessage,'base64','utf8') //base64 , hex
//   decoded  += decipher.final('utf8')
//   return decoded
// }

// export const decryptFileBuffer = async (encryptedFile: Blob, key: string, _iv: string) => {
//   const encryptedBuffer = await encryptedFile.arrayBuffer()
//   const _buffer = Buffer.from(encryptedBuffer);
//   // console.log("_buffer: ", _buffer)
//   // const buffer = Buffer.from(encryptedBuffer).slice(16);
//   // encrypted = encrypted.slice(16)
//   // const ivstring = _iv.toString('hex').slice(0, 16);
//   // console.log("iv-from decryptFileBuffer: ", ivstring)
//   const decipher = crypto.createDecipheriv('aes-128-cbc', key, _iv)
//   const result = Buffer.concat([ decipher.update(_buffer), decipher.final() ])
//   console.log("result of decrypted buffer: ", result)
//   const blob = new Blob([result]);

//   return blob
  
// }

// export const AESDecryption = async (encryptedMessage: string, rsaEncryptedFileKey: string, rsaEncryptedFileIV: string, file?: Blob) => {
//     const encryptedFileKey = rsaEncryptedFileKey;
//     const encryptedFileIV = rsaEncryptedFileIV; 
    
//     try {
//         const key = new NodeRSA(privateKey);
//         key.setOptions({encryptionScheme: 'pkcs1'});
//         const decryptedFileKey = key.decrypt(encryptedFileKey, 'utf8');
//         const decryptedFileIV = key.decrypt(encryptedFileIV, 'utf8');
//         const decrypt = await AES_Decrypt(encryptedMessage, decryptedFileKey, decryptedFileIV, file)
//         return decrypt
//       } catch (error) {
//         console.error('Decryption failed:', error);
//       }
// }

// async function AES_Decrypt(encryptedMessage: string, key: string, iv: string, file?: Blob) {
//     const _key = CryptoJs.enc.Hex.parse(key);
//     const _iv = CryptoJs.enc.Hex.parse(iv);
//     try{
//       // console.log("encryptedMessage: ", encryptedMessage)
//       //   const decryptedData = CryptoJs.AES.decrypt(encryptedMessage, _key, { iv: _iv });
//       //   const _decryptedData = decryptedData.toString(CryptoJs.enc.Utf8);
//       //   console.log("_decryptedData!!!: ", (_decryptedData))
        
//         // return _decryptedData

//         const aesDecryptFile = await decryptFileBuffer(file, key, iv)
        
//         console.log("attachment_aesDecryptFile: ", aesDecryptFile)
//         return aesDecryptFile
//     }
//     catch(e) {
//         console.error('AES_Decrypt Error:', e);
//     }
//   }

export const getIv = () => {
    const ivKey = crypto.randomBytes(16);
    const iv = CryptoJs.enc.Hex.parse(ivKey.toString());
    return iv
}

export const getDataHash = (data: string) => {
    const hash = CryptoJs.SHA256(data).toString();
    return hash
}

export const generateKeyAndIv = async () => {
    try {
        const randomBytes = new Uint8Array(32);
        window.crypto.getRandomValues(randomBytes);

        const fileKey = randomBytes.subarray(0, 16);
        const fileIV = randomBytes.subarray(16, 32);

        const fileKeyHex = Array.from(fileKey).map(byte => byte.toString(16).padStart(2, '0')).join('').slice(0,16);
        const fileIVHex = Array.from(fileIV).map(byte => byte.toString(16).padStart(2, '0')).join('').slice(0,16);

        return { fileKey: fileKeyHex, fileIV: fileIVHex };
    } catch (error) {
        throw error
    }
  };

export const rsaEncryption = (message: string, publicKey: string) => {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKey)
    return encryptor.encrypt(message);
}

// export const AESFileEncryptionWithIv = (file: File, fileObject: AESFileObject): Promise<File> => {

//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
    
//         reader.onload = (event) => {
//             try{
//                 if (event.target && event.target.result) {
//                     const key = CryptoJs.enc.Hex.parse(fileObject.fileKey);
//                     const iv = CryptoJs.enc.Hex.parse(fileObject.fileIV);
    
//                     const buffer = event.target.result as ArrayBuffer;
//                     const truncatedLength = Math.floor(buffer.byteLength / 4) * 4; // Truncate length to the nearest multiple of 4 (required by unit32Array)
//                     const truncatedBuffer = buffer.slice(0, truncatedLength);
//                     /*
//                         event.target.result gives the ArrayBuffer value which is when used directly 
//                         as a parameter in the WordArray.create() function, causes the typescript to 
//                         pop an error as that function expects an array of numbers.
//                     */
//                     const uint32Array = new Uint32Array(truncatedBuffer); 
//                     // const uint32Array = new Uint32Array(buffer); 
//                     const wordArrayData = Array.from(uint32Array); 
    
//                     const wordArray = CryptoJs.lib.WordArray.create(wordArrayData);
//                     const encrypted = CryptoJs.AES.encrypt(wordArray, key, {iv: iv}).toString();
//                     // const fileEnc = new Blob([encrypted], {
//                     //         type: file.type,
//                     //       });
//                     const fileEnc = new File([encrypted], file.name, {
//                         type: file.type,
//                     });
//                     resolve(fileEnc);
//                 }
//             }
//             catch (error) {
//                 reject(new Error('Error processing the file'));
//             }
            
//         };
    
//         reader.onerror = () => {
//           reject(new Error('Failed to read the file.'));
//           throw new Error('Failed to read the file.')
//         };
    
//         reader.readAsArrayBuffer(file);
//     });
// }

export function getFileHash(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let hashHex = "";
  
        reader.onload = () => {
            const fileData = reader.result;
            const byteArray = new Uint8Array(fileData as ArrayBuffer);
            const hashBuffer = window.crypto.subtle.digest('SHA-256', byteArray);
    
            hashBuffer.then((hashArrayBuffer) => {
                const hashArray = Array.from(new Uint8Array(hashArrayBuffer));
                hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
                resolve(hashHex);
            });
        };
  
        reader.onerror = () => {
            reject(new Error('Error reading the file'));
        };
  
        reader.readAsArrayBuffer(file);
    });
}