import * as React from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import mcli from '../../libs/matrix';
import {hideActiveModal, selectChatLeaveRoomModal} from '../../reducer/app';
import {selectSelectedRoom, updateSelectedRoom} from '../../reducer/matrix';
import ModalSkelleton from '../ModalSkelleton';
import {selectRoom} from '../../reducer/matrix';
import {useTranslation} from 'react-i18next';

const ChatLeaveRoomModal: React.FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const selectedRoomId = useAppSelector(selectSelectedRoom);
    const selectChatLeaveRoom = useAppSelector(selectChatLeaveRoomModal);
    const roomId = selectChatLeaveRoom?.roomId || selectedRoomId;

    const room = useAppSelector((state) => selectRoom(state, roomId || ''));

    const leaveRoom = (id: string) => {
        mcli.leave(id);
        dispatch(updateSelectedRoom(""));
    };

    if (!room || !room?.id) return <></>;

    return (
        <ModalSkelleton>
            <div className="p-4">
                <div>
                    <h4>{t('leave_chat_text')}</h4>
                    <div className="mt-2">{t('leave_chat_warning')}</div>
                </div>
                <div className="flex items-center justify-between mt-4">
                    <button
                        className="mapp-button mapp-button-secondary"
                        onClick={() => dispatch(hideActiveModal())}
                    >
                        {t('abort')}
                    </button>
                    <button
                        className="mapp-button mapp-button-danger"
                        onClick={() => {
                            leaveRoom(room.id);
                            dispatch(hideActiveModal());
                        }}
                    >
                        <b>{t('leave_chat')}</b>
                    </button>
                </div>
            </div>
        </ModalSkelleton>
    );
};

export default ChatLeaveRoomModal;
