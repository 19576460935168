import StorageModule from '../StorageModule';

export type TCoupon = {
    link: string;
    code: string;
};

type TStore = {
    items: TCoupon;
};

let store: TStore = {
    items: {link: '', code: ''},
};

class CouponModule extends StorageModule<TStore> {
    constructor() {
        super({name: 'coupon', store});
    }
}

export default CouponModule;
