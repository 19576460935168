import * as React from 'react';
import { IContent } from 'matrix-js-sdk';
import Image from './Image';
import Text from './Text';
import Video from './Video';
import File from './File';
import BadEncryptText from './BadEncryptText';
import { useTranslation } from 'react-i18next';
import Audio from './Audio';

type TProps = {
	content: IContent;
	message: string;
	roomId: string;
	isReply?: boolean;
	me: boolean;
	replyTo: string | null;
	callback: () => void;
};
const Switch: React.FC<TProps> = ({
	content,
	message,
	roomId,
	me,
	isReply,
	replyTo,
	callback,
}) => {
	const { t } = useTranslation();

	switch (content.msgtype) {
		case 'm.image':
			return (
				<div className="">
					{content?.file ? (
						<Image
							name={content.body}
							file={content.file}
							callback={callback}
							info={content.info}
						/>
					) : (
						<Image
							name={content.body}
							url={content.url || ''}
							callback={callback}
							info={content.info}
						/>
					)}
				</div>
			);
		case 'm.video':
			return (
				<div className="">
					<Video content={content} callback={callback} />
				</div>
			);
		case 'm.file':
			return (
				<div className="">
					{content?.file ? (
						<File name={content.body} file={content.file} />
					) : (
						<File name={content.body} url={content.url || ''} />
					)}
				</div>
			);
		case 'm.audio':
			return <Audio content={content} callback={callback} />;
		case 'm.bad.encrypted':
			return (
				<BadEncryptText me={me} isReply={isReply || false}>
					<span>&bdquo;{t('bad_encrypt_message')}&rdquo;</span>
				</BadEncryptText>
			);
		default:
			return (
				<Text
					message={content.body || message}
					me={me}
					replyTo={replyTo}
					isReply={isReply || false}
					roomId={roomId}
					callback={callback}
				/>
			);
	}
};

export default Switch;
