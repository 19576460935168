import StorageModule from '../StorageModule';

export type TTools = {
    items: TToolItem[];
};

export type TToolItem = {
    id: string;
    title: string;
    url: string;
    icon: string;
    image: string;
};

type TStore = {
    items: TTools[];
};

let store: TStore = {
    items: [],
};

class ToolsModule extends StorageModule<TStore> {
    constructor() {
        super({name: 'tools', store});
    }
}

export default ToolsModule;
