import { saveLocal, loadLocal } from '@atino/libs/browser/storage';
import {Config} from '../Config';
import localStore, { setStore } from './Local';

export class StorageModule<TStore> {
    name = '';
    storeName = '';

    constructor({ name, store }: { name: string; store: TStore }) {
        this.name = name;
        this.storeName = name;
        localStore[name] = Config.local ? loadLocal('mapp-sdk') ? loadLocal('mapp-sdk')[name] : store : store;
    }

    /**
     * @returns {TStore} store object
     */
    get = (): TStore => this.getStore();

    /**
     * update store
     * @returns {void}
     */
    set = (store: TStore): void => this.updateStore(store);

    /**
     * get store
     */
    protected readonly getStore = (): TStore => {
        if (Config.local) {
            const lstore = loadLocal('mapp-sdk');
            if (!!lstore && lstore[this.name]) {
                return lstore[this.storeName];
            }
        }
        return localStore[this.storeName];
    };

    /**
     * update store from auth login response
     */
    protected readonly updateStore = (_store: TStore) => {
        if (!localStore[this.storeName] || !_store) return;
        let wasUpdated = false;

        let lstore = Config.local ? loadLocal('mapp-sdk') : localStore;
        if (lstore === undefined) lstore = {};
        if (!lstore[this.storeName]) {
            lstore[this.storeName] = _store;
            if(Config.local) {
                saveLocal('mapp-sdk', lstore);
            }
            return;
        }
        Object.keys(lstore[this.storeName]).forEach((key: string) => {
            Object.keys(_store).forEach((newKey: string) => {
                if (key === newKey) {
                    // @ts-ignore
                    if (!_store[newKey] || !_store[newKey]?.length === 0)
                        return;
                    lstore[this.storeName] = Object.assign(
                        lstore[this.storeName],
                        {
                            // @ts-ignore
                            [newKey]: _store[newKey],
                        },
                    );
                    wasUpdated = true;
                }
            });
        });
        if (Config.local && wasUpdated)
            saveLocal('mapp-sdk', {
                ...lstore,
            });
        setStore(lstore);
    };
}
export default StorageModule;
