import * as React from 'react';
import mcli from '../../libs/matrix';
import { downloadFile } from '@atino/libs/common/download';

import {
    TFile,
    getDecryptedImageUrl,
    TFileInfo,
} from '../../libs/matrix/message';
import { useAppDispatch } from '../../hooks';
import { openImageModal } from '../../reducer/app';

type TImage = {
    url: string;
    objUrl: string;
};

type TInfo = {};

const imageStore: TImage[] = [];

type TProps = {
    file?: TFile;
    url?: string;
    name: string;
    callback: () => void;
    info: TFileInfo;
};

const Image: React.FC<TProps> = React.memo(
    ({ url, file, name, callback, info }) => {
        const dispatch = useAppDispatch();

        const isFile = !!file?.url;
        const found = imageStore.find(
            (i) => i.url === (isFile ? file.url : url),
        );

        const imageUrl = !isFile ? mcli.mxcUrlToHttp(url || "") || undefined : undefined;

        const [image, setImage] = React.useState<string | undefined>(
            found?.objUrl || imageUrl,
        );
        const [ready, setReady] = React.useState(false);

        React.useEffect(() => {
            if (!found && !image && isFile) {
                getDecryptedImageUrl(file).then((resUrl) => {
                    if (resUrl) {
                        setImage(resUrl);
                        imageStore.push({
                            url: file.url,
                            objUrl: resUrl || '',
                        });
                    }
                });
            } else {

            }
        }, []);



        const calcHeight = (originWidth: number, width: number, originHeight: number) => {
            let factor = 1;

            if (originWidth < width) {
                factor = originWidth / width;
            }
            if (originWidth > width) {
                factor = width / originWidth;
            }
            return originHeight * factor;
        }

        const width = info?.w ? info.w <= 320 ? info.w : 320 : 320;
        const height = calcHeight(info?.w || 250, width, info?.h || 300);

        return (
            <>
                <div className="h-fill w-fill">
                    {image && <>

                        {!ready && <div className={`max-w-full image-skeleton`} style={{ width, height }}></div>}
                        <img
                            onLoad={() => { setReady(true); callback(); }}
                            src={image}
                            onClick={() => dispatch(openImageModal({ url: image }))}
                            className={`rounded ${!ready && "hidden"}`}
                            data-type="modal-btn"
                            style={{ width, height }}
                        />
                        <a
                            className="text-xs text-gray-500 cursor-pointer"
                            onClick={() => downloadFile(image || "", name)}
                        >
                            download
                        </a></>
                    }
                </div>
            </>
        );
    },
);

export default Image;
