import React, { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { selectSyncState } from '../../reducer/app';
import { SyncState } from 'matrix-js-sdk/lib/sync';
import { useTranslation } from 'react-i18next';

const NetworkErrorTooltip = () => {
	const [tooltipStyle, setTooltipStyle] = useState({ display: 'none' });
	const syncState = useAppSelector(selectSyncState);
	const { t } = useTranslation();

        const showTooltip = (e: any) => {
		const tooltipElement = document.getElementById('tooltip');
		if (!tooltipElement) return;
		const x = e.clientX - 100;
		const y = e.clientY;
		tooltipElement.style.left = `${x}px`;
		tooltipElement.style.top = `${y}px`;
		setTooltipStyle({ display: 'block' });
	};

	const hideTooltip = () => {
		setTooltipStyle({ display: 'none' });
	};

	const isNetworkError =
		syncState.state === SyncState.Error ||
		syncState.state === SyncState.Reconnecting;
	const networkErrorColor =
		syncState.state === SyncState.Reconnecting ? 'yellow' : 'red';

	if (!isNetworkError) return null;
	return (
		<div
			className={`relative group text-${networkErrorColor}-400`}
			onMouseMove={showTooltip}
			onMouseOut={hideTooltip}>
			<p>{t('error_network_short')}</p>
			<div
				id="tooltip"
				className="absolute py-1 px-3 bg-red-700 text-white rounded w-80"
				style={tooltipStyle}>
				{t('error_network')}
			</div>
		</div>
	);
};

export default NetworkErrorTooltip;
