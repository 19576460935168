import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks';
import mcli from '../../libs/matrix';
import {hideActiveModal, selectChatMessageDeleteModal} from '../../reducer/app';
import ModalSkelleton from '../ModalSkelleton';

const ChatMessageDeleteModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const app = useAppSelector(selectChatMessageDeleteModal);
    const {t} = useTranslation();

    const deleteMessage = async (eventId: string, roomId: string) => {
        await mcli.redactEvent(roomId, eventId, undefined, {});
    };

    if (!app?.eventId || !app?.roomId) return <></>;
    return (
        <ModalSkelleton>
            <div className="p-4">
                <div className="text-lg font-semibold">
                    {t('delete_message')}
                </div>
                <div className="flex items-center justify-between mt-4">
                    <button
                        className="mapp-button mapp-button-secondary"
                        onClick={() => dispatch(hideActiveModal())}
                    >
                        {t('abort')}
                    </button>
                    <button
                        className="mapp-button mapp-button-primary"
                        onClick={() => {
                            deleteMessage(app.eventId, app.roomId);
                            dispatch(hideActiveModal());
                        }}
                    >
                        {t('accept')}
                    </button>
                </div>
            </div>
        </ModalSkelleton>
    );
};

export default ChatMessageDeleteModal;
