import { unescapeHtml } from '@atino/libs/unescapeHTML/index';
import { IContent, RoomMember } from 'matrix-js-sdk';
import moment from 'moment';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import mcli from '../../libs/matrix';
import { sendMedia, sendMessage, sendVideo } from '../../libs/matrix/message';
import { makeReplyMixIn } from '../../libs/matrix/reply';
import { openUploadModal } from '../../reducer/app';
import { getRoomAsync, selectRoom } from '../../reducer/matrix';
import EmojiPicker from '../EmojiPicker';
import GifPicker from '../GifPicker';
import ChatClipIcon from './Icons/ChatClipIcon';
import ChatGifIcon from './Icons/ChatGifIcon';
import ChatSendingIcon from './Icons/ChatSendingIcon';
import ChatSmileIcon from './Icons/ChatSmileIcon';
import { handleFile } from '../../libs/matrix/image';
let sendTypingDate = new Date();

type TReplyProps = {
	message: string;
	user: RoomMember | undefined;
	type: string;
	id: string;
	replyEvent: any;
};

type TProps = {
	roomId: string;
	messageToReply?: TReplyProps | null;
	closeReply: () => void;
};

let lastRoomId = '';

const ChatInput: React.FC<TProps> = ({
	roomId,
	messageToReply,
	closeReply,
}) => {
	const isMobile = window.innerWidth < 621;
	const room = useAppSelector((state) => selectRoom(state, roomId));
	const inputRef = React.useRef<HTMLSpanElement | null>(null);
	const fileUploadRef = React.useRef<HTMLInputElement | null>(null);
	const specialContainerRef = React.useRef<HTMLDivElement>(null);
	const isThisRef = React.useRef<any>(null);
	const [text, setText] = React.useState('');
	const [recorder, setRecorder] = React.useState<MediaRecorder>();
	const [recorderState, setRecorderState] =
		React.useState<string>('inactive');

	const [sending, setSending] = React.useState(false);
	const [isThis, _setIsThis] = React.useState<number | null>(null);
	const dispatch = useAppDispatch();
	let chunks = [];

	const setIsThis = (data: number | null) => {
		isThisRef.current = data;
		_setIsThis(data);
	};

	React.useEffect(() => {
		let switched = roomId !== lastRoomId;
		if (switched) {
			lastRoomId = roomId;
			if (inputRef.current) {
				inputRef.current.innerText = '';
				inputRef.current.innerHTML = '';
				inputRef.current.textContent = '';
			}

			setText('');
		}
		const hideSpecial = (evt: any) => {
			const selected = document.querySelector('[data-type=special]');
			if (!selected) return evt;
			if (!selected?.contains(evt.target)) {
				specialContainerTransEnd();
				setIsThis(null);
			}
		};
		if (isThis && isThis !== null) {
			window.addEventListener('mousedown', hideSpecial);
		}
		if (!isMobile && inputRef.current) {
			inputRef.current.focus();
		}
		if (switched) {
			specialContainerTransEnd();
			setIsThis(null);
		}

		// if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
		// 	console.log('getUserMedia supported.');
		// 	navigator.mediaDevices
		// 		.getUserMedia(
		// 			// constraints - only audio needed for this app
		// 			{
		// 				audio: true,
		// 			}
		// 		)

		// 		// Success callback
		// 		.then((stream) => {
		// 			const mediaRecorder = new MediaRecorder(stream);
		// 			setRecorder(mediaRecorder);
		// 		})

		// 		// Error callback
		// 		.catch((err) => {
		// 			console.error(
		// 				`The following getUserMedia error occurred: ${err}`
		// 			);
		// 		});
		// } else {
		// 	console.log('getUserMedia not supported on your browser!');
		// }

		document.addEventListener('keyup', (e) => closeSpecial(e));
		return () => {
			window.removeEventListener('mousedown', hideSpecial);
			document.removeEventListener('keyup', (e) => closeSpecial(e));
		};
	}, [sending, roomId, isThis]);

	const closeSpecial = (evt: any) => {
		evt.preventDefault();
		if (evt.keyCode == 27 && isThisRef.current)
			toggleSpecial(isThisRef.current);
	};

	const specialContainerTransStart = () => {
		if (!specialContainerRef.current) return;
		specialContainerRef.current.classList.remove('hidden', 'opacity-0');
	};
	const specialContainerTransEnd = () => {
		if (!specialContainerRef.current) return;
		specialContainerRef.current.classList.add('hidden', 'opacity-0');
	};

	const send = async (e: any) => {
		if (!inputRef || !inputRef.current) return;

		const msg = inputRef.current?.innerText;
		if (!msg || msg?.length === 0) return;

		if (inputRef.current) {
			inputRef.current.innerText = '';
			inputRef.current.innerHTML = '';
			inputRef.current.textContent = '';
		}

		setSending(true);

		if (messageToReply) {
			const content: IContent = {
				body: cleanMessage(text),
				msgtype: 'm.text',
				'm.relates_to': {},
				formatted_body: '',
			};
			content['m.relates_to'] = {
				...(content['m.relates_to'] || {}),
				// @ts-ignore
				...makeReplyMixIn(messageToReply.replyEvent.event_id),
			};
			content.body = messageToReply.replyEvent.body + content.body;
			(content.format = 'org.matrix.custom.html'),
				(content.formatted_body =
					messageToReply.replyEvent.html + text);

			sendMessage(roomId, content, 'm.text')
				.then(() => {
					dispatch(getRoomAsync(roomId));
				})
				.catch(console.error);
		} else {
			sendMessage(roomId, msg, 'm.text')
				.then(() => {
					dispatch(getRoomAsync(roomId));
				})
				.catch(console.error);
		}

		setText('');
		closeReply();
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		isThis && toggleSpecial(isThis);
		send(e);
	};

	const handleTextInput = (addText: string) => {
		setText(text + addText);
		if (inputRef && inputRef.current) {
			inputRef.current.textContent = text + addText;
		}
	};

	function dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		let extension = '.jpg';

		switch (mime) {
			case mime.includes('png'):
				extension = '.png';
				break;
			case mime.includes('mp4'):
				extension = '.mp4';
				break;
		}

		return new File([u8arr], filename.replaceAll(' ', '_') + extension, {
			type: mime,
		});
	}

	const handleClipboardImageBlob = (e: any) => {
		const clipBoardImage = e.target?.innerHTML;
		const fileArray: any[] = [];
		if (clipBoardImage) {
			const div = document.createElement('div');
			div.innerHTML = clipBoardImage;
			const img = div.querySelector('img');
			const imageSrc = img?.src;
			const formatDate = moment().format('DD-MM-YYYY_hh:mm:ss');
			const imgFile = dataURLtoFile(imageSrc, `Screenshot_${formatDate}`);

			if (imgFile) {
				handleFile(imgFile);
			}
		}
	};

	const handleURLFileUpload = async (url: string) => {
		setSending(true);
		specialContainerTransEnd();
		setIsThis(null);
		const res = await fetch(url);
		if (!res.ok) {
			setSending(false);
			console.error(
				'Error downloading Tenor Media -> ',
				res.status,
				res.statusText
			);
		}
		const urlParts = url.split('/');
		const name = urlParts[urlParts.length - 1];
		const blob = await res.blob();
		const file = new File([blob], name, { type: 'image/gif' });

		return sendMedia(roomId, 'm.image', file).finally(() => {
			setSending(false);
		});
	};

	const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;
		const file = e.target.files[0];
		if (file) {
			handleFile(file);
		}
	};

	const toggleSpecial = (n: number) => {
		if (isThis && isThisRef.current === n) {
			specialContainerTransEnd();
			setIsThis(null);
		} else {
			specialContainerTransStart();
			setIsThis(n);
		}
	};

	const cleanMessage = (replyMessage: string) => {
		const regex = />(.*?)</g;
		const clean = regex.exec(replyMessage);

		if (clean) return clean[1];
		return unescapeHtml(replyMessage);
	};

	// const startRecordAudioMessage = () => {
	// 	// check permission
	// 	if (recorder) {
	// 		recorder.start();
	// 		setRecorderState(recorder.state);
	// 		console.log(recorder.state);
	// 	}
	// };
	// const stopRecordAudioMessage = () => {
	// 	if (recorder) {
	// 		recorder.stop();
	// 		setRecorderState(recorder.state);
	// 	}
	// };

	// if (recorder) {
	// 	recorder.ondataavailable = async (e: any) => {
	// 		if (e.data) {

	// 			await sendMedia(
	// 				roomId,
	// 				'm.audio',
	// 				new Blob([e.data], { type: 'audio/aac' }),
	// 				'Audio'
	// 			);
	// 		}
	// 	};
	// }

	return (
		<>
			<form data-type={'special'} onSubmit={handleSubmit}>
				<div className="flex flex-col flex-nowrap">
					<div
						ref={specialContainerRef}
						className={`hidden transition ease-in-out delay-150 h-56 max-h-56`}>
						{isThis === 1 && (
							<EmojiPicker onClick={handleTextInput} />
						)}
						{isThis === 2 && (
							<GifPicker onClick={handleURLFileUpload} />
						)}
					</div>
					<div className="chat-input-field">
						<div className="chat-input_wrapper">
							{messageToReply && (
								<div className="chat-message_to-reply-wrapper">
									<div className="chat-message_to-reply">
										<div className="chat-message__reply-container">
											<div className="chat-message__reply-sender">
												{messageToReply?.user?.name}
												<div
													className="chat-message__reply-close"
													onClick={closeReply}>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 320 512"
														className="w-3 h-3 mr-1 mt-1 fill-gray-400 hover:fill-gray-500 cursor-pointer">
														<path d="M312.1 375c9.369 9.369 9.369 24.57 0 33.94s-24.57 9.369-33.94 0L160 289.9l-119 119c-9.369 9.369-24.57 9.369-33.94 0s-9.369-24.57 0-33.94L126.1 256L7.027 136.1c-9.369-9.369-9.369-24.57 0-33.94s24.57-9.369 33.94 0L160 222.1l119-119c9.369-9.369 24.57-9.369 33.94 0s9.369 24.57 0 33.94L193.9 256L312.1 375z" />
													</svg>
												</div>
											</div>
											{cleanMessage(
												messageToReply?.message
											)}
										</div>
									</div>
								</div>
							)}
							<div
								className={`chat-input ${
									messageToReply
										? 'chat-input__with-reply'
										: ''
								}`}>
								<span
									ref={inputRef}
									placeholder="..."
									contentEditable={true}
									className="textarea w-full"
									role="textarea"
									// @ts-ignore
									onInput={(e: any) => {
										const div =
											document.createElement('div');
										div.innerHTML = e.target.innerHTML;
										if (div.childElementCount === 1) {
											if (div.querySelector('img')) {
												e.preventDefault();
												handleClipboardImageBlob(e);
												e.target.textContent = '';
												setText('');
												return;
											}
										}
										const value =
											e.target.innerText ||
											e.target.textContent;
										setText(value);
										//e.target.textContent = e.target.innerText || e.target.textContent;
									}}
									onKeyUp={(e: any) => {
										const now = new Date();
										if (
											sendTypingDate.getTime() / 1000 +
												2 <
											now.getTime() / 1000
										) {
											sendTypingDate = now;
											mcli.sendTyping(roomId, true, 3000);
										}
									}}
									onKeyDown={(e: any) => {
										if (e.code === 'Enter' && !e.shiftKey) {
											handleSubmit(e);
											// @ts-ignore
											e.target.textContent = '';
											// @ts-ignore
											e.target.innerHTML = '';
											setText('');
										}
									}}></span>
								<div className="chat-input__icon-wrapper">
									<div
										className="chat-input__emoji-icon"
										onClick={() => toggleSpecial(1)}>
										<ChatSmileIcon />
									</div>
									<div className="cursor-pointer h-6 w-6 rounded-md mt-1 mr-1">
										<input
											ref={fileUploadRef}
											className="hidden"
											id="data-picker"
											type="file"
											onChange={(e) => {
												if (e.target.files)
													handleFileUpload(e);
											}}
										/>
										<label
											htmlFor="data-picker"
											className="chat-input__clip-icon">
											<ChatClipIcon />
										</label>
									</div>
									<div className="cursor-pointer">
										<div
											className="chat-input__gif-icon"
											onClick={() => toggleSpecial(2)}>
											<ChatGifIcon />
										</div>
									</div>
									<div
										className="chat-input__send-icon"
										onClick={send}>
										<ChatSendingIcon />
									</div>

									{/* {recorderState == 'recording' ? (
										<div
											className="audio-recorder 2"
											onClick={() =>
												stopRecordAudioMessage()
											}>
											stop record
										</div>
									) : (
										<div
											className="audio-recorder text-base border rounded bg-blue-300"
											onClick={() =>
												startRecordAudioMessage()
											}>
											Record
										</div>
									)} */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

export default ChatInput;
