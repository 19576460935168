import sanitizeHtml from 'sanitize-html';
import escapeHtml from '@atino/libs/escapeHtml/index';
import { IEventRelation, MatrixEvent } from 'matrix-js-sdk';

const ALLOWED_TAGS = [
    'font',
    'del',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'ul',
    'ol',
    'sup',
    'sub',
    'blockquote',
    'li',
    'b',
    'i',
    'u',
    'strong',
    'em',
    'strike',
    'code',
    'hr',
    'br',
    'div',
    'table',
    'thead',
    'tbody',
    'tr',
    'th',
    'td',
    'caption',
    'pre',
    'span',
    'img',
    'details',
    'summary',
];

const PERMITTED_URL_SCHEMES = [
    'ftp',
    'geo',
    'http',
    'https',
    'im',
    'irc',
    'ircs',
    'mailto',
    'news',
    'nntp',
    'openpgp4fpr',
    'sip',
    'sftp',
    'ssh',
    'tel',
    'urn',
    'webcal',
];

// REPLY CONTENT

export function getParentEventId(ev: MatrixEvent) {
    if (!ev || ev.isRedacted()) return;
    if (ev.replyEventId) {
        return ev.replyEventId;
    }
}

function stripPlainReply(body: string) {
    // Removes lines beginning with `> ` until you reach one that doesn't.
    const lines = body.split('\n');
    while (lines.length && lines[0].startsWith('> ')) lines.shift();
    // Reply fallback has a blank line after it, so remove it to prevent leading newline
    if (lines[0] === '') lines.shift();
    return lines.join('\n');
}

export function stripHTMLReply(html: string) {
    // Sanitize the original HTML for inclusion in <mx-reply>.  We allow
    // any HTML, since the original sender could use special tags that we
    // don't recognize, but want to pass along to any recipients who do
    // recognize them -- recipients should be sanitizing before displaying
    // anyways.  However, we sanitize to 1) remove any mx-reply, so that we
    // don't generate a nested mx-reply, and 2) make sure that the HTML is
    // properly formatted (e.g. tags are closed where necessary)
    return sanitizeHtml(html, {
        allowedTags: ALLOWED_TAGS, // false means allow everything
        allowedAttributes: false,
        // we somehow can't allow all schemes, so we allow all that we
        // know of and mxc (for img tags)
        allowedSchemes: [...PERMITTED_URL_SCHEMES, 'mxc'],
        exclusiveFilter: (frame) => frame.tag === 'mx-reply',
    });
}

export function makeReplyMixIn(eventId: string, threadRootId?: string, relation?: IEventRelation) {
    const mixin = {
        'm.in_reply_to': {
            event_id: eventId
        },
    };

    if (threadRootId) {
        // Clients that do not offer a threading UI should behave as follows when replying, for best interaction
        // with those that do. They should set the m.in_reply_to part as usual, and then add on
        // "rel_type": "m.thread" and "event_id": "$thread_root", copying $thread_root from the replied-to event.
        // @ts-ignore
        mixin.rel_type = relation.rel_type;
        // @ts-ignore
        mixin.event_id = relation.event_id;
    }

    return mixin;
}

export function getNestedReplyText(ev: MatrixEvent) {
    if (!ev) return null;
    const { msgtype } = ev.getContent();
    let { body, formatted_body: html } = ev.getContent();


    if (!body) body = '';
    // @ts-ignore
    if (html) {
        // sanitize the HTML before we put it in an <mx-reply>
        html = stripHTMLReply(html);
    } else {
        // Escape the body to use as HTML below.
        // We also run a nl2br over the result to fix the fallback representation. We do this
        // after converting the text to safe HTML to avoid user-provided BR's from being converted.
        html = escapeHtml(body).replace(/\n/g, '<br/>');
    }

    // dev note: do not rely on `body` being safe for HTML usage below.

    // const evLink = permalinkCreator.forEvent(ev.getId());
    const mxid = ev.getSender();

    const mxReply =
        `<mx-reply><blockquote><a href="">In reply to</a> <a href="">${mxid}</a>` +
        `<br>VAR_TEXT</blockquote></mx-reply>`;

    // This fallback contains text that is explicitly EN.
    switch (msgtype) {
        case 'm.text': {
            html = mxReply.replace('VAR_TEXT', html);
            const lines = body.trim().split('\n');
            if (lines.length > 0) {
                lines[0] = `<${mxid}> ${lines[0]}`;
                body =
                    lines.map((line: string) => `> ${line}`).join('\n') +
                    '\n\n';
            }
            break;
        }
        case 'm.image':
            html = mxReply.replace('VAR_TEXT', 'sent an image.');
            body = `> <${mxid}> sent an image.\n\n`;
            break;
        case 'm.video':
            html = mxReply.replace('VAR_TEXT', 'sent a video.');
            body = `> <${mxid}> sent a video.\n\n`;
            break;
        case 'm.file':
            html = mxReply.replace('VAR_TEXT', 'sent a file.');
            body = `> <${mxid}> sent a file.\n\n`;
            break;
        case 'm.audio':
            html = mxReply.replace('VAR_TEXT', 'sent an audio file.');
            body = `> <${mxid}> sent an audio file.\n\n`;
            break;
        default:
            return null;
    }

    return { body, html, event_id: ev?.getId(), sender: ev?.getSender(), threadRootId: ev.threadRootId, relation: ev.getRelation() };
}