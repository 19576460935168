import * as React from 'react';
import {css} from '@emotion/react';
import PulseLoader from 'react-spinners/PulseLoader';
import {RoomMember} from 'matrix-js-sdk';
import { TMember } from '../../reducer/matrix';

type TProps = {
    typingUsers: TMember[];
};

const override = css`
    display: block;
    margin: 0 auto;
`;

export default function TypingIndicator({typingUsers}: TProps) {
    return (
        <div className="chat-is-typing">
            {typingUsers.map((user, index: number) => (
                <div key={user.userId}>
                    {user.name}
                    {index > 0 ? ', ' : ' '}
                </div>
            ))}
            <div className="chat-is-typing__indicator">
                <PulseLoader color={'#FFFFFF'} speedMultiplier={0.5} size={4} />
            </div>
        </div>
    );
}
