import Json from './json';

interface HTTP {
    json: Json;
}

class HTTP implements HTTP {
    public json = new Json();
}

export default HTTP;
