import StorageHandler from '../Storage/StorageHandler';
import { Config } from '../Config';
import HTTP from '../HTTP';

export enum Events {
	CONFIGURATION_HTPP_FAILED = 'CONFIGURATION_HTPP_FAILED',
}

class Tools {
	http = new HTTP();

	get = async () => {
		const url = new URL('/api/web/toolbox/items', Config.host);
		const res = await this.http.json.httpGet(url);

		if (!res.ok) {
			throw res;
		}

		const result = await res.json();

		return result;
	};

	getImage = async (id: string) => {
		const url = new URL(
			`/api/web/toolbox/${id}/image/download`,
			Config.host
		);
		const res = await this.http.json.httpGet(url);

		if (!res.ok) {
			return await res.json();
		}

		return URL.createObjectURL(await res.blob());
	};
}
export default Tools;
