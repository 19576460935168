export function unescapeHtml (htmlString:string) {
    if(htmlString) {
        htmlString = htmlString.replace(/&lt;/g , "<");
        htmlString = htmlString.replace(/&gt;/g , ">");
        htmlString = htmlString.replace(/&quot;/g , "\"");
        htmlString = htmlString.replace(/&#39;/g , "'");
        htmlString = htmlString.replace(/&amp;/g , "&");

        return htmlString;
    }
}