import StorageModule from '../StorageModule';

export type TChatUsersItem = {
    imageName: string;
};

type TStore = {
    images: Blob[];
};

let store: TStore = {
    images: [],
};

class ImagesModule extends StorageModule<TStore> {
    constructor() {
        super({name: 'images', store});
    }
}

export default ImagesModule;
