import StorageHandler from '../Storage/StorageHandler';
import { Config } from '../Config';
import HTTP from '../HTTP';

export enum Events {
    CONFIGURATION_HTPP_FAILED = 'CONFIGURATION_HTPP_FAILED',
}

class Coupon {
    http = new HTTP();

    get = async () => {
        const url = new URL('/api/web/coupon/code', Config.host);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) {
            return await res.json();
        }

        const result = await res.json();

        return result.data;
    };
}
export default Coupon;
