import StorageModule from '../StorageModule';
import {TPagination} from './NewsModule';

export type TWikiArticle = {
    id: string;
    headline: string;
    content: string;
    image: string;
    video: string;
    isFavourite: boolean;
    category: TWikiArticleCatogory;
    wikiArticleDocuments?: TWikiArticleDocument[];
};

export type TWikiArticleCatogory = {
    id: string;
    title: string;
    icon: string;
};

export type TWikiArticleDocument = {
    downloadLink: string;
    id: string;
    mimeType: string;
    name: string;
    originalName: string;
    size: number;
};

export type TWikiCatogory = {
    id: string;
    title: string;
    icon: string;
    numberOfArticles: 0;
    children: [
        {
            id: string;
            title: string;
            icon: string;
            numberOfArticles: 0;
            children: [string];
        },
    ];
};

type TStore = {
    items: TWikiArticle[];
    pagination: TPagination | null;
};

let store: TStore = {
    items: [],
    pagination: null,
};

class WikiModule extends StorageModule<TStore> {
    constructor() {
        super({name: 'wiki', store});
    }

    add = (items: TWikiArticle[]) => {
        let toAdd: TWikiArticle[] = [];
        items.forEach((_item) => {
            const found = store.items.find((i) => i.id === _item.id);
            if (!found) toAdd.push(_item);
        });
        const stored = this.getStore();
        this.updateStore({...stored, items: [...toAdd]});
    };

    setPagination = (pagination: any) => {
        this.updateStore({
            ...this.getStore(),
            pagination: {...pagination},
        });
    };

    /**
     * get pagination info, null if not set
     * @returns {TPagination | null}
     */
    getPagination = (): TPagination | null => this.getStore().pagination;
}

export default WikiModule;
