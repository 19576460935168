import StorageModule from '../StorageModule';

export type TContactItem = {
	id: string;
	department: string;
	name: string;
	email: string;
	secondaryEmail: string;
	phoneNumber: string;
	profileImageFile: string;
	responsibilities: string;
	mobileNumber: string;
};

type TStore = {
	items: TContactItem[];
};

let store: TStore = {
	items: [],
};

class ContactModule extends StorageModule<TStore> {
	constructor() {
		super({ name: 'contacts', store });
	}
}

export default ContactModule;
