import { Config } from '../Config';
import HTTP from '../HTTP';
import StorageHandler from '../Storage/StorageHandler';

class User {
    store = StorageHandler;
    http = new HTTP();

    /**
     * Fetch and store latest categories
     * @returns JSON
     */
    get = async () => {
        const url = new URL('/api/web/user/profile', Config.host);
        const res = await this.http.json.httpGet(url);

        if (!res.ok) throw res.status;

        const jsonResult = await res.json();

        return jsonResult;
    };

    uploadAvatar = async (file: {result: Blob; name: string}) => {
        const formData = new FormData();
        formData.append(
            `avatar`,
            // @ts-ignore
            file.result,
            file.name,
        );

        try {
            const res = await this.http.json.httpUpload(
                new URL(`/api/web/avatar`, Config.host),
                formData,
            );
            if (!res.ok || res.status !== 200) {
                return res;
            }
            return {ok: res.ok, status: res.status, response: res};
        } catch (e) {
            throw e;
        }
    };
}

export default User;
